import { Patient } from "./interface";
import {
    ApiPatientData,
    Session,
} from "../../ServerApiLayer/VOne/sessions/start";

// Function signature type
export type SetFromApiDataType = (
    turn: number,
    apiData: ApiPatientData[]
) => void;

/*
Store the patient data in localStorage directly from the patient api call. This
loops through the patient data and sets the patient data in localStorage, using
the patient id as the key.

@turn: The turn of the patient "arrival_turn".
@apiData: The PatientData data from the patient api call.
 */
export function setFromApiData(turn: number, apiData: Session): void {
    // Set the patient data here
    apiData.patients.forEach((patient) => {
        setPatientData(turn, patient);
    });
    return;
}

/*
Store the patient data from the getAllPatients API call in localStorage.

@patientsData: The list of patient data from the getAllPatients API call.
 */
export function setFromPatientGetAllData(patientsData: ApiPatientData[]): void {
    if (Array.isArray(patientsData)) {
        patientsData.forEach((patient) => {
            setPatientData(patient.arrival_turn, patient);
        });
    } else {
        console.error(
            "Expected patientsData to be an array, but got:",
            patientsData
        );
    }
}

/*
Sets the patient data in localStorage.

@turn: The turn of the patient "arrival_turn".
@patientData: The patient data that we want to store. For the basic patient data,
we only need the following fields:
- id
- first_name
- last_name
- age
- gender
- location
- location_type
- presenting_complaint
- status
- face_image_path
 */
export function setPatientData(
    turn: number,
    patientData: ApiPatientData
): void {

    // Format the patient data here
    let fullName = patientData.first_name + " " + patientData.last_name;
    let dashboardDemo =
        JSON.stringify(patientData.age) + " " + patientData.gender;

    // Put the patient prompt (which coemes as patientData.patient_prompt) into
    // the patientData.prompt field as dict with key 'CHATBOT' and value as the prompt
    let prompt = patientData.patient_prompt
        ? { CHATBOT: patientData.patient_prompt }
        : null;

    // Create the patient object
    let patient: Patient = {
        admin: {
            id: patientData.id,
            location: patientData.location,
            location_type: patientData.location_type,
            arrival_turn: turn,
            discharge_status: false,
            discharge_form: false,
        },
        demographic: {
            first_name: patientData.first_name,
            last_name: patientData.last_name,
            full_name: fullName,
            age: patientData.age,
            gender: patientData.gender,
            age_gender: dashboardDemo,
        },
        symptoms: {
            presenting_complaint: patientData.presenting_complaint,
            all_symptoms: [],
        },
        diagnosis: "",
        drugs: {
            drugChart: {},
            anyAllergies: null,
            allergyList: [],
        },
        examination: null,
        consultations: null,
        handover: patientData.handover,
        prompt: prompt,
        investigation: null,
        additionalTests: null,
        bloods: null,
        treatments: null,
        observations: null,
        notes: {
            additional_tests: {},
            bloods: {},
            drugs: {},
            exams: {},
            consultations: {},
            investigations: {},
            treatments: {},
            documentation: {},
            forms: {},
            patient_id: patientData.id,
        },
        status: patientData.status,
        face_image_path: patientData.face_image_path,
        forms: null,
        scoring: null,
    };

    // Set the patient data here in localStorage
    localStorage.setItem(
        JSON.stringify(patientData.id),
        JSON.stringify(patient)
    );
}

/*
 Get list of patient data from localStorage for the dashboard,
 only for keys that are integers.

@return: The list of patient data.
 */
export function getPatientData(): Patient[] {
    let patientData: Patient[] = [];

    for (let i = 0; i < localStorage.length; i++) {
        let key = localStorage.key(i);
        // Check if the key is a valid integer which is the patient id
        if (key && !isNaN(parseInt(key))) {
            let data = localStorage.getItem(key);
            if (data) {
                try {
                    let patient: Patient = JSON.parse(data);
                    patientData.push(patient);
                } catch (e) {
                    console.error(`Error parsing data for key ${key}`, e);
                }
            }
        }
    }

    return patientData;
}

export type GetPatientDataByStatusType = (
    status: "discharged" | "active"
) => Patient[];

export function getPatientDataByStatus(
    status: "discharged" | "active"
): Patient[] {
    const activeStatuses = ["Waiting", "Seen"];
    let patientData: Patient[] = [];

    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && !isNaN(parseInt(key))) {
            const data = localStorage.getItem(key);
            if (data) {
                try {
                    const patient: Patient = JSON.parse(data);
                    const isDischarged =
                        status === "discharged" &&
                        patient.status === "Discharged";
                    const isActive =
                        status === "active" &&
                        activeStatuses.includes(patient.status);

                    if (isDischarged || isActive) {
                        patientData.push(patient);
                    }
                } catch (e) {
                    console.error(`Error parsing data for key ${key}`, e);
                }
            }
        }
    }

    return patientData;
}

/*
Get specific patient data from localStorage. We are using the patient id as the key.

@patientId: The patient id for which we want to get the data.

@return: The patient data for the given patient id.
 */
export function getSpecificPatientData(patientId: number): Patient | null {
    let data = localStorage.getItem(patientId.toString());
    if (data) {
        return JSON.parse(data);
    }
    return null;
}

/*
Update the patient data in localStorage.

@patientId: The patient id for which we want to update the data.
@patientData: The patient data that we want to update, for example, examination
 */
export function updatePatientData(
    patientId: number,
    patientData: Patient
): void {
    localStorage.setItem(patientId.toString(), JSON.stringify(patientData));
}


