// This file contains the configuration for the blood test options. 

export const BloodTestOptions = {
    FULL_BLOOD_COUNT: [
        'HAEMOGLOBIN',
        'HCT',
        'MCH',
        'MCHC',
        'MCV',
        'PLATELETS',
        'TOTAL_WHITE_BLOOD_CELLS',
        'LYMPHOCYTES',
        'BASOPHILS',
        'EOSINOPHILS',
        'MONOCYTES',
        'NEUTROPHILS',
    ] as const,
    LIVER_PROFILE: [
        'ALBUMIN',
        'ALP',
        'ALT',
        'AST',
        'BILIRUBIN',
        'BILIRUBIN_CONJUGATED',
        'GGT',
        'TOTAL_PROTEIN',
    ] as const,
    RENAL_PROFILE: [
        'SODIUM',
        'POTASSIUM',
        'CREATININE',
        'UREA',
        'EGFR',
    ] as const,
    INFLAMMATORY_MARKERS: ['CRP', 'PROCALCITONIN'] as const,
    BONE_PROFILE: ['MAGNESIUM', 'PHOSPHATE', 'CALCIUM'] as const,
    COAGULATION: [
        'APTT',
        'FIBRINOGEN',
        'INR',
        'PROTHROMBIN',
        'D_DIMER',
    ] as const,
    ENDOCRINOLOGY: ['FT3', 'FT4', 'TSH'] as const,
    HAEMOTINICS: [
        'FERRITIN',
        'FOLIC_ACID',
        'IRON',
        'TRANSFERRIN',
        'TRANSFERRIN_SATURATION',
        'VITAMIN_B12',
    ] as const,
    DIABETES: ['GLUCOSE', 'HBA1C'] as const,
    LIPIDS: [
        'TRIGLYCERIDES',
        'CHOLESTEROL',
        'LDL',
        'HDL',
        'NON_HDL_CHOLESTEROL',
    ] as const,
    CARDIAC_MARKERS: ['TROPONIN', 'NTPRO_BNP', 'CK', 'CKMB'] as const,
    GASES: ['ABG', 'VBG'] as const,
    MISC: ['ALCOHOL', 'URIC_ACID', 'AMYLASE', 'LIPASE', 'LDH'] as const,
} as const


export const BloodTestResults = {
    FULL_BLOOD_COUNT: [
        'HAEMOGLOBIN',
        'HCT',
        'MCH',
        'MCHC',
        'MCV',
        'PLATELETS',
        'TOTAL_WHITE_BLOOD_CELLS',
        'LYMPHOCYTES',
        'BASOPHILS',
        'EOSINOPHILS',
        'MONOCYTES',
        'NEUTROPHILS',
    ] as const,
    LIVER_PROFILE: [
        'ALBUMIN',
        'ALP',
        'ALT',
        'AST',
        'BILIRUBIN',
        'BILIRUBIN_CONJUGATED',
        'GGT',
        'TOTAL_PROTEIN',
    ] as const,
    RENAL_PROFILE: [
        'SODIUM',
        'POTASSIUM',
        'CREATININE',
        'UREA',
        'EGFR',
    ] as const,
    INFLAMMATORY_MARKERS: ['CRP', 'PROCALCITONIN'] as const,
    BONE_PROFILE: ['MAGNESIUM', 'PHOSPHATE', 'CALCIUM'] as const,
    COAGULATION: [
        'APTT',
        'FIBRINOGEN',
        'INR',
        'PROTHROMBIN',
        'D_DIMER',
    ] as const,
    ENDOCRINOLOGY: ['FT3', 'FT4', 'TSH'] as const,
    HAEMOTINICS: [
        'FERRITIN',
        'FOLIC_ACID',
        'IRON',
        'TRANSFERRIN',
        'TRANSFERRIN_SATURATION',
        'VITAMIN_B12',
    ] as const,
    DIABETES: ['GLUCOSE', 'HBA1C'] as const,
    LIPIDS: [
        'TRIGLYCERIDES',
        'CHOLESTEROL',
        'LDL',
        'HDL',
        'NON_HDL_CHOLESTEROL',
    ] as const,
    CARDIAC_MARKERS: ['TROPONIN', 'NTPRO_BNP', 'CK', 'CKMB'] as const,
    VBG: ['VBG_PH', 'VBG_PO2', 'VBG_PCO2', 'VBG_LACTATE', 'VBG_BASE_EXCESS', 'VBG_BICARBONATE', 'VBG_SAO2'] as const,
    ABG: ['ABG_PH', 'ABG_PO2', 'ABG_PCO2', 'ABG_LACTATE', 'ABG_BASE_EXCESS', 'ABG_BICARBONATE', 'ABG_SAO2'] as const,
    MISC: ['ALCOHOL', 'URIC_ACID', 'AMYLASE', 'LIPASE', 'LDH'] as const,
} as const



export const BloodTestOptionsSequenceWithTitle = [
    [
        {
            name: "fbc",
            title: "FBC",
            options: BloodTestOptions.FULL_BLOOD_COUNT

        },
        {
            name: "liver_profile",
            title: "Liver Profile",
            options: BloodTestOptions.LIVER_PROFILE

        },
        {
            name: "u_and_e",
            title: "U&Es",
            options: BloodTestOptions.RENAL_PROFILE

        }
    ],
    [
        {
            name: "inflamatory_markers",
            title: "Inflammatory Markers",
            options: BloodTestOptions.INFLAMMATORY_MARKERS

        },
        {
            name: "bone_profile",
            title: "Bone Profile",
            options: BloodTestOptions.BONE_PROFILE

        },
        {
            name: "coagulation",
            title: "Coagulation",
            options: BloodTestOptions.COAGULATION

        }
    ],
    [
        {
            name: "endocrinology",
            title: "Endocrinology",
            options: BloodTestOptions.ENDOCRINOLOGY

        },
        {
            name: "haemotinics",
            title: "Haemotinics",
            options: BloodTestOptions.HAEMOTINICS

        },
        {
            name: "diabetes",
            title: "DIABETES",
            options: BloodTestOptions.DIABETES

        }
    ],
    [
        {
            name: "lipids",
            title: "Lipids",
            options: BloodTestOptions.LIPIDS

        },
        {
            name: "cardiac_markers",
            title: "Cardiac Markers",
            options: BloodTestOptions.CARDIAC_MARKERS

        },
        {
            name: "gases",
            title: "GASES",
            options: BloodTestOptions.GASES

        }
    ],
    [
        {
            name: "misc",
            title: "MISC",
            options: BloodTestOptions.MISC

        }
    ]
 ] as const
 export const Priorities = [{
    value: "1",
    label: "High"
},
{
    value: "2",
    label: "Medium"
},
{
    value: "3",
    label: "Low"
}]
export type IBloodTestProfile = keyof typeof BloodTestOptions
export type IBloodTestKey = typeof BloodTestOptions[IBloodTestProfile][number]
export default BloodTestOptions
export const BloodTestProfiles = Object.keys(
    BloodTestOptions
) as IBloodTestProfile[]