import { DrugDataTranslations } from "./types";

export const deDrugTranslations: DrugDataTranslations = {
    FOUR_DMAP: "4-DMAP, IV, 250 mg",
    ACETYLCYSTEIN: "Acetylcystein, IV, 1000 mg",
    ADENOSIN: "Adenosin, IV, 6 mg",
    ADRENALIN_IM: "Adrenalin, IM, 0.5 mg",
    ADRENALIN_IV_1MG: "Adrenalin, IV, 1 mg",
    ADRENALIN_IV_PERFUSER: "Adrenalin, IV, 5 mcg/min über Perfusor",
    AJMALIN: "Ajmalin, IV, 50 mg über 5 Minuten",
    ALTEPLASE_IV_60MIN: "Alteplase, IV, 90 mg über 60 Minuten",
    ALTEPLASE_IV_BOLUS: "Alteplase, IV, 10 mg Bolus",
    AMIODARON: "Amiodaron, IV, 150 mg",
    AMLODIPIN: "Amlodipin, Oral, 5 mg",
    AMOXICILLIN_CLAVULANSAURE_IV: "Amoxicillin/Clavulansäure, IV, 1200 mg",
    AMOXICILLIN_CLAVULANSAURE_ORAL: "Amoxicillin/Clavulansäure, Oral, 625 mg",
    AMOXICILLIN_ORAL: "Amoxicillin, Oral, 500 mg",
    AMPICILLIN_IV: "Ampicillin, IV, 2000 mg",
    AMPICILLIN_ORAL: "Ampicillin, Oral, 2000 mg",
    AMPICILLIN_SULBACTAM: "Ampicillin/Sulbactam, IV, 3000 mg",
    ASS_100: "ASS, Oral, 100 mg",
    ASS_250: "ASS, IV, 250 mg",
    ASS_1000_IV: "ASS, IV, 1000 mg",
    ASS_1000_ORAL: "ASS, Oral, 1000 mg",
    ATROPIN: "Atropin, IV, 0.5 mg",
    AZITHROMYCIN: "Azithromycin, IV, 500 mg",
    AZITHROMYCIN_ORAL: "Azithromycin, Oral, 500 mg",
    BUDESONID: "Budesonid, Inhalativ, 1 mg",
    BUTYLSCOPOLAMIN: "Butylscopolamin, IV, 20 mg",
    BUTYLSCOPOLAMIN_ORAL: "Butylscopolamin, Oral, 20 mg",
    CAFEDRIN_THEODRENALIN: "Cafedrin/Theodrenalin (Akrinor), IV, 40/2 mg",
    CALCIUMGLUCONAT: "Calciumgluconat, IV, 10 ml",
    CANDESARTAN: "Candesartan, Oral, 8 mg",
    CARBAMAZEPIN: "Carbamazepin, Oral, 200 mg",
    CARVEDILOL: "Carvedilol, Oral, 25 mg",
    CEFOTAXIM: "Cefotaxim, IV, 2000 mg",
    CEFPODOXIM: "Cefpodoxim, Oral, 200 mg",
    CEFTAZIDIM: "Ceftazidim, IV, 2000 mg",
    CEFTRIAXON: "Ceftriaxon, IV, 2000 mg",
    CEFUROXIM_IV: "Cefuroxim, IV, 1500 mg",
    CEFUROXIM_ORAL: "Cefuroxim, Oral, 500 mg",
    CIMETIDIN: "Cimetidin, IV, 200 mg",
    CIPROFLOXACIN: "Ciprofloxacin, IV, 400 mg",
    CIPROFLOXACIN_ORAL: "Ciprofloxacin, Oral, 750 mg",
    CLARITHROMYCIN: "Clarithromycin, IV, 500 mg",
    CLARITHROMYCIN_ORAL: "Clarithromycin, Oral, 500 mg",
    CLEMASTIN: "Clemastin, IV, 2 mg",
    CLINDAMYCIN: "Clindamycin, IV, 600 mg",
    CLINDAMYCIN_ORAL: "Clindamycin, Oral, 600 mg",
    CLONAZEPAM: "Clonazepam, IV, 1 mg",
    CLONIDIN: "Clonidin, IV, 0.05 mg",
    CLONIDIN_ORAL: "Clonidin, Oral, 0.15 mg",
    CLOPIDOGREL_300: "Clopidogrel, Oral, 300 mg",
    CLOPIDOGREL_75: "Clopidogrel, Oral, 75 mg",
    COTRIMOXAZOL: "Cotrimoxazol, IV, 960 mg",
    COTRIMOXAZOL_ORAL: "Cotrimoxazol, Oral, 960 mg",
    CYCLIZINE_ORAL: "Cyclizin, Oral, 50 mg",
    DABIGATRAN: "Dabigatran, Oral, 110 mg",
    DAPTOMYCIN: "Daptomycin, IV, 500 mg",
    DEXAMETHASON_40: "Dexamethason, IV, 40 mg",
    DEXAMETHASON_8: "Dexamethason, IV, 8 mg",
    DIAZEPAM: "Diazepam, IV, 10 mg",
    DIAZEPAM_ORAL: "Diazepam, Oral, 10 mg",
    DICLOFENAC: "Diclofenac, Oral, 50 mg",
    DIGITOXIN: "Digitoxin, Oral, 0.07 mg",
    DIGOXIN: "Digoxin, Oral, 0.25 mg",
    DIMENHYDRINAT: "Dimenhydrinat, IV, 62 mg",
    DOBUTAMIN: "Dobutamin, IV, 200 mcg/min über Perfusor",
    DOXYCYCLIN: "Doxycyclin, Oral, 200 mg",
    ENALAPRIL: "Enalapril, Oral, 10 mg",
    ENOXAPARIN_20: "Enoxaparin, SC, 20 mg",
    ENOXAPARIN_40: "Enoxaparin, SC, 40 mg",
    ERYTHROMYCIN: "Erythromycin, IV, 500 mg",
    ERYTHROMYCIN_250: "Erythromycin, IV, 250 mg",
    ESKETAMIN: "Esketamin, IV, 15 mg",
    ESMOLOL: "Esmolol, IV, 50 mg",
    FLECAINID: "Flecainid, Oral, 100 mg",
    FLUCLOXACILLIN: "Flucloxacillin, IV, 1000 mg",
    FLUMAZENIL: "Flumazenil, IV, 0.2 mg",
    FONDAPARINUX_2_5: "Fondaparinux, SC, 2.5 mg",
    FONDAPARINUX_7_5: "Fondaparinux, SC, 7.5 mg",
    FOSFOMYCIN: "Fosfomycin, IV, 4000 mg",
    FOSFOMYCIN_ORAL: "Fosfomycin, Oral, 3000 mg",
    FUROSEMID: "Furosemid, IV, 40 mg",
    GENTAMICIN: "Gentamicin, IV, 200 mg",
    GLUCOSE_10: "Glukose 10%, IV, 250 ml",
    GLUCOSE_40: "Glukose 40%, IV, 10 ml",
    GLUCOSE_5: "Glukose 5%, IV, 500 ml",
    GLYCEROLTRINITRAT: "Glyceroltrinitrat, Sublingual, 0.8 mg",
    HALOPERIDOL: "Haloperidol, IM, 5 mg",
    HYDROCHLOROTHIAZIDE: "HCT, Oral, 25 mg",
    HEPARIN: "Heparin, IV, 5000 IE",
    HYDROCHLOROTHIAZID: "Hydrochlorothiazid, Oral, 25 mg",
    HYDROCORTISON: "Hydrocortison, IV, 100 mg",
    HYDROXOCOBALAMIN: "Hydroxocobalamin, IV, 5000 mg",
    IBUPROFEN: "Ibuprofen, Oral, 600 mg",
    IMIPENEM_CILASTATIN: "Imipenem/Cilastatin, IV, 1000 mg",
    IPRATROPIUMBROMID: "Ipratropiumbromid, Inhalativ, 250 mcg",
    KALIUMCHLORID: "Kaliumchlorid, IV, 20 ml",
    KETAMIN: "Ketamin, IV, 30 mg",
    LAMOTRIGIN: "Lamotrigin, Oral, 25 mg",
    LEVETIRACETAM: "Levetiracetam, IV, 500 mg",
    LEVETIRACETAM_ORAL: "Levetiracetam, Oral, 500 mg",
    LEVOFLOXACIN: "Levofloxacin, IV, 500 mg",
    LEVOFLOXACIN_ORAL: "Levofloxacin, Oral, 500 mg",
    LORAZEPAM: "Lorazepam, Oral, 1 mg",
    MAGNESIUMSULFAT: "Magnesiumsulfat 50%, IV, 10 ml",
    MANNITOL: "Mannitol 15%, IV, 250 ml",
    MEDIZINISCHE_KOHLE: "Medizinische Kohle, Oral, 50 mg",
    MELPERON: "Melperon, Oral, 50 mg",
    MEROPENEM: "Meropenem, IV, 1000 mg",
    METAMIZOL: "Metamizol, IV, 1000 mg",
    METAMIZOL_ORAL: "Metamizol, Oral, 500 mg",
    METHYLPREDNISOLON: "Methylprednisolon, IV, 500 mg",
    METOCLOPRAMID: "Metoclopramid, IV, 10 mg",
    METOCLOPRAMID_ORAL: "Metoclopramid, Oral, 10 mg",
    METOPROLOL: "Metoprolol, IV, 5 mg",
    METOPROLOL_ORAL: "Metoprolol, Oral, 47.5 mg",
    METRONIDAZOL: "Metronidazol, IV, 1000 mg",
    METRONIDAZOL_ORAL: "Metronidazol, Oral, 1000 mg",
    MIDAZOLAM: "Midazolam, IV, 2 mg",
    MORPHIN: "Morphin, IV, 5 mg",
    MOXIFLOXACIN: "Moxifloxacin, Oral, 400 mg",
    MOXIFLOXACIN_ORAL: "Moxifloxacin, IV, 400 mg",
    NALOXON: "Naloxon, IV, 0.1 mg",
    NATRIUMHYDROGENCARBONAT: "Natriumhydrogencarbonat, IV, 100 ml",
    NITRENDIPIN: "Nitrendipin, Oral, 20 mg",
    NITROFURANTOIN: "Nitrofurantoin, Oral, 100 mg",
    NORADRENALIN: "Noradrenalin, IV, 10 mcg",
    OLANZAPIN: "Olanzapin, Oral, 5 mg",
    ONDANSETRON: "Ondansetron, IV, 8 mg",
    PANTOPRAZOL: "Pantoprazol, IV, 40 mg",
    PANTOPRAZOL_80: "Pantoprazol, IV, 80 mg",
    PANTOPRAZOL_ORAL: "Pantoprazol, Oral, 40 mg",
    PARACETAMOL: "Paracetamol, IV, 1000 mg",
    PARACETAMOL_ORAL: "Paracetamol, Oral, 500 mg",
    PENICILLIN_G: "Penicillin G, IV, 3000 mg",
    PENICILLIN_V: "Penicillin V, Oral, 1.5 Mio IE",
    PHYSOSTIGMIN: "Physostigmin, IV, 2 mg",
    PIPERACILLIN_TAZOBACTAM: "Piperacillin/Tazobactam, IV, 4500 mg",
    PIRITRAMID: "Piritramid, IV, 5 mg",
    PRASUGREL_10: "Prasugrel, Oral, 10 mg",
    PRASUGREL_60: "Prasugrel, Oral, 60 mg",
    PREDNISOLON_50: "Prednisolon, IV, 50 mg",
    PREDNISOLON_500: "Prednisolon, IV, 500 mg",
    PROMETHAZIN: "Promethazin, Oral, 25 mg",
    PROPAFENON: "Propafenon, IV, 50 mg",
    PROPAFENON_ORAL: "Propafenon, Oral, 150 mg",
    PROPOFOL: "Propofol, IV, 20 mg",
    PROPRANOLOL: "Propranolol, Oral, 80 mg",
    QUETIAPIN: "Quetiapin, Oral, 50 mg",
    RAMIPRIL: "Ramipril, Oral, 5 mg",
    REPROTEROL: "Reproterol, IV, 0.09 mg",
    RIFAMPICIN: "Rifampicin, IV, 600 mg",
    RISPERIDON: "Risperidon, Oral, 1 mg",
    RIVAROXABAN: "Rivaroxaban, Oral, 15 mg",
    SALBUTAMOL_INHALER: "Salbutamol, Dosieraerosol, 2 Hübe",
    SALBUTAMOL: "Salbutamol, Inhalativ per Vernebler, 2.5 mg",
    SPIRONOLACTON: "Spironolacton, Oral, 50 mg",
    SUMATRIPTAN: "Sumatriptan, Oral, 50 mg",
    TAMSULOSIN: "Tamsulosin, Oral, 0.4 mg",
    THEOPHYLLIN: "Theophyllin, IV, 200 mg über 20 Minuten",
    TICAGRELOR: "Ticagrelor, Oral, 90 mg",
    TOLUIDINBLAU: "Toluidinblau, IV, 200 mg",
    TORASEMID: "Torasemid, Oral, 5 mg",
    TRANEXAMSAURE: "Tranexamsäure, IV, 1000 mg",
    URAPIDIL: "Urapidil, IV, 10 mg",
    VALPROAT: "Valproat, Oral, 500 mg",
    VANCOMYCIN: "Vancomycin, IV, 1000 mg",
    VERAPAMIL: "Verapamil, IV, 5 mg",
    VITAMIN_B_1: "Vitamin B1, IV, 100 mg",
    VITAMIN_B_12: "Vitamin B12, IV, 1 mg",
    VITAMIN_B_6: "Vitamin B6, IV, 20 mg",
    VITAMIN_K: "Vitamin K, IV, 10 mg",
    VOLLELEKTROLYTLOSUNG: "Balancierte Vollelektrolytlösung, IV, 500 ml",
    SAUERSTOFF_4: "Sauerstoff, Inhalativ, 4 l/min über Nasensonde",
    SAUERSTOFF_10: "Sauerstoff, Inhalativ, 10 l/min über Maske",
    SAUERSTOFF_15: "Sauerstoff, Inhalativ, 15 l/min über Maske",
    ANTICOAGULANT_ACS_ANY: "Antikoaguationsmittel für akutes Koronarsyndrom",
    ANTIPLATELET_ADD: "Antiaggreganzientherapie",
    BETA_BLOCKER: "Betablocker",
};
