import { DischargeFormTranslations } from "./types";

export const enTranslations: DischargeFormTranslations = {
    title: "Discharge Form",
    formType: "Form Type",
    admissionButton: "Admission",
    dischargeButton: "Discharge",
    admission: "Admission Destination",
    selectPlaceholder: "Select...",
    admissionOptions: [
        {
            value: "WARD_DISCHARGE",
            label: "Ward",
        },
        {
            value: "HDU_DISCHARGE",
            label: "HDU",
        },
        {
            value: "ITU_DISCHARGE",
            label: "ITU",
        },
    ],
    admitting: "Admitting Team",
    admittingOptions: [
        {
            label: "Admit to medical team",
            value: "GENERAL_MEDICAL",
        },
        {
            label: "Admit to surgical team",
            value: "GENERAL_SURGICAL",
        },
        {
            label: "Urology",
            value: "UROLOGY",
        },
        {
            label: "Cardiology",
            value: "CARDIOLOGY",
        },
        {
            label: "Gastroenterology",
            value: "GASTROENTEROLOGY",
        },
        {
            label: "Obstetrics",
            value: "OBSTETRICS",
        },
        {
            label: "Orthopaedics",
            value: "ORTHO",
        },
    ],
    presentation: "Presentation",
    presentationTip:
        "Make sure to include any red flags or concerning features",
    action: "Action Taken",
    follow: "Follow-up",
    clearButton: "Clear",
    submitButton: "Submit",
    titleDiagnosis: "Please specify a diagnosis first:",
    selectPlaceholderDiagnosis: "Select...",
    diagnosisButton: "Save Diagnosis",
};
