import { ExitChatModalTranslation } from './types';

export const enTranslations: ExitChatModalTranslation = {
  placeholderOne: 'Best Guess First',
  placeholderTwo: 'Best Guess Two',
  placeholderThree: 'Best Guess Three',  // Updated to "Best Guess Three"
  modalTitle: 'Best Guess Diagnosis',
  saveButton: "Save",
  noteText: "After history taking, differential diagnoses are:"
};
