
// External imports
import React, { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";

// Internal imports
import MainPageLayout from "../../../components/core/header/MainPageLayout";
import {
    ReviewPageContainer,
    ReviewPageHeadTitle,
    ReviewPageHeadTitleMain,
    ReviewPageHeadTitleParagraph,
    BackButton,
} from "./ReviewClassPage.styles";
import ReviewClassLeftSection from "./LeftSection/LeftSection";
import ReviewClassRightSection from "./RightSection/RightSection";
import { getJwt } from "../../../core/LocalStorage/AuthSession/core";

// Dependency injection imports
import { ReviewClassType } from "../../../core/ServerApiLayer/VOne/classes/reviewClass";

// language context
import { useLanguage} from "../../../core/translations/LanguageContext";

// translations
import { ReviewClassTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import { BloodTestTranslations } from "../../../core/translations/bloods/types";
import { enBloodTestTranslations } from "../../../core/translations/bloods/en";
import { deBloodTestTranslations } from "../../../core/translations/bloods/de";
import { InvestigationTypesTranslations } from "../../../core/translations/investigations/types";
import { enInvestigationTranslations } from "../../../core/translations/investigations/en";
import { deInvestigationTranslations } from "../../../core/translations/investigations/de";
import { DrugDataTranslations } from "../../../core/translations/drugs/types";
import { enDrugTranslations } from "../../../core/translations/drugs/en";
import { deDrugTranslations } from "../../../core/translations/drugs/de";
import { TreatmentTypesTranslations } from "../../../core/translations/treatments/types";
import { enTreatmentTranslations } from "../../../core/translations/treatments/en";
import { deTreatmentTranslations } from "../../../core/translations/treatments/de";
import { DiseaseTranslations } from "../../../core/translations/diseases/types";
import { enDiseaseTranslations } from "../../../core/translations/diseases/en";


// Constants
const TRANSLATIONS: Record<SupportedLanguage, ReviewClassTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

const DISEASE_TRANSLATIONS: Record<SupportedLanguage, DiseaseTranslations> = {
    en: enDiseaseTranslations,
    de: enDiseaseTranslations
};

const BLOOD_TRANSLATIONS: Record<SupportedLanguage, BloodTestTranslations> =
    {
        en: enBloodTestTranslations,
        de: deBloodTestTranslations
    };
const INVESTIGATION_TRANSLATIONS: Record<SupportedLanguage, InvestigationTypesTranslations> = {
    en: enInvestigationTranslations,
    de: deInvestigationTranslations
};
const DRUG_TRANSLATIONS: Record<SupportedLanguage, DrugDataTranslations> = {
    en: enDrugTranslations,
    de: deDrugTranslations
};
const TREATMENT_TRANSLATIONS: Record<SupportedLanguage, TreatmentTypesTranslations> = {
    en: enTreatmentTranslations,
    de: deTreatmentTranslations
}


/**
 * Props interface for the ReviewClassPage component with dependency injection
 */
export interface ReviewPageProps {
    reviewClassApiCall: ReviewClassType;
}

export const ReviewClassPage: React.FC<ReviewPageProps> = ({ reviewClassApiCall }) => {
    const { id } = useParams<{ id: string }>();
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    const bloodTranslations = BLOOD_TRANSLATIONS[language];
    const investigationTranslations = INVESTIGATION_TRANSLATIONS[language];
    const drugTranslations = DRUG_TRANSLATIONS[language];
    const treatmentTranslations = TREATMENT_TRANSLATIONS[language];
    const diseaseTranslations = DISEASE_TRANSLATIONS[language];
    const navigate = useNavigate();

    const [leftTableData, setLeftTableData] = useState([]);
    const [rightTableData, setRightTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [classDetails, setClassDetails] = useState<any>({});
    const [error, setError] = useState("");

    // Combine translations with other common translations
    const allTranslations = {
        ...diseaseTranslations,
        ...bloodTranslations.types,
        ...investigationTranslations,
        ...drugTranslations,
        ...treatmentTranslations
    };

    useEffect(() => {
        const jwt = getJwt();

        //@ts-ignore
        reviewClassApiCall(jwt, { class_id: parseInt(id) })
            .then((response) => {
                if (response.status === 200 && response.data) {
                    console.log(response.data);
                    setClassDetails(response.data.class_details);

                    const formattedLeftTableData = response.data.feedback.map((item: any) => ({
                        disease: item.disease_name,
                        students: item.students.toString(),
                        patients: item.completed_patients.toString(),
                        diagnosis: `${item.percent_correct_diagnosis.toFixed(2)}%`,
                        avg_score: `${item.mean_score.toFixed(1)}%`,
                        actions: item.actions
                            .map((action: ReviewPageRightTableRowProps) => ({
                                action: action.action,
                                percentage: action.percentage,
                            }))
                            .sort((a, b) => parseFloat(b.percentage) - parseFloat(a.percentage)), // Sort actions by percentage descending
                    }));

                    setLeftTableData(formattedLeftTableData);

                    if (formattedLeftTableData.length > 0) {
                        setRightTableData(formattedLeftTableData[0].actions);
                    }
                } else {
                    setError("Failed to load class review data.");
                }
            })
            .catch((err) => {
                setError("An error occurred while fetching class review data.");
                console.error("API call error:", err);
            });
    }, [reviewClassApiCall, id]);

    const handleRowSelection = (row: any) => {
        setSelectedRow(row);
        setRightTableData(row.actions);
    };

    const handleBack = () => {
        navigate('/teacher/view-classes');
    };

    const headerInfo = (
        <ReviewPageHeadTitleMain>
            <BackButton onClick={handleBack}>
                <FaChevronLeft size={20} />
                {translations.backButton}
            </BackButton>

        </ReviewPageHeadTitleMain>
    );

    return (
        <MainPageLayout>
            {headerInfo}
            <ReviewPageContainer>
                {error && <p className="error-message">{error}</p>}
                <ReviewClassLeftSection
                    tableData={leftTableData}
                    setSelectedRow={handleRowSelection}
                    classDetails={classDetails}
                    translations={diseaseTranslations}
                />
                <ReviewClassRightSection
                    tableData={rightTableData}
                    selectedRow={selectedRow}
                    translations={allTranslations}
                />
            </ReviewPageContainer>
        </MainPageLayout>
    );
};