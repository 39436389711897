//External Imports
import React, { ReactNode, useState } from "react";
import {
    FaUserPlus,
    FaComment,
    FaUserMd,
    FaCommentMedical,
    FaBinoculars,
    FaEyeDropper,
    FaVials,
    FaSearch,
    FaStethoscope,
    FaClipboard,
    FaMedkit,
    FaPills,
    FaSyringe,
    FaPencilAlt,
    FaRegStickyNote,
    FaHospitalAlt,
    FaEye,
    FaArchive,
    FaHome,
} from "react-icons/fa";

// Internal Imports
import { GameNavBar } from "../GameNavBar/GameNavBar";
import { SidebarNav } from "./SideBarNav/SidebarNav";
import useNavigation, { INavigationItem } from "./useNavigation";
import { PiTestTubeFill } from "react-icons/pi";
import {
    Container,
    FlexRow,
    Main,
    Content,
    GamePageTitle,
} from "./GamePageLayout.styles";
import { Notification } from "../GameNavBar/NotifPopup/NotificationBell/NotificationBell";
import PatientDetail from "../patientSideBar/PatientDetail";
import GuidelinesModal from "../../core/modal/guidelines-modal/GuidelinesModal";
import ChatExitModal from "../../core/modal/chat-exit-diagnosis-modal/ChatExitModal";

// Types
import { GameLayoutTranslations } from "./translations/types";
import {
    SidebarDocumentationDependencies,
    GameBarLayoutTranslations,
    DemographicsDependencies,
    TurnManagementDependencies,
} from "../../../core/sharedDependencies/types";
import { PostDiagnosisType } from "../../../core/ServerApiLayer/VOne/patients/postDiagnosis";
import { useParams } from "react-router-dom";

// Define all navigation items, including dashboard-specific and patient-specific ones
const navigationItems = (
    translations: GameLayoutTranslations
): INavigationItem[] => [
    {
        name: translations.leftSidebar.dashboard,
        href: "/dashboard",
        Icon: FaHome,
    },
    {
        name: translations.leftSidebar.dischargedDashboard,
        href: "/discharged",
        Icon: FaArchive,
    },
    {
        name: translations.leftSidebar.plan,
        href: "/plan",
        Icon: FaEye,
    },
    {
        name: translations.leftSidebar.reviewPage,
        href: "/patients/:id/review",
        Icon: FaClipboard,
    },
    {
        name: translations.leftSidebar.chatPage,
        href: "/patients/:id/chat",
        Icon: FaComment,
    },
    {
        name: translations.leftSidebar.examPage,
        href: "/patients/:id/exam",
        Icon: FaStethoscope,
    },
    {
        name: translations.leftSidebar.testsOverview,
        Icon: PiTestTubeFill,
        subItems: [
            {
                name: translations.leftSidebar.orderBloodTest,
                href: "/patients/:id/bloods/order",
                Icon: FaEyeDropper,
            },
            {
                name: translations.leftSidebar.reviewBloodTest,
                href: "/patients/:id/bloods/results",
                Icon: FaVials,
            },
            {
                name: translations.leftSidebar.investigations,
                href: "/patients/:id/investigate",
                Icon: FaBinoculars,
            },
        ],
    },
    {
        name: translations.leftSidebar.consultOverview,
        Icon: FaCommentMedical,
        subItems: [
            {
                name: translations.leftSidebar.specialistConsult,
                href: "/patients/:id/specialty",
                Icon: FaUserMd,
            },
            {
                name: translations.leftSidebar.therapyConsult,
                href: "/patients/:id/hcp",
                Icon: FaUserPlus,
            },
        ],
    },
    {
        name: translations.leftSidebar.treatmentOverview,
        Icon: FaMedkit,
        subItems: [
            {
                name: translations.leftSidebar.drugChart,
                href: "/patients/:id/drugchart",
                Icon: FaPills,
            },
            {
                name: translations.leftSidebar.treatmentPage,
                href: "/patients/:id/treatments",
                Icon: FaSyringe,
            },
        ],
    },
    {
        name: translations.leftSidebar.documentationOverview,
        Icon: FaPencilAlt,
        subItems: [
            {
                name: translations.leftSidebar.notes,
                href: "/patients/:id/notes",
                Icon: FaRegStickyNote,
            },
            {
                name: translations.leftSidebar.forms,
                href: "/patients/:id/forms",
                Icon: FaHospitalAlt,
            },
        ],
    },
    {
        name: translations.leftSidebar.guidelines,
        href: "/patients/:id/guidelines",
        Icon: FaSearch,
    },
];

export interface GamePageLayoutProps {
    turnCount: number;
    unreadNotifications: Notification[];
    children: ReactNode;
    pageTitle?: string;
    patient?: any;
    isDischargeFormOpen?: boolean;
    translations: GameBarLayoutTranslations;
    turnDependencies: TurnManagementDependencies;
    documentationDependencies?: SidebarDocumentationDependencies;
    demographicsDependencies?: DemographicsDependencies;
    useLanguage: any;
    refreshPage: () => void;
}

export const GamePageLayout: React.FC<GamePageLayoutProps> = ({
    turnCount,
    unreadNotifications,
    children,
    pageTitle = "Dashboard",
    patient,
    isDischargeFormOpen,
    translations,
    turnDependencies,
    documentationDependencies,
    demographicsDependencies,
    useLanguage,
    refreshPage,
}) => {
    const { id } = useParams<{ id: string }>();

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [chatExitModalOpen, setChatExitModalOpen] = useState<boolean>(false);
    const setPatientIdToFalse = () => {
        // Retrieve the current array from localStorage
        const existingData = localStorage.getItem("showChatExitPopupFor");
        const patientIds = existingData ? JSON.parse(existingData) : [];

        // Update the patientId to false (indicating popup has been shown)
        const updatedPatientIds = patientIds.map(
            (patient: { [key: string]: boolean }) => {
                //@ts-ignore
                if (patient[id] !== undefined) {
                    //@ts-ignore

                    return { [id]: false };
                }
                return patient;
            }
        );
        localStorage.setItem(
            "showChatExitPopupFor",
            JSON.stringify(updatedPatientIds)
        );
        setChatExitModalOpen(false);
    };
    // Get navigation items by calling the function
    const navItems = navigationItems(translations.gameBar);

    let filteredNavigationItems = navItems;
    if (location.pathname.startsWith("/patients")) {
        filteredNavigationItems = navItems.filter(
            (item) =>
                item.href?.includes("patients") ||
                item.subItems?.some((subItem) =>
                    subItem.href?.includes("patients")
                )
        );
    } else if (
        location.pathname === "/dashboard" ||
        location.pathname === "/discharged"
    ) {
        filteredNavigationItems = navItems.filter((item) =>
            ["/dashboard", "/discharged", "/plan"].includes(item.href || "")
        );
    }

    const { items: sidebarItems } = useNavigation(filteredNavigationItems);

    return (
        <Container>
            <GameNavBar
                turnCount={turnCount}
                unreadNotifications={unreadNotifications}
                translations={translations.gameBar}
                turnDependencies={turnDependencies}
            />
            <FlexRow>
                <SidebarNav
                    items={sidebarItems}
                    setModalOpen={setModalOpen}
                    setChatExitModalOpen={setChatExitModalOpen}
                />
                {modalOpen && (
                    <GuidelinesModal onClose={() => setModalOpen(false)} />
                )}
                {chatExitModalOpen && (
                    <ChatExitModal onClose={() => setPatientIdToFalse()} />
                )}
                <Main width={patient ? "calc(100% - 24rem)" : "100%"}>
                    <GamePageTitle>{pageTitle}</GamePageTitle>
                    <Content>{children}</Content>
                </Main>
                {patient &&
                    documentationDependencies &&
                    demographicsDependencies &&
                    turnDependencies && (
                        <PatientDetail
                            patient={patient}
                            isDischargeFormOpen={isDischargeFormOpen}
                            translations={translations}
                            documentationDependencies={
                                documentationDependencies
                            }
                            turnDependencies={turnDependencies}
                            useLanguage={useLanguage}
                            demographicsDependencies={demographicsDependencies}
                            refreshPage={refreshPage}
                        />
                    )}
            </FlexRow>
        </Container>
    );
};
