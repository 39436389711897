// External imports
import React, { useState } from "react";

// Internal imports
import {
    DischargePatientDetailsTableContainer,
    ReviewDischargePatientButton,
} from "./DischargePatientDetails.styles";
import Table from "../../../../components/core/table/Table";
import { LocationIcon } from "../../../../components/core/location-icon/LocationIcon";
import GenderIcon from "../../../../components/core/gender-icon/GenderIcon";
import { DemographicsContainer } from "../../dashBoardPage/dashBoardTable/dashBoardTable.styles";
import { DischargedPatient } from "../dischargeBoardPage";
import DischargePatientModal from "../../../../components/core/modal/discharge-patient/DischargePatient";

// Translation imports
import { DischargePageTranslations } from "../translations/types";
import { GameBarLayoutTranslations } from "../../../../core/sharedDependencies/types";

export interface DischargePatientDetailsProps {
    data: DischargedPatient[];
    translations: any;
    pageTranslations: DischargePageTranslations;
    demographicsDependencies: any;
}

export const DischargePatientDetails: React.FC<DischargePatientDetailsProps> = ({
                                                                                    data,
                                                                                    translations,
                                                                                    pageTranslations,
                                                                                    demographicsDependencies,
                                                                                }: DischargePatientDetailsProps) => {
    // State to show/hide feedback modal
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

    // State to store the selected discharged patient data
    const [selectedPatientData, setSelectedPatientData] = useState<DischargedPatient | null>(null);

    const showFeedbackPopup = (patient_id: number): void => {
        // Find the discharged patient from the data array
        const dischargedPatient = data.find(patient => Number(patient.id) === patient_id);

        if (dischargedPatient) {

            setSelectedPatientData(dischargedPatient);
            setFeedbackModalOpen(true);
        }
    };

    const columns = [
        {
            key: "location",
            label: pageTranslations.table.columns.location,
            render: (patient: DischargedPatient) => (
                <LocationIcon location={patient.location} translations={translations}/>
            ),
        },
        {
            key: "name",
            label: pageTranslations.table.columns.name,
            render: (patient: DischargedPatient) => <span>{patient.name}</span>,
        },
        {
            key: "demographics",
            label: pageTranslations.table.columns.ageGender,
            render: (patient: DischargedPatient) => {
                const [age, gender] = patient.demographics.split(" ");
                return (
                    <DemographicsContainer>
                        {age}{" "}
                        <GenderIcon
                            gender={gender === "Male" ? "male" : "female"}
                        />
                    </DemographicsContainer>
                );
            },
        },
        {
            key: "symptom",
            label: pageTranslations.table.columns.symptom,
            render: (patient: DischargedPatient) => (
                <span>
                    {translations.symptoms[patient.symptom]}
                </span>
            ),
        },
        {
            key: "diagnosis",
            label: pageTranslations.table.columns.diagnosis,
            render: (patient: DischargedPatient) => (
                <span>
                    {patient.diagnosis ? translations.diagnosis[patient.diagnosis] : null}
                </span>
            ),
        },
        {
            key: "real_diagnosis",
            label: pageTranslations.table.columns.realDiagnosis,
            render: (patient: DischargedPatient) => (
                <span>
                    {patient.real_diagnosis ? translations.diagnosis[patient.real_diagnosis] : null}
                </span>
            ),
        },
        {
            key: "score",
            label: pageTranslations.table.columns.score,
            render: (patient: DischargedPatient) => (
                <span>{patient.score ?? "N/A"}</span>
            ),
        },
        {
            key: "review",
            render: (patient: DischargedPatient) => (
                <ReviewDischargePatientButton
                    onClick={() => showFeedbackPopup(Number(patient.id))}
                >
                    {pageTranslations.table.feedback}
                </ReviewDischargePatientButton>
            ),
        },
    ];

    return (
        <DischargePatientDetailsTableContainer>
            <Table
                data={data || []}
                // @ts-ignore
                columns={columns}
                enableReviewModal={true}
                noRowsText={pageTranslations.table.noPatient}
            />
            {feedbackModalOpen && selectedPatientData && (
                <DischargePatientModal
                    patient={selectedPatientData}
                    translations={translations}
                    onClose={() => {
                        setFeedbackModalOpen(false);
                        setSelectedPatientData(null); // Clear selected patient data when closing
                    }}
                />
            )}
        </DischargePatientDetailsTableContainer>
    );
};