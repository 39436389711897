// External imports
import React from 'react';

// Internal imports
import {
    ReviewPageTableHead,
    ReviewPageTableRow,
    ReviewPageTableData,
    ReviewPageTableRowHead,
    ReviewPageTable,
    ReviewPageTableBody
} from "./TableComponent.styles";
import { ReviewPageRightTableRowProps } from '../../ReviewTablesStaticData';

// Langiage context
import { useLanguage } from "../../../../../core/translations/LanguageContext";

// Translations
import { RightSectionTableTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../../../core/translations/supportedLanguages";


/**
 * CONSTANTS for translations
 */
const TRANSLATIONS: Record<SupportedLanguage, RightSectionTableTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

// Interface for the table component
export interface ReviewPageRightTableComponentProps {
    tableData: Array<ReviewPageRightTableRowProps>,
    translations: any
}

// Format the percentage to 2 decimal places
function formatPercentage(percentage: string) {
    return parseFloat(percentage).toFixed(2);
}

/**
 * Table component for the right section of the review page
 * @param tableData - data to be displayed in the table
 */
const ReviewPageRightTableComponent: React.FC<ReviewPageRightTableComponentProps> = ({
    tableData,
    translations
}) => {

    console.log("Table Data", tableData);

    const { language } = useLanguage();
    const pageTranslations = TRANSLATIONS[language];

    return (
        <ReviewPageTable>
            <ReviewPageTableHead>
                <ReviewPageTableRow>
                    <ReviewPageTableRowHead>{pageTranslations.actions}</ReviewPageTableRowHead>
                    <ReviewPageTableRowHead>{pageTranslations.percentage}</ReviewPageTableRowHead>
                </ReviewPageTableRow>
            </ReviewPageTableHead>
            <ReviewPageTableBody>
                {tableData.map((data, index) => (
                    <ReviewPageTableRow key={index}>
                        <ReviewPageTableData>{translations[data.action] ?? data.action}</ReviewPageTableData>
                        <ReviewPageTableData>{formatPercentage(data.percentage)}</ReviewPageTableData>
                    </ReviewPageTableRow>
                ))}
                {tableData.length == 0 &&
                    <ReviewPageTableRow>
                        <ReviewPageTableData colSpan={2}>{pageTranslations.noRecord}</ReviewPageTableData>
                    </ReviewPageTableRow>
                }
            </ReviewPageTableBody>
        </ReviewPageTable>
    );
}

export default ReviewPageRightTableComponent;
