import styled from "@emotion/styled";

export const PopupForm=styled.form`
 label {
    font-family: Inter, sans-serif; /* Include font family */
    font-weight: 600; /* Make the label bold */
    display: flex; /* Include flexbox */
    align-items: center; /* Align it center */
    justify-content: space-between; /* Add spacing in middle of elements */
    width: 100%; /* Preview label in full width of div */
}
    `;

export const InputFieldArea = styled.div`
    gap: 10px; /* Add some space */
    display: flex; /* Include flexbox */
    flex-wrap: wrap; /* Wrap flexbox */
`;

export const PopupSelectField =styled.div`
    width: 100%; /* Preview section in full width of div */
    display: flex; /* Include flexbox */
    flex-direction: column; /* Align divs column wise */
    gap: 10px; /* Include spacing */
`;

export const PopupButton = styled.button`
    background-color: red; /* Add background color to no button */
    cursor: pointer; /* Change cursor to pointer */
    border: none; /* Remove border */
    border-radius: 4px; /* Add some border radius */
    padding: 10px; /* Add some padding to button */
    font-size: 14px; /* Provide font size to button text */
    color: white; /* Give the button color white */
    width: 100%; /* Preview text in full width of div */
`;