import axios, { AxiosResponse } from "axios";
import { ClassUrl } from "./AUrl";


/*
The interface for the payload needed to create a class.

@name: The name of the class being created.
@open: Whether the class is open or closed.
@diseases: The diseases that wil present in games sessions for the class.
@starting_patient_no: The number of patients that game sessions start with assoicated with the class.
@patient_arrival_interval: The interval at which patients arrive in game sessions associated with the class.
@scoring_diagnosis: The percentage of the score that is attributed to the diagnosis of the patient.
@scoring_never: The percentage of the score that is attributed to having performed never events.
@scoring_protocol: The percentage of the score that is attributed to having performed actions in the right order.
@images: Whether images are enabled for the class.
 */
export interface CreateClassSchema {
    name: string;
    open: boolean;
    diseases: string[];
    starting_patient_no: number;
    patient_arrival_interval: number;
    scoring_diagnosis: number;
    scoring_protocol: number;
    images: boolean;
}

/*
The interface for the response from the create class endpoint.

@status: The HTTP status code.
@error: The error message.
@data: The data returned from the endpoint.
 */
export interface CreateClassResponse {
    status: number;
    error?: string;
    data?: AuthCodeResponse;
}

/*
The structure of the response data containing the auth code.

@auth_code: The auth code for the class.
 */
export interface AuthCodeResponse {
    auth_code: string;
}


// Function Signature
export type CreateClassType = (payload: CreateClassSchema, jwt: string) => Promise<CreateClassResponse>;

/*
The function to create a class.

@payload: The data needed to create a class.
@jwt: The JWT of the user creating the class.

@returns: The ApiResponse from the create class endpoint.
 */
export async function createClass(payload: CreateClassSchema, jwt: string): Promise<CreateClassResponse> {
    const url = new ClassUrl().create;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }

    let response: AxiosResponse<any>;
    try {
        response = await axios.post<CreateClassResponse | string>(url, payload, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            // Resolve the promise for all HTTP status codes
            validateStatus: () => true
        });

    } catch (error) {
        // Handle network or other Axios-related errors
        return {
            status: 500,
            error: 'Network or other error occurred'
        };
    }

    if (response.status === 201) {
        // Successful response
        return {
            status: response.status,
            data: response.data as AuthCodeResponse
        };
    } else {
        // Other response codes with a string message
        return {
            status: response.status,
            error: response.data as string
        };
    }
}
