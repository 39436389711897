import { ConsultationTypesTranslations } from './types';

export const enConsultationTranslations: ConsultationTypesTranslations = {
    cardiothoracic: "Cardiothoracic Surgery",
    ent: "ENT",
    general_surgery: "General Surgery",
    neurosurgery: "Neurosurgery",
    psychiatry: "Psychiatry",
    trauma_ortho_surgery: "Trauma and Orthopaedic Surgery",
    urology: "Urology",
};