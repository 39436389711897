import { ConsultationTypesTranslations } from './types';

export const deConsultationTranslations: ConsultationTypesTranslations = {
    cardiothoracic: "Herz-Thorax-Chirurgie",
    ent: "Hals-Nasen-Ohren-Heilkunde",
    general_surgery: "Allgemeinchirurgie",
    neurosurgery: "Neurochirurgie",
    psychiatry: "Psychiatrie",
    trauma_ortho_surgery: "Trauma- und Orthopädische Chirurgie",
    urology: "Urologie",
};