import React from 'react';
import {
    ReviewPageLeftSection,
    ClassHeaderSection,
    ClassName,
    AuthCode
} from "./LeftSection.styles";
import ReviewPageStatsOptions from './ReviewPageStatsOptions/ReviewPageStatsOptions';
import ReviewPageLeftTableComponent from './TableComponent/TableComponent';
import { ReviewPageLeftTableRowProps } from '../ReviewTablesStaticData';

export interface ReviewClassLeftSectionProps {
    setSelectedRow: (data: ReviewPageLeftTableRowProps) => void;
    tableData: Array<ReviewPageLeftTableRowProps>;
    classDetails: {
        class_name: string;
        auth_code: string;
        total_students: number;
        total_sessions: number;
        total_patients: number;
        average_score: number;
    };
    translations: any;
}

const ReviewClassLeftSection: React.FC<ReviewClassLeftSectionProps> = ({
                                                                           tableData,
                                                                           setSelectedRow,
                                                                           classDetails, // Receive class details as props
    translations,
                                                                       }) => {

    console.log("Left Section Translations", translations);

    return (
        <ReviewPageLeftSection>
            <ClassHeaderSection>
                <ClassName>{classDetails.class_name}</ClassName>
                <AuthCode>{classDetails.auth_code}</AuthCode>
            </ClassHeaderSection>
            <ReviewPageStatsOptions classDetails={classDetails} />
            <ReviewPageLeftTableComponent tableData={tableData} setSelectedRow={setSelectedRow} translations={translations}/>
        </ReviewPageLeftSection>
    );
}

export default ReviewClassLeftSection;
