// External imports
import React from "react";
import { useParams } from "react-router-dom";
import { FaCalendarAlt, FaWalking } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

// Internal imports
import Tabs from "./components/Tabs/Tabs";
// @ts-ignore
import { AdmissionForm } from "./components/FormComponent/AdmissionForm";
import { DischargeForm } from "./components/FormComponent/DischargeForm";
import { PopupFormComponent } from "./components/FormComponent/PopupFormComponent/PopupFormComponent";
import { GamePageLayout } from "../../../components/game/GamePageLayout/GamePageLayout";
import { formEntryData } from "../../../core/LocalStorage/patients/interface";

// Dependency Injection for Discharge Form Page only
import { PostFormType } from "../../../core/ServerApiLayer/VOne/patients/postForm";
import {
    GetLatestFormDataFromLocalStorageType,
    SetFormsToLocalStorageType,
} from "../../../core/LocalStorage/patients/forms";
import { UpdateFormDocumentationToLocalStorageType } from "../../../core/LocalStorage/patients/notes";
import {
    GetDemographicsFromLocalStorageType,
    GetDiagnosisType,
    UpdateDiagnosisType, updateLocation
} from "../../../core/LocalStorage/patients/demographics";
import { setScoreData, SetScoreDataType } from "../../../core/LocalStorage/patients/scoring";

// Shared Dependency Injection across all the in-game pages
import {
    withGameDependencies,
    WithPatientProps,
} from "../../../core/sharedDependencies/withGameDependencies";
import { WithGameDependenciesProps } from "../../../core/sharedDependencies/types";

// Page Translations
import { DischargeFormTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import {
    FlexContainer,
    PageContainer,
    RightSidePanel,
} from "./DischargeForm.styles";


// Define the translations for the discharge form page
export const DISCHARGE_FORM_TRANSLATIONS: Record<
    SupportedLanguage,
    DischargeFormTranslations
> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * DischargeScreenProps
 */
interface DischargeScreenProps extends WithPatientProps {
    getPatientData: GetDemographicsFromLocalStorageType;
    postFormApiCall: PostFormType;
    setFormsDataToLocalStorage: SetFormsToLocalStorageType;
    getLatestFormFromLocalStorage: GetLatestFormDataFromLocalStorageType;
    updateNotesDataToLocalStorage: UpdateFormDocumentationToLocalStorageType;
    getDiagnosisFromLocalStorage: GetDiagnosisType;
    updateDiagnosisToLocalStorage: UpdateDiagnosisType;
    setScoreData: SetScoreDataType;
}

// Combined Props
type CombinedProps = DischargeScreenProps & WithGameDependenciesProps;

/**
 * DischargeScreenPage
 *
 * ThisPage is the main page for the discharge form.
 */
const DischargeScreenBase: React.FC<CombinedProps> = ({
    getPatientData,
    postFormApiCall,
    setFormsDataToLocalStorage,
    getLatestFormFromLocalStorage,
    updateNotesDataToLocalStorage,
    getDiagnosisFromLocalStorage,
    updateDiagnosisToLocalStorage,
                                                          setScoreData,
    // Injected game dependencies
    sidebarDependencies,
    turnDependencies,
    getJwt,
    demographicsDependencies,
    useLanguage,
    translations,
    refreshPage,
}) => {
    // Get the turn count and patient id
    const { id: id } = useParams<{ id: string }>();
    // @ts-ignore
    const patientId = parseInt(id);
    const turnCount = turnDependencies.getTurnCount() || 0;
    const patient = demographicsDependencies.getDemographicsFromLocalStorage(
        Number(patientId)
    );

    // navigation
    const navigate = useNavigate();

    // Translations
    const { language } = useLanguage();
    const pageTranslations = DISCHARGE_FORM_TRANSLATIONS[language];

    // Get the unread notifications
    const unreadNotifications = turnDependencies.getNotifications();

    /**
     * handleSubmit of the form. This function is called when the form is submitted.
     *
     * @param formData
     */
    const handleSubmit = async (formData: formEntryData): Promise<boolean> => {
        const payload = {
            turn: turnCount,
            patient_id: Number(patientId),
            form_type: formData.form_type,
            discharge_destination: formData.discharge_destination,
            severity: formData.severity,
            patient_details: formData.patient_details,
            actions: formData.actions,
            situation: formData.situation,
        };

        const jwt = getJwt();

        //@ts-ignore
        const response = await postFormApiCall(jwt, payload);

        if (response.status === 201) {
            // Update the notes data in local storage
            //@ts-ignore
            updateNotesDataToLocalStorage(patientId, formData);

            // Update location in local storage
            updateLocation(patientId, formData.discharge_destination);

            // Update the score data in local storage
            //@ts-ignore
            setScoreData(patientId, response.data);

            // Update the turn count
            turnDependencies.updateTurns(4);

            // Redirect to dashboard page
            navigate("/dashboard");

            return true;
        } else {
            console.error("Error posting form:", response.error);
            return false;
        }
    };

    /**
     * tabsArray is an array of objects that contains the data for the tabs - Admission and Discharge.
     */
    const tabsArray = [
        {
            id: 1,
            icon: <FaCalendarAlt size={16} />,
            title: pageTranslations.admissionButton,
            child: (
                <AdmissionForm
                    onSubmit={handleSubmit}
                    setFormsToLocalStorage={setFormsDataToLocalStorage}
                    getLatestFormFromLocalStorage={
                        getLatestFormFromLocalStorage
                    }
                    patientId={Number(patientId)}
                    turn={turnCount}
                />
            ),
        },
        {
            id: 2,
            icon: <FaWalking size={16} />,
            title: pageTranslations.dischargeButton,
            child: (
                <DischargeForm
                    onSubmit={handleSubmit}
                    setFormsToLocalStorage={setFormsDataToLocalStorage}
                    getLatestFormFromLocalStorage={
                        getLatestFormFromLocalStorage
                    }
                    patientId={Number(patientId)}
                    turn={turnCount}
                />
            ),
        },
    ];

    return (
        <>
            <GamePageLayout
                turnCount={turnCount}
                unreadNotifications={unreadNotifications}
                pageTitle={pageTranslations.title}
                patient={patient}
                isDischargeFormOpen={true}
                translations={translations}
                documentationDependencies={sidebarDependencies}
                turnDependencies={turnDependencies}
                demographicsDependencies={demographicsDependencies}
                useLanguage={useLanguage}
                refreshPage={refreshPage}
            >
                <FlexContainer>
                    <PageContainer>
                        <Tabs
                            tabsTitle={pageTranslations.formType}
                            defaultActiveTab={1}
                            tabs={tabsArray}
                        />
                        <RightSidePanel></RightSidePanel>
                    </PageContainer>
                    <PopupFormComponent
                        translations={pageTranslations}
                        diseaseTranslations={translations.diagnosis}
                        getDiagnosisFromLocalStorage={
                            getDiagnosisFromLocalStorage
                        }
                        updateDiagnosisFromLocalStorage={
                            updateDiagnosisToLocalStorage
                        }
                    />
                </FlexContainer>
            </GamePageLayout>
        </>
    );
};

export const DischargeScreenPage =
    withGameDependencies<DischargeScreenProps>(DischargeScreenBase);
