import React, { useState, useEffect } from "react";
import { Modal } from "../Modal";
import {
    ChatExitModalContainer,
    ChatExitModalContentContainer,
    ChatExitModalSearchContentContainer,
    ChatExitModalContentWrapper,
    ChatExitModalButtonContainer,
    ChatExitModalTitle,
    ChatExitModalContentWrapperDivs,
} from "./ChatExitModal.styles";

import { SupportedLanguage } from "../../../../core/translations/supportedLanguages";
import { ExitChatModalTranslation } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { useLanguage } from "../../../../core/translations/LanguageContext";
import SelectBox, { SelectOption } from "../../select-box/SelectBox";
import WebsiteButton from "../../button/WebsiteButton";
import {
    onSelectOptionChange,
    onSelectOptionConversion,
} from "../../select-box/functions/SelectBox.functions";
import {
    DIAGNOSIS_TYPES,
    DIAGNOSIS_TYPES_NAME,
} from "../diagnosis-modal/config/DiagnosisModal.config";
import { getJwt } from "../../../../core/LocalStorage/AuthSession/core";
import { getTurnCount } from "../../../../core/LocalStorage/turnLogic/core";
import { useParams } from "react-router-dom";
import { postNote } from "../../../../core/ServerApiLayer/VOne/patients/postNote";
import { noteData } from "../../../../core/LocalStorage/patients/interface";
/**
 * ChatExitModal Props.
 * @interface ChatExitModalProps
 * @property {() => void} onClose - The function to close the modal.
 */
interface ChatExitModalProps {
    onClose: () => void;
}

/**
 * ChatExitModal component.
 * @component ChatExitModal
 * @description A modal component on exit chat page.
 * @param {ChatExitModalProps} props - The component props.
 * @returns {JSX.Element} The rendered ChatExitModal component.
 */
function ChatExitModal({ onClose }: ChatExitModalProps) {
    const [diagnosisOne, setDiagnosisOne] = useState<SelectOption>();
    const [diagnosisTwo, setDiagnosisTwo] = useState<SelectOption>();
    const [diagnosisThree, setDiagnosisThree] = useState<SelectOption>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const { id } = useParams<{ id: string }>();

    const diagnosisOptions = onSelectOptionConversion(
        DIAGNOSIS_TYPES,
        DIAGNOSIS_TYPES_NAME,
        true
    );

    useEffect(() => {
        if (!diagnosisOne && diagnosisOptions.length > 0) {
            setDiagnosisOne(diagnosisOptions[0]);
        }
    }, [diagnosisOptions]);
    const TRANSLATIONS: Record<SupportedLanguage, ExitChatModalTranslation> = {
        en: enTranslations,
        de: deTranslations,
    };

    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    /**
     * Handle saving the diagnosisOne
     * Updates the patient's diagnosisOne and closes the modal
     */
    const handleSaveDiagnosis = async () => {
        try {
            if (!diagnosisOne || isSubmitting) return;
            let apiInput = `${translations.noteText}\n`;

            // Diagnosis one is always included
            apiInput += `1. ${diagnosisOne.label}\n`;

            // Only include diagnosis two if there is a value
            if (diagnosisTwo) {
                apiInput += `2. ${diagnosisTwo.label}\n`;
            }

            // Only include diagnosis three if there is a value
            if (diagnosisThree) {
                apiInput += `3. ${diagnosisThree.label}\n`;
            }
            console.log("error", error)
            onClose();
            setIsSubmitting(true);


            const jwt = getJwt();
            if (!jwt) {
                setError("Authorization token is missing or expired.");
                return;
            }

            const turnCount = getTurnCount();
            const patientId = parseInt(id || "", 10);
            if (isNaN(patientId)) {
                setError("Invalid patient ID.");
                return;
            }

            const payload = {
                turn: turnCount,
                patient_id: patientId,
                entry_type: "note",
                text_body: apiInput,
            };
            const response = await postNote(jwt, payload);

            if (response.status === 201) {
                //@ts-ignore
                updateNotesDocumentationToLocalStorage(
                    patientId,
                    response.data as noteData["documentation"]
                );
            } else {
                setError(response.error || "Failed to add notes.");
            }


            
            // Add your API call here, if needed
        } catch (error) {
            console.error("Error updating diagnosis:", error);
            // Add error handling UI if needed
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Modal width="fit-content" onRequestClose={() => console.log("Close popup called")}>
            <ChatExitModalContainer>
                <ChatExitModalContentContainer>
                    <ChatExitModalSearchContentContainer>
                        <ChatExitModalTitle>
                            {translations.modalTitle}
                        </ChatExitModalTitle>
                        <ChatExitModalContentWrapper>
                            <ChatExitModalContentWrapperDivs>
                                <SelectBox
                                    value={[
                                        diagnosisOne
                                            ? diagnosisOne
                                            : diagnosisOptions[0],
                                    ]}
                                    options={diagnosisOptions}
                                    onChange={(value: SelectOption) =>
                                        onSelectOptionChange(
                                            value,
                                            diagnosisOptions,
                                            setDiagnosisOne
                                        )
                                    }
                                    placeholder={translations.placeholderOne}
                                />
                            </ChatExitModalContentWrapperDivs>
                            <ChatExitModalContentWrapperDivs>
                                <SelectBox
                                    value={[diagnosisTwo ? diagnosisTwo : ""]}
                                    options={diagnosisOptions}
                                    onChange={(value: SelectOption) =>
                                        onSelectOptionChange(
                                            value,
                                            diagnosisOptions,
                                            setDiagnosisTwo
                                        )
                                    }
                                    placeholder={translations.placeholderTwo}
                                />
                            </ChatExitModalContentWrapperDivs>
                            <ChatExitModalContentWrapperDivs>
                                <SelectBox
                                    value={[
                                        diagnosisThree ? diagnosisThree : "",
                                    ]}
                                    options={diagnosisOptions}
                                    onChange={(value: SelectOption) =>
                                        onSelectOptionChange(
                                            value,
                                            diagnosisOptions,
                                            setDiagnosisThree
                                        )
                                    }
                                    placeholder={translations.placeholderThree}
                                />
                            </ChatExitModalContentWrapperDivs>

                            <ChatExitModalButtonContainer>
                                <WebsiteButton
                                    tag="button"
                                    onClick={handleSaveDiagnosis}
                                    disabled={isSubmitting}
                                >
                                    {translations.saveButton}
                                </WebsiteButton>
                            </ChatExitModalButtonContainer>
                        </ChatExitModalContentWrapper>
                    </ChatExitModalSearchContentContainer>
                </ChatExitModalContentContainer>
            </ChatExitModalContainer>
        </Modal>
    );
}

export default ChatExitModal;
