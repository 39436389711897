// External imports
import React, { useState } from "react";

// Internal imports
import { ClassScoringComponent } from "./ClassScoringComponent/ClassScoringComponent";
import { DiseaseChoicesComponent } from "./DiseaseChoicesComponent/DiseaseChoicesComponent";
import { DiseaseChoiceState } from "../../../../core/metaDataAccess/classes/diseaseChoices/state";
import NewClassFormTabs from "./NewClassTabs/NewClassFormTabs";
import NewClassFormBox from "./NewClassFormBox";
import NewClassFormConfig from "./NewClassConfig/NewClassConfig";
import { NewClassLeftPanelWrapper } from "./NewClassLeftPanel.styles";


/*
The props passed into the form for creating a new class.

@prop diseaseData: the state of disease selection for the new class
 */
export interface DiseaseChoicesProps {
    diseaseData: DiseaseChoiceState
}

/*
The form for creating a new class housing all the subforms needed to configure the class under tabs.
 */
export const NewClassLeftPanel: React.FC<DiseaseChoicesProps> = ({
    diseaseData,
}) => {

    // keeps track of the active tab
    const [activeTab, setActiveTab] = useState("diseases");

    return (
        <NewClassLeftPanelWrapper>
            <NewClassFormTabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />

            <div className="tabContent">
                {activeTab === "diseases" && (
                    <DiseaseChoicesComponent
                        state={diseaseData}
                    />
                )}
                {activeTab === "scoring" && (
                    <NewClassFormBox>
                        <ClassScoringComponent/>
                    </NewClassFormBox>
                )}
                {/*{activeTab === "config" && (*/}
                {/*    <NewClassFormConfig/>*/}
                {/*)}*/}
                {/* Conditional rendering for other tabs' content */}
            </div>
        </NewClassLeftPanelWrapper>
    );
};
