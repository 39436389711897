// External imports
import React, { createElement } from "react";
import { FaCog, FaDisease } from "react-icons/fa";
import { FiTarget } from "react-icons/fi";

// Internal imports
//@ts-ignore
import { NewClassFormTabButton } from "./NewClassFormTabButton";
import { NewClassFormTabsContainer } from "./NewClassFormTabs.styles";
import { NewClassFormStyledButton } from "./NewClassFormTabButton.styles";

// Translation imports
import { useLanguage } from '../../../../../core/translations/LanguageContext';
import { TabTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../../../core/translations/supportedLanguages';

// Constants
const TRANSLATIONS: Record<SupportedLanguage, TabTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * Props interface for the NewClassFormTabs component
 * @interface TabsProps
 * @property {string} activeTab - Currently active tab
 * @property {function} setActiveTab - Function to set the active tab
 */
interface TabsProps {
    activeTab: string;
    setActiveTab: (tab: string) => void;
}

/**
 * Props interface for the NewClassFormTabButton component
 * @interface TabButtonProps
 * @property {boolean} active - Whether the tab is active
 * @property {any} icon - Icon component to render
 * @property {function} onClick - Click handler
 * @property {string} label - Button label
 * @property {string} id - Button ID
 */
interface TabButtonProps {
    active: boolean;
    icon: any;
    onClick: () => void;
    label: string;
    id: string;
}


/**
 * NewClassFormTabs component
 * Renders all tabs for the new class form
 */
const NewClassFormTabs: React.FC<TabsProps> = ({ activeTab, setActiveTab }) => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    const tabs = [
        {
            label: translations.tabs.diseases,
            icon: FaDisease,
            tab: "diseases",
            id: "diseases-create-class-tab"
        },
        {
            label: translations.tabs.scoring,
            icon: FiTarget,
            tab: "scoring",
            id: "scoring-create-class-tab"
        },
        // {
        //     label: translations.tabs.config,
        //     icon: FaCog,
        //     tab: "config",
        //     id: "config-create-class-tab"
        // },
    ];

    return (
        <NewClassFormTabsContainer>
            {tabs.map((tab) => (
                <li key={tab.tab}>
                    <NewClassFormTabButton
                        active={activeTab === tab.tab}
                        onClick={() => setActiveTab(tab.tab)}
                        label={tab.label}
                        icon={tab.icon}
                        id={tab.id}
                    />
                </li>
            ))}
        </NewClassFormTabsContainer>
    );
};

export default NewClassFormTabs;