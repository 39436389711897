import React from "react";
import { startCase } from "lodash";
import {
    OrderConsultationResultContainer,
    OrderConsultationResultList,
    OrderConsultationResultTitle,
} from "./OrderConsultationResult.styles";
import { OrderConsultationResultListItem } from "../orderConsultationResultListItem/OrderConsultationResultListItem";

/**
 * OrderConsultationResultProps
 */
interface OrderConsultationResultProps {
    status: string;
    data: any[];
}

// Translation imports
import { enTranslations } from "../../../translations/en";
import { deTranslations } from "../../../translations/de";
import { ConsultationPageTranslations } from "../../../translations/types";
import { SupportedLanguage } from "../../../../../../core/translations/supportedLanguages";
import { useLanguage } from "../../../../../../core/translations/LanguageContext";
import { formatGameTime } from "../../../../../../core/gameTime/formatTime";
import { turnsToTime } from "../../../../../../core/LocalStorage/turnLogic/core";

/**
 * OrderConsultationResult
 *
 * This component is a list of consultation results. It displays the consultation results that are
 * available or pending. There are two statuses: available and pending.
 */
export const OrderConsultationResult = ({
    status,
    data,
}: OrderConsultationResultProps) => {
    // Constants
    const TRANSLATIONS: Record<
        SupportedLanguage,
        ConsultationPageTranslations
    > = {
        en: enTranslations,
        de: deTranslations,
    };

    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    return (
        <OrderConsultationResultContainer>
            <OrderConsultationResultTitle>{`${startCase(status)} ${
                translations.consultations
            }`}</OrderConsultationResultTitle>
            <OrderConsultationResultList>
                {data && data.length > 0
                    ? data.map((item, index) =>
                          status === "Pending" ? (
                              // For pending results, show details and no buttons
                              <OrderConsultationResultListItem
                                  key={index}
                                  title={item.specialty}
                                  complete={false}
                                  details={`Ordered at ${item.turn_ordered}`}
                                  allowNotes={false}
                                  turn_ordered={item.turn_ordered}
                                  reason={item.reason}
                              />
                          ) : (
                              // For available results, show the full details
                              <OrderConsultationResultListItem
                                  key={index}
                                  title={item.specialty}
                                  complete={true}
                                  details={item.findings}
                                  allowNotes={true}
                                  turn_ordered={item.turn_ordered}
                                  reason={item.reason}
                              />
                          )
                      )
                    : null}
            </OrderConsultationResultList>
        </OrderConsultationResultContainer>
    );
};
