import React, { SyntheticEvent, useState, useEffect } from "react";
import {
    InvestigationFormGridContainer,
    InvestigationFormContainer,
    InvestigationFormGridItemBy3,
    InvestigationFormGridItemBy6,
    InvestigationFormLabel,
    InvestigationFormFieldContainer,
} from "./OrderInvestigationForm.styles";
import SelectBox, {
    SelectOption,
} from "../../../../../components/core/select-box/SelectBox";
import TextArea from "../../../../../components/core/input-form/TextArea";
import ClearSubmitFormButtons from "../../../../../components/core/clear-submit-form-buttons/ClearSubmitFormButtons";
import { onSelectOptionChange } from "../../../../../components/core/select-box/functions/SelectBox.functions";

// Translation imports remain the same...
import { useLanguage } from "../../../../../core/translations/LanguageContext";
import { enTranslations } from "./../../translations/en";
import { deTranslations } from "./../../translations/de";
import { InvestigationPageTranslations } from "./../../translations/types";
import { SupportedLanguage } from "../../../../../core/translations/supportedLanguages";

const TRANSLATIONS: Record<SupportedLanguage, InvestigationPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

interface OrderInvestigationFormProps {
    onSubmit: (
        investigation: string,
        priority: string,
        justification: string
    ) => void;
    investigationTranslations: any;
    priorityTranslations: any;
}

export const OrderInvestigationForm: React.FC<OrderInvestigationFormProps> = ({
                                                                                  onSubmit,
                                                                                  investigationTranslations,
                                                                                  priorityTranslations,
                                                                              }) => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    // Convert options first so we can use them to initialize state

    const investigationOptions = Object.entries(investigationTranslations)
        .map(([key, value]) => ({
            value: key,
            label: value,
        }))
        // @ts-ignore
        .sort((a, b) => a.label.localeCompare(b.label));

    const priorityOptions = Object.entries(priorityTranslations)
        .map(([key, value]) => ({
            label: value,
            value: key,
            turn: undefined,
        }))
        // @ts-ignore
        .sort((a, b) => a.label.localeCompare(b.label));

    // Initialize state with default values
    // @ts-ignore
    const [investigation, setInvestigation] = useState<SelectOption>(investigationOptions[0]);
    // @ts-ignore
    const [priority, setPriority] = useState<SelectOption>(priorityOptions[0]);
    const [justification, setJustification] = useState<string>("");

    // Handle form submit with the default values
    const handleFormSubmit = async () => {
        if (justification.trim().length >= 10) {
            const success = await onSubmit(
                investigation?.value || investigationOptions[0].value,
                priority?.value || priorityOptions[0].value,
                justification
            );
            // @ts-ignore
            if (success) {
                clearForm();
            }
        }
    };

    const clearForm = () => {
        // @ts-ignore
        setInvestigation(investigationOptions[0]);
        // @ts-ignore
        setPriority(priorityOptions[0]);
        setJustification("");
    };

    return (
        <form onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
            <InvestigationFormContainer>
                <div>
                    <InvestigationFormGridContainer>
                        <InvestigationFormGridItemBy3>
                            <InvestigationFormLabel htmlFor="priority">
                                {translations.investigation}:
                            </InvestigationFormLabel>
                            <InvestigationFormFieldContainer>
                                <SelectBox
                                    value={[investigation]}
                                    // @ts-ignore
                                    options={investigationOptions}
                                    onChange={(value: SelectOption) =>
                                        onSelectOptionChange(
                                            value,
                                            // @ts-ignore
                                            investigationOptions,
                                            setInvestigation
                                        )
                                    }
                                />
                            </InvestigationFormFieldContainer>
                        </InvestigationFormGridItemBy3>
                        <InvestigationFormGridItemBy3>
                            <InvestigationFormLabel htmlFor="priority">
                                {translations.priority}:
                            </InvestigationFormLabel>
                            <InvestigationFormFieldContainer>
                                <SelectBox
                                    value={[priority]}
                                    // @ts-ignore
                                    options={priorityOptions}
                                    onChange={(value: SelectOption) =>
                                        onSelectOptionChange(
                                            value,
                                            // @ts-ignore
                                            priorityOptions,
                                            setPriority
                                        )
                                    }
                                />
                            </InvestigationFormFieldContainer>
                        </InvestigationFormGridItemBy3>
                        <InvestigationFormGridItemBy6>
                            <InvestigationFormLabel htmlFor="reasons">
                                {translations.justification}:
                            </InvestigationFormLabel>
                            <InvestigationFormFieldContainer>
                                <TextArea
                                    rows={3}
                                    height="auto"
                                    color="#000"
                                    borderColor="#d1d5db"
                                    value={justification}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLTextAreaElement>
                                    ) => setJustification(e.target.value)}
                                />
                            </InvestigationFormFieldContainer>
                        </InvestigationFormGridItemBy6>
                    </InvestigationFormGridContainer>
                </div>
            </InvestigationFormContainer>

            <ClearSubmitFormButtons
                submitText={translations.submit}
                clearText={translations.clear}
                isSubmitDisabled={justification.trim().length < 10}
                onSubmit={handleFormSubmit}
                onClear={clearForm}
                RenderComponent={() => <></>}
            />
        </form>
    );
};

export default OrderInvestigationForm;