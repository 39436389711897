import { scoreData } from "./interface";
import { getSpecificPatientData, updatePatientData } from "./core";

// Function signature type of setting to local storage
export type SetScoreDataType = (patient_id: number, scoreData: scoreData) => void;

/*
Set the score data for a patient in localStorage.

Here we set the score, correct actions and unnecessary actions for a patient
whcih comes back when we discharge the patient.
 */
export function setScoreData(patient_id: number, scoreData: scoreData): void {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Update the patient's score data
    patientData.scoring = scoreData;

    // Persist the updated data back to localStorage
    updatePatientData(patient_id, patientData);
}




