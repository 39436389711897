// External imports
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";

// Internal imports
//@ts-ignore
import { DrugChartLayout } from "./DrugChartLayout/DrugChartLayout";
import { GamePageLayout } from "../../../components/game/GamePageLayout/GamePageLayout";
import { drugChartData } from "../../../core/LocalStorage/patients/interface";
import AllergyModal from "../../../components/core/modal/allergy-modal/AllergyModal";
import SelectedDrugsListing from "./SelectedDrugsListing/SelectedDrugsListing";

// Shared Dependency Injection across all the in-game pages
import {
    withGameDependencies,
    WithPatientProps,
} from "../../../core/sharedDependencies/withGameDependencies";
import { WithGameDependenciesProps } from "../../../core/sharedDependencies/types";

// Dependency injection
import {
    GetAllergiesFromLocalStorageType,
    GetDrugsFromLocalStorageType,
    SetAllergiesToLocalStorageType,
    SetDrugsToLocalStorageType,
} from "../../../core/LocalStorage/patients/drugs";
import { PostDrugType } from "../../../core/ServerApiLayer/VOne/patients/postDrug";
import { GetAllDrugsType } from "../../../core/ServerApiLayer/VOne/patients/getAllDrugs";
import { UpdateDrugsDocumentationToLocalStorageType } from "../../../core/LocalStorage/patients/notes";
import {
    DrugChartHeader,
    FlexContainer,
    UpdateAllergiesButton,
} from "./DrugChart.styles";

// Translations
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { DrugPageTranslations } from "./translations/types";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import { DrugDataTranslations } from "../../../core/translations/drugs/types";
import { enDrugTranslations } from "../../../core/translations/drugs/en";
import { deDrugTranslations } from "../../../core/translations/drugs/de";

// Translations consts
const TRANSLATIONS: Record<SupportedLanguage, DrugPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

// Drug Translations
const DRUG_TRANSLATIONS: Record<SupportedLanguage, DrugDataTranslations> = {
    en: enDrugTranslations,
    de: deDrugTranslations,
};


// Type for each row of the table
export type TableDrug = {
    turn: number;
    drugName: string;
    dose: string;
    route: string;
};

// The data for one drug in the table. Each row in the table will have this data
export interface TableDataProps {
    turn: number;
    drugName: string;
    route: string;
    dose: string;
}

/**
 * The props for the DrugChart component. This is where the dependency injection happens.
 * @postDrugApiCall: The function to post a drug to the drug chart
 * @getDrugsFromLocalStorage: The function to get the drugs from local storage
 * @setDrugsToLocalStorage: The function to set the drugs to local storage
 * @setAllergiesToLocalStorage: The function to set the allergies to local storage
 */
interface DrugChartProps extends WithPatientProps {
    postDrugApiCall: PostDrugType;
    getDrugsApiCall: GetAllDrugsType;
    getDrugsFromLocalStorage: GetDrugsFromLocalStorageType;
    setDrugsToLocalStorage: SetDrugsToLocalStorageType;
    setAllergiesToLocalStorage: SetAllergiesToLocalStorageType;
    getAllergiesFromLocalStorage: GetAllergiesFromLocalStorageType;
    updateDrugsDocumentationToLocalStorage: UpdateDrugsDocumentationToLocalStorageType;
}

// Combined Props
type CombinedProps = DrugChartProps & WithGameDependenciesProps;

/**
 * The DrugChart component.
 * @unreadNotifications: The unread notifications for the user
 * @turnCount: The turn count of the game
 */
export const DrugChartBase: React.FC<CombinedProps> = ({
    postDrugApiCall,
    getDrugsApiCall,
    getDrugsFromLocalStorage,
    setDrugsToLocalStorage,
    setAllergiesToLocalStorage,
    getAllergiesFromLocalStorage,
    updateDrugsDocumentationToLocalStorage,
    // Injected game dependencies
    sidebarDependencies,
    turnDependencies,
    getJwt,
    demographicsDependencies,
    useLanguage,
    translations,
    refreshPage,
}) => {
    // Get the turn count and patient id
    const { id: id } = useParams<{ id: string }>();
    // @ts-ignore
    const patientId = parseInt(id);
    const turnCount = turnDependencies.getTurnCount();
    const patient = demographicsDependencies.getDemographicsFromLocalStorage(
        Number(patientId)
    );

    // Get the unread notifications
    const unreadNotifications = turnDependencies.getNotifications();

    // Get the translations
    const { language } = useLanguage();
    const pageTranslations = TRANSLATIONS[language];
    const drugNameTranslations = DRUG_TRANSLATIONS[language];

    // Get the jwt token
    const jwt = getJwt();

    const [drugChartArray, setDrugChartArray] = useState<any>([]);
    const [showModal, setShowModal] = useState(false);
    const [allergyForm, setAllergyForm] = useState<string[] | null>(null);

    // Check if allergies are recorded in local storage
    const checkAllergyStatus = () => {
        //@ts-ignore
        const haveAllergyStatus = getAllergiesFromLocalStorage(patientId);
        if (haveAllergyStatus === null) {
            setShowModal(true); // Show modal only allergies haven't been set
        } else if (haveAllergyStatus.length === 0) {
            setAllergyForm(null);
            setShowModal(true);
        } else {
            setAllergyForm(haveAllergyStatus);
            setShowModal(false);
        }
    };

    // Handle Allergy Submission
    const handleSubmitAllergy = (allergies: string[]) => {
        turnDependencies.updateTurns(1);

        const patientIdNumber = patientId;

        // Set allergies to local storage
        setAllergiesToLocalStorage(patientIdNumber, allergies);

        // Update state with the new allergies
        setAllergyForm(allergies);

        // Close the modal after submission
        setShowModal(false);
    };

    // Transform drug data for the table display
    function transformDrugData(drugs: drugChartData): TableDrug[] {
        return Object.keys(drugs).map((turn) => {
            const drug = drugs[turn];
            return {
                turn: parseInt(turn),
                drugName: drug.drug_name,
                dose: drug.dose,
                route: drug.route,
            };
        });
    }

    // Fetch drugs from local storage or API
    const fetchDrugs = () => {
        //@ts-ignore
        const drugData = getDrugsFromLocalStorage(parseInt(patientId));
        if (drugData) {
            const drugs = drugData.drugChart || {};
            setDrugChartArray(transformDrugData(drugs));
        } else {
            const getDrugs = async () => {
                //@ts-ignore
                const response = await getDrugsApiCall(jwt, {
                    patient_id: patientId,
                });
                if (response.status === 200 && response.data) {
                    setDrugChartArray(transformDrugData(response.data));

                    // Update the local storage drugs
                    //@ts-ignore
                    setDrugsToLocalStorage(patientId, response.data);
                }
            };
            getDrugs();
        }
    };

    const handleFormSubmit = async (drugData: any) => {
        //@ts-ignore
        const patientIdNumber = parseInt(patientId);

        console.log("Inside handleFormSubmit", drugData);

        // Create the drug object by splitting the drug data
        const formattedDrugData = drugData.drug.split(", ");

        const drugDataObject = {
            drug_key: drugData.drug_key,
            drugName: formattedDrugData[0],
            dose: formattedDrugData[1],
            route: formattedDrugData[2],
        };

        // Post drug to the server
        const handleApiSubmit = async () => {
            //@ts-ignore
            const response = await postDrugApiCall(jwt, {
                patient_id: patientIdNumber,
                turn: turnCount,
                drug_key: drugDataObject.drug_key,
                drug_name: drugDataObject.drugName,
                dose: drugDataObject.dose,
                route: drugDataObject.route,
            });

            if (response.status === 201 && response.data) {
                // Turn count is updated by 2
                turnDependencies.updateTurns(2);

                // Immediately update the state with the new drug data
                const updatedDrugArray = transformDrugData(
                    response.data.drug_chart
                );
                setDrugChartArray(updatedDrugArray); // Update state with the new data

                // Update the local storage with the new drug data
                setDrugsToLocalStorage(
                    patientIdNumber,
                    response.data.drug_chart
                );

                // Update the local storage documentation with the new drug data
                //@ts-ignore
                updateDrugsDocumentationToLocalStorage(
                    patientId,
                    response.data.drug_chart
                );
            } else {
                console.error("Error posting drug", response.error);
            }
        };
        await handleApiSubmit();
    };

    const openAllergyModal = () => setShowModal(true);
    const closeAllergyModal = () => setShowModal(false);

    useEffect(() => {
        fetchDrugs();
        checkAllergyStatus();
    }, []);

    // @ts-ignore
    return (
        <>
            <GamePageLayout
                turnCount={turnCount}
                unreadNotifications={unreadNotifications}
                pageTitle={pageTranslations.title}
                patient={patient}
                translations={translations}
                documentationDependencies={sidebarDependencies}
                turnDependencies={turnDependencies}
                demographicsDependencies={demographicsDependencies}
                useLanguage={useLanguage}
                refreshPage={refreshPage}
            >
                <DrugChartHeader>
                    <UpdateAllergiesButton onClick={() => openAllergyModal()}>
                        {pageTranslations.updateAllergies}
                    </UpdateAllergiesButton>
                </DrugChartHeader>

                <FlexContainer>
                    <DrugChartLayout
                        initalValuesOfTable={drugChartArray}
                        onSubmit={handleFormSubmit}
                        translations={pageTranslations}
                        drugTranslations={drugNameTranslations}
                    />
                </FlexContainer>
                <SelectedDrugsListing
                    initalTableData={drugChartArray}
                    translations={pageTranslations}
                />

                {showModal && (
                    <AllergyModal
                        onClose={closeAllergyModal}
                        onSubmit={handleSubmitAllergy}
                        translations={pageTranslations}
                    />
                )}
            </GamePageLayout>
        </>
    );
};

export const DrugChart = withGameDependencies<DrugChartProps>(DrugChartBase);
