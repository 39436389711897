import { AxiosResponse } from "axios";
import axios from "axios";

import { ApiResponse } from "../interfaces";
import { UserUrl } from "./AUrl";
import { UserSession } from "./login";

/*
The interface for the response from the refresh token endpoint.

@jwt: The new JWT token to be used for authentication.
@refresh_token: The new refresh token to be used for future refresh requests.
@user_session: The updated session information for the user.
 */
export interface RefreshResponse {
    jwt: string;
    refresh_token: string;
    user_session: UserSession;
}

/*
The function to refresh a JWT using a refresh token.

@refreshToken: The refresh token to be used for generating a new JWT.

Returns the response from the refresh token endpoint.
 */
export async function refreshToken(refreshToken: string): Promise<ApiResponse<RefreshResponse>> {
    const url = new UserUrl().refresh;
    let response: AxiosResponse<any>;

    try {
        response = await axios.post<RefreshResponse | string>(url,
            { refresh_token: refreshToken },
            {
                headers: {
                    "Content-Type": "application/json"
                },
                // Resolve the promise for all HTTP status codes
                validateStatus: () => true
            }
        );
    } catch (error) {
        return {
            status: 500,
            error: "Network or other error occurred"
        };
    }

    if (response.status === 200) {
        return {
            status: response.status,
            data: response.data as RefreshResponse
        };
    } else {
        return {
            status: response.status,
            error: response.data as string
        };
    }
}
