import { ObservationPageTranslations } from "./types";

export const deTranslations: ObservationPageTranslations = {
    title: "Vitalparameter",
    columns: {
        turns: "Turn",
        time: "Zeit",
        bloodPressure: "Blutdruck",
        pulse: "Herzfrequenz",
        respiratoryRate: "Atemfrequenz",
        oxygenSaturation: "Sauerstoffsättigung",
        temperature: "Temperatur",
        mmHg: "mmHg",
        bpm: "/min",
        degCel: "°C",
        percentage: "%",
    },
    takeObs: "Messung",
};
