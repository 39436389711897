import styled from "@emotion/styled";

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
`;
export const FormH3 = styled.p`
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 1rem; /* Reduced font size */
    text-align: center;
    display: flex;
    flex-direction: column; /* Stack text and icon */
    align-items: center;
    gap: 10px;
    width: 100%;

    svg {
        font-size: 2rem; /* Icon size */
    }
`;
export const ProfessionalName = styled.span`
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 0.9rem; /* Subtitle size */
    color: #6b7280; /* Gray for subtler emphasis */
    text-align: center;
    margin-top: 5px;
`;
export const FormH4 = styled.h4`
    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 1rem; /* Reduced font size */
    margin: 10px 0;
    text-align: center;
`;
export const FormLabel = styled.label`
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 5px;
    display: block;
    width: 100%;
`;
export const FormInputFieldArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
`;
export const FormFieldSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
`;
export const FormSubmitButton = styled.button`
    background-color: #0284d2;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    padding: 10px;
    font-size: 0.9rem; /* Reduced size */
    color: white;
    width: 100%;
    text-align: center;
    transition: background-color 0.3s ease;

    &:disabled {
        background-color: #99a0aa;
        cursor: not-allowed;
    }
`;

export const ErrorMessage = styled.div`
    color: red;
    font-size: 0.9rem;
    margin-top: 5px;
    text-align: center;
`;
