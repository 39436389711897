import styled from "@emotion/styled";

export const ChatExitModalContainer = styled.div`
    width: 100%;
    gap: 1rem;
    color: #000000;
    position: relative;
    padding: 2rem 1.5rem;
    border-radius: 0.75rem;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`;

export const ChatExitModalContentContainer = styled.div`
    width: 100%;
    overflow-y: auto;
    max-height: 35em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    button {
        width: 100% !important;
    }
`;

export const ChatExitModalSearchContentContainer = styled.div`
    overflow-y: auto;
    width: 50em;
    height: 24rem;
`;

export const ChatExitModalTitle = styled.h1`
    font-size: 22px;
    margin-top: 5px;
`;

export const ChatExitModalContentWrapper = styled.div`
    margin-top: 1.5rem;
`;

export const ChatExitModalButtonContainer = styled.div`
    margin-top: 1rem;

    button {
        width: 100%;
    }
`;

export const ChatExitModalContentWrapperDivs = styled.div`
    margin-top: 1.5rem;
`;