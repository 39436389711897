// External Imports
import React, { useState } from "react";

// Internal Imports
import {
    OrderInvestigationResultListItemContainer,
    OrderInvestigationResultListItemContent,
    OrderInvestigationResultListItemContentContainer,
    OrderInvestigationResultListItemContentTitle,
    StyledOrderInvestigationResultListItem,
} from "./OrderInvestigationResultListItem.styles";
import TurnTablet from "../../../../notePage/components/TurnTablet/TurnTablet";
import WebsiteButton from "../../../../../../components/core/button/WebsiteButton";
import OrderInvestigationReportModal from "../orderInvestigationReportModal/OrderInvestigationReport";


// Translation
import { useLanguage } from "../../../../../../core/translations/LanguageContext";
import { enTranslations } from "./../../../translations/en";
import { deTranslations } from "./../../../translations/de";
import { InvestigationPageTranslations } from "./../../../translations/types";
import { SupportedLanguage } from "../../../../../../core/translations/supportedLanguages";
import { enInvestigationTranslations} from "../../../../../../core/translations/investigations/en";
import { deInvestigationTranslations} from "../../../../../../core/translations/investigations/de";
import { InvestigationTypesTranslations } from "../../../../../../core/translations/investigations/types";
import { formatGameTime } from "../../../../../../core/gameTime/formatTime";
import { turnsToTime } from "../../../../../../core/LocalStorage/turnLogic/core";


// Set the translations for the page
const TRANSLATIONS: Record<SupportedLanguage, InvestigationPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

const INVESTIGATION_TRANSLATIONS: Record<SupportedLanguage, InvestigationTypesTranslations> = {
    en: enInvestigationTranslations,
    de: deInvestigationTranslations,
};



/**
 * OrderInvestigationResultListItemProps
 */
interface OrderInvestigationResultListItemProps {
    title: string;
    turn_ordered: number;
    details: string;
    complete: boolean;
    notes?: string;
    allowNotes?: boolean;
    image?: string;
    reason?: string;
    onNoteSave?: any;
}

/**
 * OrderInvestigationResultListItem
 * 
 * This component is a list item for the investigation results.
 */
export const OrderInvestigationResultListItem = ({
    title,
    turn_ordered,
    details,
    complete,
    allowNotes,
    notes,
    image,
    reason,
                                                     onNoteSave
}: OrderInvestigationResultListItemProps) => {
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);

    // Language translation
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    const investigationTranslations = INVESTIGATION_TRANSLATIONS[language];

    return (
        <>
            <StyledOrderInvestigationResultListItem>
                <OrderInvestigationResultListItemContainer>
                    <OrderInvestigationResultListItemContentContainer>
                        <OrderInvestigationResultListItemContentTitle>
                            {investigationTranslations[title]}
                        </OrderInvestigationResultListItemContentTitle>
                        <OrderInvestigationResultListItemContent>
                            {complete ? (
                                <WebsiteButton onClick={() => setIsReportModalOpen(true)}>
                                    {/* If image is not null, then button says View Image, else button says View Report */}
                                    {image ? translations.viewImage  : translations.viewReport }
                                </WebsiteButton>
                            ) : (
                                <TurnTablet turn={formatGameTime(turnsToTime(turn_ordered))} />
                            )}
                        </OrderInvestigationResultListItemContent>
                    </OrderInvestigationResultListItemContentContainer>
                </OrderInvestigationResultListItemContainer>
            </StyledOrderInvestigationResultListItem>
            {isReportModalOpen && (
                <OrderInvestigationReportModal
                    title={title}
                    details={details}
                    allowNotes={allowNotes}
                    turn_ordered={turn_ordered}
                    notes={notes}
                    image={image}
                    onClose={() => setIsReportModalOpen(false)}
                    reason={reason}
                    onNoteSave={onNoteSave}
                />
            )}
        </>
    );
}