import styled from "@emotion/styled";

export const DischargePatientContainer = styled.div`
    width: 61em;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem;
    color: #000000;
    position: relative;
    padding: 2rem 1.5rem;
    border-radius: 0.75rem;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`;

export const DischargePatientContentContainer = styled.div`
    overflow-y: auto;
    max-height: 35em;
`;

export const DischargePatientDiagnosisInfoContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
`;

export const DischargePatientFeedbackContainer = styled.div`
    padding-left: 1rem;
    border-left-width: 1px;
    border-color: #d1d5db;
`;

export const DischargePatientFeedbackContent = styled.div`
    color: #374151;
    font-size: 0.9em;
`;

export const DischargePatientFeedbackScore = styled.div`
    margin-top: 1rem;
    font-size: 0.9em;

    span {
        font-weight: 700;
    }
`;

export const RequestFeedbackButton = styled.button`
    padding: 0.75rem 1.5rem;
    background-color: #4a90e2;
    color: #ffffff;
    border: none;
    border-radius: 0.375rem;
    font-weight: 600;
    font-size: 0.9em;
    cursor: pointer;
    transition: background-color 0.2s;
    float: right;
    margin-top: 1rem;

    &:hover {
        background-color: #357abd;
    }
`;