// External Imports
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

// Internal Imports
import {
    BloodTestResultTable,
    BloodTestResultTableBody,
    BloodTestResultTableData,
    BloodTestResultLeftTableData,
    BloodTestResultTableHead,
    BloodTestResultTableRow,
    BloodTestResultTurnPaginationButton,
    BloodTestResultTurnPaginationContainer,
    BloodTestResultTableContainer,
} from "./BloodTestTable.styles";
import { BloodTestResults } from "../bloodOrderPage/config/BloodTestOptions";
import { GamePageLayout } from "../../../components/game/GamePageLayout/GamePageLayout";

// Dependency Injection
import {
    GetBloodsFromLocalStorageType,
    SetBloodsToLocalStorageType,
} from "../../../core/LocalStorage/patients/bloods";
import { GetAllBloodsType } from "../../../core/ServerApiLayer/VOne/patients/getAllBloods";

// Shared Dependency Injection across all the in-game pages
import {
    withGameDependencies,
    WithPatientProps,
} from "../../../core/sharedDependencies/withGameDependencies";
import { WithGameDependenciesProps } from "../../../core/sharedDependencies/types";

// Translations
import { BloodTestTranslations } from "../../../core/translations/bloods/types";
import { enBloodTestTranslations } from "../../../core/translations/bloods/en";
import { deBloodTestTranslations } from "../../../core/translations/bloods/de";
import { useLanguage } from "../../../core/translations/LanguageContext";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import { BloodTestPageTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { GAME_TRANSLATIONS } from "../../../core/translations/gameTranslations";
import { turnsToTime, updateTurnCount } from "../../../core/LocalStorage/turnLogic/core";
import { formatGameTime } from "../../../core/gameTime/formatTime";

// Define translations for page
const TRANSLATIONS: Record<SupportedLanguage, BloodTestPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

// Define translations for blood tests
const BLOOD_TEST_TRANSLATIONS: Record<
    SupportedLanguage,
    BloodTestTranslations
> = {
    en: enBloodTestTranslations,
    de: deBloodTestTranslations,
};

/**
 * BloodTestResult Interface
 */
interface BloodTestResultProps extends WithPatientProps {
    getBloodsApiCall: GetAllBloodsType;
    getBloodsFromLocalStorage: GetBloodsFromLocalStorageType;
    setBloodsFromLocalStorage: SetBloodsToLocalStorageType;
}
type CombinedProps = BloodTestResultProps & WithGameDependenciesProps;

/**
 * Helper function to get the group name for a test
 *
 * @param testName
 * @param translations
 */
function getTestGroupName(
    testName: string,
    translations: BloodTestTranslations
): string {
    for (const [group, tests] of Object.entries(BloodTestResults)) {
        if ((tests as readonly string[]).includes(testName)) {
            return translations.groups[group] || testName;
        }
    }
    return testName;
}

/**
 * Helper function to transform the data into a format that can be used to
 * display the blood test results
 *
 * @param data
 */
function transformData(
    data: any,
    translations: BloodTestTranslations,
    pageTranslations: BloodTestPageTranslations,
    turnCount: number,
) {
    const transformed: any = {};

    Object.entries(data).forEach(([turn, turnData]: any) => {
        const tests = turnData.results;

        Object.entries(tests).forEach(([testName, testValues]: any) => {
            const groupName = getTestGroupName(testName, translations);

            if (!transformed[groupName]) {
                transformed[groupName] = [];
            }

            let testObj = transformed[groupName].find(
                (test: any) => test.name === testName
            );
            if (!testObj) {
                // Add type safety for units
                const unitKey =
                    testValues.units as keyof typeof pageTranslations.units;
                const translatedUnit =
                    pageTranslations.units[unitKey] || testValues.units;

                testObj = {
                    name: testName,
                    displayName: translations.types[testName] || testName,
                    units: translatedUnit,
                    values: {},
                    normalRange: testValues.reference_ranges || "1 - 10",
                    statuses: {},
                };
                transformed[groupName].push(testObj);
            }

            // If turn is < 10, replace it wiht a "-" to make it look better
            let addCount = 30;
            if (groupName === "ABG" || groupName === "VBG") {
                addCount = 5;
            } else if ( testObj.name === "TROPONIN") {
                addCount = 15;
            };
            if (parseInt(turn) + addCount > turnCount) {
                testObj.values[turn] = "-";
                testObj.statuses[turn] = "Normal";
            } else {
                testObj.values[turn] = testValues.value;
                testObj.statuses[turn] = testValues.status || "Normal";
            }


        });
    });

    return transformed;
}

/**
 * BloodTestResultTableComponent
 */
export const BloodTestResultTableComponent = React.memo(
    ({ groupName, tests }: any) => {
        const [currentPage, setCurrentPage] = useState<number>(0);

        const { language } = useLanguage();
        const translations = TRANSLATIONS[language];
        const bloodTranslations = BLOOD_TEST_TRANSLATIONS[language];

        // Ensure we have all unique turn numbers from the transformed data
        const allTurns = tests
            ? Object.keys(tests[Object.keys(tests)[0]]?.values || {})
            : [];

        const uniqueTurns = Array.from(new Set(allTurns)).sort(
            (a: any, b: any) => b - a
        );

        const handlePageChange = (newPage: number) => {
            setCurrentPage(newPage);
        };

        const currentGroupTurns = useMemo(() => {
            const baseIndex = currentPage * 1;
            return uniqueTurns.slice(baseIndex, baseIndex + 3); // Showing 3 turns at a time
        }, [currentPage, uniqueTurns]);

        const handlePreviousClick = useCallback(() => {
            handlePageChange(currentPage - 1);
        }, [currentPage]);

        const handleNextClick = useCallback(() => {
            handlePageChange(currentPage + 1);
        }, [currentPage]);

        const isPreviousDisabled = currentPage === 0;
        const isNextDisabled = currentPage * 1 + 3 >= uniqueTurns.length;

        // Helper function to display values and background based on status
        const displayValue = (turn: string, value: any, status: string) => {
            const addError =
                status?.toLowerCase() === "high" ||
                status?.toLowerCase() === "low";
            return (
                <BloodTestResultTableData
                    key={turn}
                    addError={addError}
                    style={{ backgroundColor: addError ? "pink" : "white" }}
                >
                    {value || "-"}
                </BloodTestResultTableData>
            );
        };

        if (!tests || !Object.keys(tests).length) {
            return null; // Early return if tests data is missing or empty
        }

        return (
            <BloodTestResultTable key={groupName}>
                <BloodTestResultTableHead>
                    <BloodTestResultTableRow addLightBackground>
                        {/* Blood Test Column */}
                        <BloodTestResultLeftTableData fontBold>
                            {groupName}
                        </BloodTestResultLeftTableData>

                        {/* Display Turn Columns */}
                        {currentGroupTurns.map(
                            (turn: string, index: number) => (
                                <BloodTestResultTableData key={turn} fontBold>
                                    <span>{translations.timeTitle} {formatGameTime(turnsToTime(Number(turn)))}</span>
                                    {index === currentGroupTurns.length - 1 && (
                                        <BloodTestResultTurnPaginationContainer>
                                            <BloodTestResultTurnPaginationButton
                                                onClick={handlePreviousClick}
                                                disabled={isPreviousDisabled}
                                            >
                                                <FaCaretLeft />
                                            </BloodTestResultTurnPaginationButton>

                                            <BloodTestResultTurnPaginationButton
                                                onClick={handleNextClick}
                                                disabled={isNextDisabled}
                                            >
                                                <FaCaretRight />
                                            </BloodTestResultTurnPaginationButton>
                                        </BloodTestResultTurnPaginationContainer>
                                    )}
                                </BloodTestResultTableData>
                            )
                        )}

                        {/* Fixed columns for Units and Normal Range */}
                        <BloodTestResultTableData fontBold>
                            {translations.unitsTitle}
                        </BloodTestResultTableData>
                        <BloodTestResultTableData fontBold>
                            {translations.normalRangeTitle}
                        </BloodTestResultTableData>
                    </BloodTestResultTableRow>
                </BloodTestResultTableHead>

                <BloodTestResultTableBody>
                    {/* Map over each test in the group */}
                    {tests.map(
                        ({
                            name,
                            units,
                            values,
                            normalRange,
                            statuses,
                        }: any) => (
                            <BloodTestResultTableRow key={name}>
                                {/* Blood Test Name */}
                                <BloodTestResultLeftTableData>
                                    {bloodTranslations.types[name] || name}
                                </BloodTestResultLeftTableData>

                                {/* Display Values for Each Turn */}
                                {currentGroupTurns.map((turn) => {
                                    const value = values?.[turn];
                                    const status = statuses?.[turn];
                                    const addError =
                                        status?.toLowerCase() === "high" ||
                                        status?.toLowerCase() === "low";

                                    return (
                                        <BloodTestResultTableData
                                            key={turn}
                                            addError={addError}
                                            style={{
                                                backgroundColor: addError
                                                    ? "pink"
                                                    : "white",
                                            }}
                                        >
                                            {value || "-"}
                                        </BloodTestResultTableData>
                                    );
                                })}

                                {/* Display Units and Normal Range at the end of each row */}
                                <BloodTestResultTableData>
                                    {units || "-"}
                                </BloodTestResultTableData>
                                <BloodTestResultTableData>
                                    {normalRange || "1 - 10"}
                                </BloodTestResultTableData>
                            </BloodTestResultTableRow>
                        )
                    )}
                </BloodTestResultTableBody>
            </BloodTestResultTable>
        );
    }
);

/**
 * BloodTestResult Component
 */
const BloodTestResultPageBase: React.FC<CombinedProps> = ({
    getBloodsApiCall,
    getBloodsFromLocalStorage,
    setBloodsFromLocalStorage,
    // Injected game dependencies
    sidebarDependencies,
    turnDependencies,
    getJwt,
    demographicsDependencies,
    useLanguage,
    translations,
    refreshPage,
}) => {
    // Get the turn count and patient id
    const { id: id } = useParams<{ id: string }>();
    // @ts-ignore
    const patientId = parseInt(id);
    const turnCount = turnDependencies.getTurnCount();

    // Get the unread notifications
    const unreadNotifications = turnDependencies.getNotifications();

    // Translations for the ExamPage
    const { language } = useLanguage();
    const bloodTranslations = BLOOD_TEST_TRANSLATIONS[language];
    const pageTranslations = TRANSLATIONS[language];

    const jwt = getJwt();
    const [data, setData] = useState<any>([]);

    // Fetch blood test data from local storage or the API
    useEffect(() => {
        const bloodData = getBloodsFromLocalStorage(Number(patientId));
        if (bloodData) {
            setData(bloodData);
        } else {
            const fetchBloodData = async () => {
                //@ts-ignore
                const response = await getBloodsApiCall(jwt, {
                    patient_id: Number(patientId),
                });
                if (response.status === 201) {
                    // Update the turn count
                    updateTurnCount(3);

                    setData(response.data); // Set data in state for the page
                    //@ts-ignore
                    setBloodsFromLocalStorage(patientId, response.data); // Save data to local storage
                } else {
                    console.error(
                        "Error fetching blood test data:",
                        response.error
                    );
                }
            };
            fetchBloodData();
        }
    }, [getBloodsApiCall, getBloodsFromLocalStorage, jwt, patientId]);

    // Transform the data to a format that can be used to display the blood test results
    const transformedData = useMemo(
        () => transformData(data, bloodTranslations, pageTranslations, turnCount),
        [data, bloodTranslations, translations]
    );

    return (
        <GamePageLayout
            pageTitle={pageTranslations.pageTitle}
            turnCount={turnCount}
            unreadNotifications={unreadNotifications}
            translations={translations}
            useLanguage={useLanguage}
            refreshPage={refreshPage}
            turnDependencies={turnDependencies}
        >
            <BloodTestResultTableContainer>
                {Object.entries(transformedData).map(

                    ([groupName, tests]: any) => (
                        <BloodTestResultTableComponent
                            key={groupName}
                            groupName={groupName}
                            tests={tests}
                            bloodTranslations={bloodTranslations}
                        />
                    )
                )}
            </BloodTestResultTableContainer>
        </GamePageLayout>
    );
};

export const BloodTestResultPage = withGameDependencies<BloodTestResultProps>(
    BloodTestResultPageBase
);
