import React from "react";
// import { startCase } from "lodash";
import {
    OrderInvestigationResultContainer,
    OrderInvestigationResultList,
    OrderInvestigationResultTitle,
} from "./OrderInvestigationResult.styles";
import { OrderInvestigationResultListItem } from "../orderInvestigationResultListItem/OrderInvestigationResultListItem";


// Translation
import { useLanguage } from "../../../../../../core/translations/LanguageContext";
import { enTranslations } from "./../../../translations/en";
import { deTranslations } from "./../../../translations/de";
import { InvestigationPageTranslations } from "./../../../translations/types";
import { SupportedLanguage } from "../../../../../../core/translations/supportedLanguages";


// Set the translations for the page
const TRANSLATIONS: Record<SupportedLanguage, InvestigationPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};


/**
 * OrderInvestigationResultProps
 */
interface OrderInvestigationResultProps {
    status: "available" | "pending";
    data: any[];
    onNoteSave?: any;
}

/**
 * OrderInvestigationResult
 *
 * This component is a list of investigation results. It displays the investigation results that are
 * available or pending. There are two statuses: available and pending.
 */
export const OrderInvestigationResult = ({
                                             status,
                                             data,
                                             onNoteSave
                                         }: OrderInvestigationResultProps) => {

    // Language translation
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    return (
        <OrderInvestigationResultContainer>
            <OrderInvestigationResultTitle>
                {status === 'pending' ? translations.pendingResults : translations.availableResults}
            </OrderInvestigationResultTitle>
            <OrderInvestigationResultList>
                {data && data.length > 0
                    ? data.map((item, index) => (
                        status === "pending" ? (
                            // For pending results, show a tooltip and no buttons
                            <OrderInvestigationResultListItem
                                key={index}
                                title={item.investigation}
                                complete={false}
                                details={`Ordered Turn ${item.turn_ordered}`}
                                image={item.image_path}
                                allowNotes={false}
                                turn_ordered={item.turn_ordered}
                                reason={item.reason}
                            />
                        ) : (
                            // For available results, show the full details
                            <OrderInvestigationResultListItem
                                key={index}
                                title={item.investigation}
                                complete={true}
                                details={item.findings}
                                image={item.image_path}
                                allowNotes={true}
                                turn_ordered={item.turn_ordered}
                                reason={item.reason}
                                onNoteSave={onNoteSave}
                            />
                        )
                    ))
                    : null}
            </OrderInvestigationResultList>
        </OrderInvestigationResultContainer>
    );
};
