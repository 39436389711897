import React, { useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { Modal } from "../../../../../../components/core/modal/Modal";
import {
    ShowInvestigationImageReportModalContentImage,
    ShowInvestigationImageReportModalFormFooter,
    ShowInvestigationReportModalContainer,
    ShowInvestigationReportModalContentContainer,
    ShowInvestigationReportModalContentLabel,
    ShowInvestigationReportModalContentWrapper,
    ShowInvestigationReportModalForm,
    ShowInvestigationReportModalFormTitle,
} from "./ShowInvestigationImageReport.styles";
import TextArea from "../../../../../../components/core/input-form/TextArea";
import WebsiteButton from "../../../../../../components/core/button/WebsiteButton";
import { formatGameTime } from "../../../../../../core/gameTime/formatTime";
import { turnsToTime } from "../../../../../../core/LocalStorage/turnLogic/core";

// Translation
import { useLanguage } from "../../../../../../core/translations/LanguageContext";
import { enTranslations } from "./../../../translations/en";
import { deTranslations } from "./../../../translations/de";
import { InvestigationPageTranslations } from "./../../../translations/types";
import { SupportedLanguage } from "../../../../../../core/translations/supportedLanguages";
import { enInvestigationTranslations} from "../../../../../../core/translations/investigations/en";
import { deInvestigationTranslations} from "../../../../../../core/translations/investigations/de";
import { InvestigationTypesTranslations } from "../../../../../../core/translations/investigations/types";



// Set the translations for the page
const TRANSLATIONS: Record<SupportedLanguage, InvestigationPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

const INVESTIGATION_TRANSLATIONS: Record<SupportedLanguage, InvestigationTypesTranslations> = {
    en: enInvestigationTranslations,
    de: deInvestigationTranslations,
};

/**
 * ShowInvestigationImageReportProps
 */
interface ShowInvestigationImageReportProps {
    investigateName: string;
    turn: number;
    url: string;
    secondUrl?: string;
    imageComment?: string;
    onClose: () => void;
    onSaveNote: (note: string) => void;
}

/**
 * ShowInvestigationImageReport Modal Component
 * @param investigateName
 * @param turn
 * @param url
 * @param secondUrl
 * @param imageComment
 * @param onClose
 * @param onSaveNote
 * @constructor
 */
export const ShowInvestigationImageReport = ({
                                                 investigateName,
                                                 turn,
                                                 url,
                                                 secondUrl,
                                                 imageComment,
                                                 onClose,
                                                 onSaveNote,
                                             }: ShowInvestigationImageReportProps) => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    const investigationTranslations = INVESTIGATION_TRANSLATIONS[language];

    const [comment, setComment] = useState(imageComment ?? "");
    const [displayedImage, setDisplayedImage] = useState(url);
    const [error, setError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const isECG = url.toLowerCase().includes('ecg');

    const handleGetRadiologyHelp = () => {
        if (secondUrl && !isECG) {
            setDisplayedImage(secondUrl);
        }
    };

    const handleSubmit = async () => {
        try {
            if (onSaveNote) {
                await onSaveNote(comment);
                onClose();
            }
        } catch (err) {
            console.error("Failed to save note:", err);
        }
    };

    return (
        <Modal onRequestClose={onClose}>
            <ShowInvestigationReportModalContainer>
                <MdOutlineClose onClick={onClose} />
                <ShowInvestigationReportModalFormTitle>
                    {translations.imageAvailable} {investigationTranslations[investigateName]}
                </ShowInvestigationReportModalFormTitle>
                <ShowInvestigationReportModalContentContainer>
                    <ShowInvestigationReportModalForm
                        onSubmit={(e: React.ChangeEvent<HTMLFormElement>) =>
                            e.preventDefault()
                        }
                    >
                        <ShowInvestigationReportModalContentWrapper
                            margin={false}
                        >
                            <ShowInvestigationReportModalContentLabel>
                                {translations.timeOrdered}: {formatGameTime(turnsToTime(turn))}
                            </ShowInvestigationReportModalContentLabel>
                            <ShowInvestigationImageReportModalContentImage
                                src={displayedImage}
                                alt="Investigation Report Image"
                            />
                        </ShowInvestigationReportModalContentWrapper>

                        {/* Only show radiology help button if not ECG and secondUrl exists */}
                        {secondUrl && !isECG && displayedImage !== secondUrl && (
                            <ShowInvestigationReportModalContentWrapper>
                                <WebsiteButton
                                    type="button"
                                    onClick={handleGetRadiologyHelp}
                                >
                                    {translations.getRadiologyHelp}
                                </WebsiteButton>
                            </ShowInvestigationReportModalContentWrapper>
                        )}

                        <ShowInvestigationReportModalContentWrapper>
                            <ShowInvestigationReportModalContentLabel>
                                {translations.notesSection}:
                            </ShowInvestigationReportModalContentLabel>
                            <TextArea
                                rows={2}
                                placeholder={translations.addAnyNotes}
                                value={comment}
                                onChange={(
                                    e: React.ChangeEvent<HTMLTextAreaElement>
                                ) => setComment(e.target.value)}
                            />
                        </ShowInvestigationReportModalContentWrapper>
                        <ShowInvestigationImageReportModalFormFooter>
                            <WebsiteButton
                                type="submit"
                                tag="button"
                                disabled={!comment.trim()}
                                onClick={handleSubmit}
                            >
                                {translations.save}
                            </WebsiteButton>
                        </ShowInvestigationImageReportModalFormFooter>
                    </ShowInvestigationReportModalForm>
                </ShowInvestigationReportModalContentContainer>
            </ShowInvestigationReportModalContainer>
        </Modal>
    );
};

export default ShowInvestigationImageReport;