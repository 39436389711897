// This file gets the demographics of the patient from the local storage for
// rendering in the patient sidebar.

// Interface for the demographics object.
import { getSpecificPatientData, updatePatientData } from "./core";

export interface Demographics {
    patientId: number;
    faceURL: string;
    name: string;
    location: string;
    symptom: string;
    age: string;
    sex: string;
    diagnosis: string | null;
}

// Signature function for GetDemographicsFromLocalStorage
export type GetDemographicsFromLocalStorageType = (
    patientId: number
) => Demographics | null;

// GetDemographicsFromLocalStorage
//
// This function gets the demographics of the patient from the local storage.
export const getDemographicsFromLocalStorage: GetDemographicsFromLocalStorageType =
    (patientId) => {
        const patient = getSpecificPatientData(patientId);
        if (patient) {
            return {
                patientId: patient.admin.id,
                faceURL: patient.face_image_path,
                name: patient.demographic.full_name,
                location: patient.admin.location,
                symptom: patient.symptoms.presenting_complaint,
                age: patient.demographic.age.toString(),
                sex: patient.demographic.gender,
                diagnosis: patient.diagnosis,
            };
        }
        return null;
    };

// Function signature for update location
export type UpdateLocationType = (patientId: number, location: string) => void;

// UpdateLocation
//
// This function updates the location of the patient in the local storage.
export const updateLocation: UpdateLocationType = (patientId, location) => {
    const patient = getSpecificPatientData(patientId);

    // If location is HOME_DISCHARGE, then set status to "Discharged" as well
    if (patient) {
        patient.admin.location = location;
        if (location === "HOME_DISCHARGE" || location === "WARD_DISCHARGE" || location === "ICU_DISCHARGE" || location === "CCU_DISCHARGE") {
            patient.status = "Discharged";
        updatePatientData(patientId, patient);
    }};
};

// Function signature for update diagnosis
export type UpdateDiagnosisType = (
    patientId: number,
    diagnosis: string
) => void;

// UpdateDiagnosis
//
// This function updates the diagnosis of the patient in the local storage.
export const updateDiagnosis: UpdateDiagnosisType = (
    patient_id: number,
    newDiagnosis: string
) => {
    // Get the current patient data from localStorage
    let patientData = getSpecificPatientData(patient_id);
    if (!patientData) {
        console.error(`No patient data found for patient ID: ${patient_id}`);
        return;
    }

    // Update the diagnosis
    patientData.diagnosis = newDiagnosis;

    // Update the patient data in localStorage
    updatePatientData(patient_id, patientData);
};

// Function signature for get diagnosis
export type GetDiagnosisType = (patientId: number) => string | null;

// GetDiagnosis
//
// This function gets the diagnosis of the patient from the local storage.
export const getDiagnosis: GetDiagnosisType = (patientId) => {
    const patient = getSpecificPatientData(patientId);
    if (patient) {
        return patient.diagnosis;
    }
    return null;
};
