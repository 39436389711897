import { DrugDataTranslations } from "./types";

export const enDrugTranslations: DrugDataTranslations = {
    FOUR_DMAP: "4-DMAP, IV, 250 mg",
    ACETYLCYSTEIN: "Acetylcysteine, IV, 1000 mg",
    ADENOSIN: "Adenosine, IV, 6 mg",
    ADRENALIN_IM: "Adrenaline, IM, 0.5 mg",
    ADRENALIN_IV_1MG: "Adrenaline, IV, 1 mg",
    ADRENALIN_IV_PERFUSER: "Adrenaline, IV, 5 mcg/min via Perfusor",
    AJMALIN: "Ajmaline, IV, 50 mg over 5 min",
    ALTEPLASE_IV_60MIN: "Alteplase, IV, 90 mg over 60 min",
    ALTEPLASE_IV_BOLUS: "Alteplase, IV, 10 mg Bolus",
    AMIODARON: "Amiodarone, IV, 150 mg",
    AMLODIPIN: "Amlodipine, Oral, 5 mg",
    AMOXICILLIN_CLAVULANSAURE_IV: "Co-Amoxiclav, IV, 1200 mg",
    AMOXICILLIN_CLAVULANSAURE_ORAL: "Co-Amoxiclav, Oral, 625 mg",
    AMOXICILLIN_ORAL: "Amoxicillin, Oral, 500 mg",
    AMPICILLIN_IV: "Ampicillin, IV, 2000 mg",
    AMPICILLIN_ORAL: "Ampicillin, Oral, 2000 mg",
    AMPICILLIN_SULBACTAM: "Ampicillin, IV, 3000 mg",
    ASS_100: "Aspirin, Oral, 75 mg", // Common low-dose aspirin in the UK is 75 mg, used for cardiovascular protection.
    ASS_250: "Aspirin, IV, 250 mg",
    ASS_1000_IV: "Aspirin, IV, 1000 mg",
    ASS_1000_ORAL: "Aspirin, Oral, 900 mg", // Confirmed as a possible high-dose for pain/fever.
    ATROPIN: "Atropine, IV, 0.5 mg",
    AZITHROMYCIN: "Azithromycin, IV, 500 mg",
    AZITHROMYCIN_ORAL: "Azithromycin, Oral, 500 mg",
    BUDESONID: "Budesonide, Inhaled, 1 mg",
    BUTYLSCOPOLAMIN: "Buscopan, IV, 20 mg",
    BUTYLSCOPOLAMIN_ORAL: "Buscopan, Oral, 20 mg",
    CAFEDRIN_THEODRENALIN: "Cafedrine, IV, 40/2 mg",
    CALCIUMGLUCONAT: "Calcium Gluconate, IV, 10 ml",
    CANDESARTAN: "Candesartan, Oral, 8 mg",
    CARBAMAZEPIN: "Carbamazepine, Oral, 200 mg",
    CARVEDILOL: "Carvedilol, Oral, 25 mg",
    CEFOTAXIM: "Cefotaxime, IV, 2000 mg",
    CEFPODOXIM: "Cefpodoxime, Oral, 200 mg",
    CEFTAZIDIM: "Ceftazidime, IV, 2000 mg",
    CEFTRIAXON: "Ceftriaxone, IV, 2000 mg",
    CEFUROXIM_IV: "Cefuroxime, IV, 1500 mg",
    CEFUROXIM_ORAL: "Cefuroxime, Oral, 500 mg",
    CIMETIDIN: "Cimetidine, IV, 200 mg",
    CIPROFLOXACIN: "Ciprofloxacin, IV, 400 mg",
    CIPROFLOXACIN_ORAL: "Ciprofloxacin, Oral, 500 mg", // Oral dose in the NHS is typically 500 mg.
    CLARITHROMYCIN: "Clarithromycin, IV, 500 mg",
    CLARITHROMYCIN_ORAL: "Clarithromycin, Oral, 500 mg",
    CLEMASTIN: "Clemastine, IV, 2 mg",
    CLINDAMYCIN: "Clindamycin, IV, 600 mg",
    CLINDAMYCIN_ORAL: "Clindamycin, Oral, 600 mg",
    CLONAZEPAM: "Clonazepam, IV, 1 mg",
    CLONIDIN: "Clonidine, IV, 0.05 mg",
    CLONIDIN_ORAL: "Clonidine, Oral, 0.15 mg",
    CLOPIDOGREL_300: "Clopidogrel, Oral, 300 mg",
    CLOPIDOGREL_75: "Clopidogrel, Oral, 75 mg",
    COTRIMOXAZOL: "Cotrimoxazole, IV, 960 mg",
    COTRIMOXAZOL_ORAL: "Cotrimoxazole, Oral, 960 mg",
    CYCLIZINE_ORAL: "Cyclizine, Oral, 50 mg",
    DABIGATRAN: "Dabigatran, Oral, 110 mg",
    DAPTOMYCIN: "Daptomycin, IV, 500 mg",
    DEXAMETHASON_40: "Dexamethasone, IV, 40 mg",
    DEXAMETHASON_8: "Dexamethasone, IV, 8 mg",
    DIAZEPAM: "Diazepam, IV, 10 mg",
    DIAZEPAM_ORAL: "Diazepam, Oral, 10 mg",
    DICLOFENAC: "Diclofenac, Oral, 50 mg",
    DIGITOXIN: "Digitoxin, Oral, 0.07 mg",
    DIGOXIN: "Digoxin, Oral, 0.25 mg",
    DIMENHYDRINAT: "Dimenhydrinate, IV, 62 mg",
    DOBUTAMIN: "Dobutamine, IV, 200 mcg/min via Perfusor",
    DOXYCYCLIN: "Doxycycline, Oral, 200 mg",
    ENALAPRIL: "Enalopril, Oral, 10 mg",
    ENOXAPARIN_20: "Enoxaparin, SC, 20 mg",
    ENOXAPARIN_40: "Enoxaparin, SC, 40 mg",
    ERYTHROMYCIN: "Erythromycin, IV, 500 mg",
    ERYTHROMYCIN_250: "Erythromycin, IV, 250 mg",
    ESKETAMIN: "Esketamine, IV, 15 mg",
    ESMOLOL: "Esmolol, IV, 50 mg",
    FLECAINID: "Flecainide, Oral, 100 mg",
    FLUCLOXACILLIN: "Flucloxacillin, IV, 1000 mg",
    FLUMAZENIL: "Flumazenil, IV, 0.2 mg",
    FONDAPARINUX_2_5: "Fondaparinux, SC, 2.5 mg",
    FONDAPARINUX_7_5: "Fondaparinux, SC, 7.5 mg",
    FOSFOMYCIN: "Fosfomycin, IV, 4000 mg",
    FOSFOMYCIN_ORAL: "Fosfomycin, Oral, 3000 mg",
    FUROSEMID: "Furosemide, IV, 40 mg",
    GENTAMICIN: "Gentamycin, IV, 200 mg",
    GLUCOSE_10: "Glucose 10%, IV, 250 ml",
    GLUCOSE_40: "Glucose 40%, IV, 10 ml",
    GLUCOSE_5: "Glucose 5%, IV, 500 ml",
    GLYCEROLTRINITRAT: "GTN, Sublingual, 1 spray",
    HALOPERIDOL: "Haloperidol, IM, 5 mg",
    HYDROCHLOROTHIAZIDE: "Hydrochlorothiazide, Oral, 25 mg",
    HEPARIN: "Heparin, IV, 5000 IE",
    HYDROCHLOROTHIAZID: "Hydrochlorothiazide, Oral, 25 mg",
    HYDROCORTISON: "Hydrocortisone, IV, 100 mg",
    HYDROXOCOBALAMIN: "Hydroxocobalamin, IV, 5000 mg",
    IBUPROFEN: "Ibuprofen, Oral, 400 mg", // Typical NHS dose is 400 mg.
    IMIPENEM_CILASTATIN: "Imipenem with Cilastatin, IV, 1000 mg",
    IPRATROPIUMBROMID: "Ipratropium Bromide, Inhaled, 250 mcg",
    KALIUMCHLORID: "Potassium Chloride 7.45%, IV, 20 ml",
    KETAMIN: "Ketamine, IV, 30 mg",
    LAMOTRIGIN: "Lamotrigine, Oral, 25 mg",
    LEVETIRACETAM: "Levetiracetam, IV, 500 mg",
    LEVETIRACETAM_ORAL: "Levetiracetam, Oral, 500 mg",
    LEVOFLOXACIN: "Levofloxacin, IV, 500 mg",
    LEVOFLOXACIN_ORAL: "Levofloxacin, Oral, 500 mg",
    LORAZEPAM: "Lorazepam, Oral, 1 mg",
    MAGNESIUMSULFAT: "Magnesium Sulphate 50%, IV, 10 ml",
    MANNITOL: "Mannitol 15%, IV, 250 ml",
    MEDIZINISCHE_KOHLE: "Activated Charcoal, Oral, 50 mg",
    MELPERON: "Melperone, Oral, 50 mg",
    MEROPENEM: "Meropenem, IV, 1000 mg",
    METAMIZOL: "Metamizole, IV, 1000 mg",
    METAMIZOL_ORAL: "Metamizole, Oral, 500 mg",
    METHYLPREDNISOLON: "Methylprednisolone, IV, 500 mg",
    METOCLOPRAMID: "Metoclopramide, IV, 10 mg",
    METOCLOPRAMID_ORAL: "Metoclopramide, Oral, 10 mg",
    METOPROLOL: "Metoprolol, IV, 5 mg",
    METOPROLOL_ORAL: "Metoprolol, Oral, 47.5 mg",
    METRONIDAZOL: "Metronidazole, IV, 1000 mg",
    METRONIDAZOL_ORAL: "Metronidazole, Oral, 400 mg", // Oral dose for NHS is typically 400 mg.
    MIDAZOLAM: "Midazolam, IV, 2 mg",
    MORPHIN: "Morphine, IV, 10 mg", // 10 mg is a typical NHS dose.
    MOXIFLOXACIN: "Moxifloxacin, Oral, 400 mg",
    MOXIFLOXACIN_ORAL: "Moxifloxacin, IV, 400 mg",
    NALOXON: "Naloxone, IV, 0.1 mg",
    NATRIUMHYDROGENCARBONAT: "Sodium Bicarbonate 8.4%, IV, 100 ml",
    NITRENDIPIN: "Nitrendipine, Oral, 20 mg",
    NITROFURANTOIN: "Nitrofurantoin, Oral, 100 mg",
    NORADRENALIN: "Noradrenaline, IV, 10 mcg",
    OLANZAPIN: "Olanzapine, Oral, 5 mg",
    ONDANSETRON: "Ondansetron, IV, 8 mg",
    PANTOPRAZOL: "Pantoprazole, IV, 40 mg",
    PANTOPRAZOL_80: "Pantoprazole, IV, 80 mg",
    PANTOPRAZOL_ORAL: "Pantoprazole, Oral, 40 mg",
    PARACETAMOL: "Paracetamol, IV, 1 g", // Corrected to 1 g for IV Paracetamol.
    PARACETAMOL_ORAL: "Paracetamol, Oral, 1 g", // Typical oral dose is 1 g.
    PENICILLIN_G: "Benzylpenicillin, IV, 3000 mg",
    PENICILLIN_V: "Penicillin V, Oral, 1.5 Mio IE",
    PHYSOSTIGMIN: "Physostigmine, IV, 2 mg",
    PIPERACILLIN_TAZOBACTAM: "Tazocin, IV, 4500 mg",
    PIRITRAMID: "Piritramide, IV, 5 mg",
    PRASUGREL_10: "Prasugrel, Oral, 10 mg",
    PRASUGREL_60: "Prasugrel, Oral, 60 mg",
    PREDNISOLON_50: "Prednisolone, IV, 50 mg",
    PREDNISOLON_500: "Prednisolone, IV, 500 mg",
    PROMETHAZIN: "Promethazine, Oral, 25 mg",
    PROPAFENON: "Propafenone, IV, 50 mg",
    PROPAFENON_ORAL: "Propafenone, Oral, 150 mg",
    PROPOFOL: "Propofol, IV, 20 mg",
    PROPRANOLOL: "Propranolol, Oral, 80 mg",
    QUETIAPIN: "Quetiapine, Oral, 50 mg",
    RAMIPRIL: "Ramipril, Oral, 5 mg",
    REPROTEROL: "Reproterol, IV, 0.09 mg",
    RIFAMPICIN: "Rifampicin, IV, 600 mg",
    RISPERIDON: "Risperidone, Oral, 1 mg",
    RIVAROXABAN: "Rivaroxaban, Oral, 15 mg",
    SALBUTAMOL_INHALER: "Salbutamol, Inhaled via Inhaler with Spacer, 2 puffs",
    SALBUTAMOL: "Salbutamol, Inhaled via Nebuliser, 2.5 mg",
    SPIRONOLACTON: "Spironolactone, Oral, 50 mg",
    SUMATRIPTAN: "Sumatriptan, Oral, 50 mg",
    TAMSULOSIN: "Tamsulosine, Oral, 0.4 mg",
    THEOPHYLLIN: "Theophylline, IV, 200 mg over 20 min",
    TICAGRELOR: "Ticagrelor, Oral, 90 mg",
    TOLUIDINBLAU: "Toluidine Blue, IV, 200 mg",
    TORASEMID: "Torasemide, Oral, 5 mg",
    TRANEXAMSAURE: "Tranexamic Acid, IV, 1000 mg",
    URAPIDIL: "Urapidil, IV, 10 mg",
    VALPROAT: "Valproate, Oral, 500 mg",
    VANCOMYCIN: "Vancomycin, IV, 1000 mg",
    VERAPAMIL: "Verapamil, IV, 5 mg",
    VITAMIN_B_1: "Vitamin B1, IV, 100 mg",
    VITAMIN_B_12: "Vitamin B12, IV, 1 mg",
    VITAMIN_B_6: "Vitamin B6, IV, 20 mg",
    VITAMIN_K: "Vitamin K, IV, 10 mg",
    VOLLELEKTROLYTLOSUNG: "Sodium Chloride, IV, 500 ml",
    SAUERSTOFF_4: "Oxygen, Inhaled, 4 l/min via Nasal Cannula",
    SAUERSTOFF_10: "Oxygen, Inhaled, 10 l/min via Mask",
    SAUERSTOFF_15: "Oxygen, Inhaled, 15 l/min via Non-Rebreather Mask",
    ANTICOAGULANT_ACS_ANY: "Anticoagulant suitable for Acute Coronary Syndrome",
    ANTIPLATELET_ADD: "Antiplatelet therapy",
    BETA_BLOCKER: "Beta Blocker"
};
