import { LocationTypesTranslations } from "./types";

export const enLocationTranslations: LocationTypesTranslations = {
    WAITING_ROOM: "Waiting Room",
    HOME_DISCHARGE: "Home",
    WARD_DISCHARGE: "Admitted to Ward",
    ICU_DISCHARGE: "Admitted to ICU",
    HDU_DISCHARGE: "Admitted to HDU",
    Majors: "Majors",
    Resus: "Resus",
    WaitingRoom: "Waiting Room",
};
