import { ClassScore, ScoreType } from "./ClassScore";


/*
This class handles the score changes for the diagnosis, protocol, and never event scores.

diagnosisScore: The diagnosis score.
protocolScore: The protocol score.
 */
export class ScoreController {
    diagnosisScore: ClassScore;
    protocolScore: ClassScore;

    constructor(diagnosis: number, protocol: number,
                diagnosisLocked: boolean, protocolLocked: boolean) {
        this.diagnosisScore = new ClassScore(
            diagnosis,
            ScoreType.Diagnosis,
            false
        );
        this.protocolScore = new ClassScore(
            protocol,
            ScoreType.Protocol,
            false
        );
        this.checkTotalScores();
        this.diagnosisScore.locked = diagnosisLocked;
        this.protocolScore.locked = protocolLocked;
    }

    /*
    Calculate the total number of score types that are not locked and the total available score.

    input: The score type to exclude from the calculation (this is the score being changed).

    returns: A tuple with the total number of free scores and the total available score.
     */
    calculateTotalFree(input: ScoreType): [number, number] {
        let totalFree = 0;
        let totalAvailable = 0;
        if (!this.protocolScore.locked && input !== ScoreType.Protocol) {
            totalFree += 1;
            totalAvailable += this.protocolScore.score;
        }

        if (!this.diagnosisScore.locked && input !== ScoreType.Diagnosis) {
            totalFree += 1;
            totalAvailable += this.diagnosisScore.score;
        }
        return [totalFree, totalAvailable]
    }

    /*
    Check if the total scores add up to 100, if they do not then throw an error.
     */
    checkTotalScores() {
        let total = this.diagnosisScore.score + this.protocolScore.score;
        if (total !== 100) {
            throw new Error("The total scores do not add up to 100")
        }
    }

    /*
    Shave the delta down to the total available score if the delta is greater than the total available score.

    delta: The change in score.
    totalFree: The total available score to be changed.

    returns: The new delta.
     */
    shaveDelta(delta: number, totalFree: number): number {
        if (delta > totalFree) {
            return totalFree
        }
        return delta
    }

    /*
    Calculates the change available for each score change.

    score: The new score for the score type.
    scoreType: The score type.
    classInstance: The class instance to change the score for.

    returns: A tuple with a boolean indicating if the score was changed and the change in score.
     */
    primeScoreChange(score: number, scoreType: ScoreType, classInstance: ClassScore): [boolean, number] {
        if (score < 0 || score > 100) {
            return [false, 0]
        }
        if (classInstance.locked) {
            return [false, 0]
        }
        let totalFree = this.calculateTotalFree(scoreType);
        if (totalFree[0] === 0) {
            return [false, 0]
        }
        const delta = this.shaveDelta((score - classInstance.score), totalFree[1]);

        classInstance.score += delta;
        let sideDelta = delta / totalFree[0];
        return [true, sideDelta]
    }

    /*
    Change the diagnosis score.

    score: The new score target for the diagnosis score.
     */
    changeDiagnosisScore(score: number) {
        let outcome = this.primeScoreChange(score, ScoreType.Diagnosis, this.diagnosisScore);
        if (!outcome[0]) {
            return
        }
        if (!this.protocolScore.locked) {
            this.protocolScore.score -= outcome[1];
        }
        this.checkTotalScores();
    }

    /*
    Change the protocol score.
     */
    changeProtocolScore(score: number) {
        let outcome = this.primeScoreChange(score, ScoreType.Protocol, this.protocolScore);
        if (!outcome[0]) {
            return
        }
        if (!this.diagnosisScore.locked) {
            this.diagnosisScore.score -= outcome[1];
        }
        this.checkTotalScores();
    }
}
