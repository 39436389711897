export const DrugOptions = [
    "FOUR_DMAP",
    "ACETYLCYSTEIN",
    "ADENOSIN",
    "ADRENALIN_IM",
    "ADRENALIN_IV_1MG",
    "ADRENALIN_IV_PERFUSER",
    "AJMALIN",
    "ALTEPLASE_IV_60MIN",
    "ALTEPLASE_IV_BOLUS",
    "AMIODARON",
    "AMLODIPIN",
    "AMOXICILLIN_CLAVULANSAURE_IV",
    "AMOXICILLIN_CLAVULANSAURE_ORAL",
    "AMOXICILLIN_ORAL",
    "AMPICILLIN_IV",
    "AMPICILLIN_ORAL",
    "AMPICILLIN_SULBACTAM",
    "ASS_100",
    "ASS_250",
    "ASS_1000_IV",
    "ASS_1000_ORAL",
    "ATROPIN",
    "AZITHROMYCIN",
    "AZITHROMYCIN_ORAL",
    "BUDESONID",
    "BUTYLSCOPOLAMIN",
    "BUTYLSCOPOLAMIN_ORAL",
    "CAFEDRIN_THEODRENALIN",
    "CALCIUMGLUCONAT",
    "CANDESARTAN",
    "CARBAMAZEPIN",
    "CARVEDILOL",
    "CEFOTAXIM",
    "CEFPODOXIM",
    "CEFTAZIDIM",
    "CEFTRIAXON",
    "CEFUROXIM_IV",
    "CEFUROXIM_ORAL",
    "CIMETIDIN",
    "CIPROFLOXACIN",
    "CIPROFLOXACIN_ORAL",
    "CLARITHROMYCIN",
    "CLARITHROMYCIN_ORAL",
    "CLEMASTIN",
    "CLINDAMYCIN",
    "CLINDAMYCIN_ORAL",
    "CLONAZEPAM",
    "CLONIDIN",
    "CLONIDIN_ORAL",
    "CLOPIDOGREL_300",
    "CLOPIDOGREL_75",
    "COTRIMOXAZOL",
    "COTRIMOXAZOL_ORAL",
    "CYCLIZINE_ORAL",
    "DABIGATRAN",
    "DAPTOMYCIN",
    "DEXAMETHASON_40",
    "DEXAMETHASON_8",
    "DIAZEPAM",
    "DIAZEPAM_ORAL",
    "DICLOFENAC",
    "DIGITOXIN",
    "DIGOXIN",
    "DIMENHYDRINAT",
    "DOBUTAMIN",
    "DOXYCYCLIN",
    "ENALAPRIL",
    "ENOXAPARIN_20",
    "ENOXAPARIN_40",
    "ERYTHROMYCIN",
    "ERYTHROMYCIN_250",
    "ESKETAMIN",
    "ESMOLOL",
    "FLECAINID",
    "FLUCLOXACILLIN",
    "FLUMAZENIL",
    "FONDAPARINUX_2_5",
    "FONDAPARINUX_7_5",
    "FOSFOMYCIN",
    "FOSFOMYCIN_ORAL",
    "FUROSEMID",
    "GENTAMICIN",
    "GLUCOSE_10",
    "GLUCOSE_40",
    "GLUCOSE_5",
    "GLYCEROLTRINITRAT",
    "HALOPERIDOL",
    "HCT",
    "HEPARIN",
    "HYDROCHLOROTHIAZID",
    "HYDROCORTISON",
    "HYDROXOCOBALAMIN",
    "IBUPROFEN",
    "IMIPENEM_CILASTATIN",
    "IPRATROPIUMBROMID",
    "KALIUMCHLORID",
    "KETAMIN",
    "LAMOTRIGIN",
    "LEVETIRACETAM",
    "LEVETIRACETAM_ORAL",
    "LEVOFLOXACIN",
    "LEVOFLOXACIN_ORAL",
    "LORAZEPAM",
    "MAGNESIUMSULFAT",
    "MANNITOL",
    "MEDIZINISCHE_KOHLE",
    "MELPERON",
    "MEROPENEM",
    "METAMIZOL",
    "METAMIZOL_ORAL",
    "METHYLPREDNISOLON",
    "METOCLOPRAMID",
    "METOCLOPRAMID_ORAL",
    "METOPROLOL",
    "METOPROLOL_ORAL",
    "METRONIDAZOL",
    "METRONIDAZOL_ORAL",
    "MIDAZOLAM",
    "MORPHIN",
    "MOXIFLOXACIN",
    "MOXIFLOXACIN_ORAL",
    "NALOXON",
    "NATRIUMHYDROGENCARBONAT",
    "NITRENDIPIN",
    "NITROFURANTOIN",
    "NORADRENALIN",
    "OLANZAPIN",
    "ONDANSETRON",
    "PANTOPRAZOL",
    "PANTOPRAZOL_80",
    "PANTOPRAZOL_ORAL",
    "PARACETAMOL",
    "PARACETAMOL_ORAL",
    "PENICILLIN_G",
    "PENICILLIN_V",
    "PHYSOSTIGMIN",
    "PIPERACILLIN_TAZOBACTAM",
    "PIRITRAMID",
    "PRASUGREL_10",
    "PRASUGREL_60",
    "PREDNISOLON_50",
    "PREDNISOLON_500",
    "PROMETHAZIN",
    "PROPAFENON",
    "PROPAFENON_ORAL",
    "PROPOFOL",
    "PROPRANOLOL",
    "QUETIAPIN",
    "RAMIPRIL",
    "REPROTEROL",
    "RIFAMPICIN",
    "RISPERIDON",
    "RIVAROXABAN",
    "SALBUTAMOL_INHALER",
    "SALBUTAMOL",
    "SPIRONOLACTON",
    "SUMATRIPTAN",
    "TAMSULOSIN",
    "THEOPHYLLIN",
    "TICAGRELOR",
    "TOLUIDINBLAU",
    "TORASEMID",
    "TRANEXAMSAURE",
    "URAPIDIL",
    "VALPROAT",
    "VANCOMYCIN",
    "VERAPAMIL",
    "VITAMIN_B_1",
    "VITAMIN_B_12",
    "VITAMIN_B_6",
    "VITAMIN_K",
    "VOLLELEKTROLYTLOSUNG",
    "SAUERSTOFF_4",
    "SAUERSTOFF_10",
    "SAUERSTOFF_15",
];