// External imports
import React, { useState } from "react";
import { AiOutlineLineChart } from "react-icons/ai";

// Internal imports
import {Modal} from "../../../../../../components/core/modal/Modal";
import {
    OrderInvestigationModalContainer,
    OrderInvestigationModalContentContainer,
    OrderInvestigationModalForm,
    OrderInvestigationModalContentLabel,
    OrderInvestigationModalFormTitle,
    OrderInvestigationModalContentDescription,
    OrderInvestigationModalContentWrapper,
    OrderInvestigationModalFormFooter,
    OrderInvestigationModalFlexContainer,
} from "./OrderInvestigationReport.styles";
import { MdOutlineClose } from "react-icons/md";
import TurnTablet from "../../../../notePage/components/TurnTablet/TurnTablet";
import TextArea from "../../../../../../components/core/input-form/TextArea";
import WebsiteButton from "../../../../../../components/core/button/WebsiteButton";
import IconActionButton from "../../../../../../components/core/icon-action-button/IconActionButton";
import ShowInvestigationImageReport from "../showInvestigationImageReportModal/ShowInvestigationImageReport";
import {turnsToTime} from "../../../../../../core/LocalStorage/turnLogic/core";
import {formatGameTime} from "../../../../../../core/gameTime/formatTime";

// Translation
import { useLanguage } from "../../../../../../core/translations/LanguageContext";
import { enTranslations } from "./../../../translations/en";
import { deTranslations } from "./../../../translations/de";
import { InvestigationPageTranslations } from "./../../../translations/types";
import { SupportedLanguage } from "../../../../../../core/translations/supportedLanguages";
import { InvestigationTypesTranslations } from "../../../../../../core/translations/investigations/types";
import { enInvestigationTranslations } from "../../../../../../core/translations/investigations/en";
import { deInvestigationTranslations } from "../../../../../../core/translations/investigations/de";


// Set the translations for the page
const TRANSLATIONS: Record<SupportedLanguage, InvestigationPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

// set the translations for the investigation types
const INVESTIGATION_TRANSLATIONS: Record<SupportedLanguage, InvestigationTypesTranslations> = {
    en: enInvestigationTranslations,
    de: deInvestigationTranslations,
};



/**
 * OrderInvestigationReportProps
 * 
 * This interface is for the props of the OrderInvestigationReport component.
 */
interface OrderInvestigationReportProps {
    title: string;
    details: string;
    notes?: string;
    allowNotes?: boolean;
    turn_ordered: number;
    image?: string;
    imageComment?: string;
    imageOpen?: boolean;
    onImageClick?: () => void;
    onClose: () => void;
    reason?: string;
    onNoteSave?: any;
}

/**
* OrderInvestigationReportModal
* 
* This component is a modal for the investigation report. This is important because it allows
* the user to view a report or image, and add notes to the report. The notes end up in the 
* documentation. 
 */
export const OrderInvestigationReportModal = ({
    title,
    details,
    allowNotes,
    notes,
    turn_ordered,
    image,
    imageComment,
    onClose,
    reason,
                                                  onNoteSave
}: OrderInvestigationReportProps) => {
    const [note, setNote] = useState(notes ?? "");
    const [isReportPreviewOpen, setIsReportPreviewOpen] = useState(false);

    // Language translation
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    const investigationTranslations = INVESTIGATION_TRANSLATIONS[language];

    const handleSubmit = async () => {
        try {
            if (onNoteSave) {
                await onNoteSave(note);
                onClose();
            }
        } catch (err) {
            console.error("Failed to save note:", err);
        }
    };

    return (
        <>
            <Modal onRequestClose={onClose}>
                <OrderInvestigationModalContainer>
                    <MdOutlineClose onClick={onClose} />
                    <OrderInvestigationModalFormTitle>
                        {translations.investigationReport} {investigationTranslations[title]}<TurnTablet turn={formatGameTime(turnsToTime(turn_ordered))} />
                    </OrderInvestigationModalFormTitle>
                    <OrderInvestigationModalContentContainer>
                        <OrderInvestigationModalForm
                            onSubmit={(e: React.ChangeEvent<HTMLFormElement>) =>
                                e.preventDefault()
                            }
                        >
                            <OrderInvestigationModalContentWrapper>
                                <OrderInvestigationModalContentLabel>
                                    {translations.reasonForInvestigation}
                                </OrderInvestigationModalContentLabel>
                                <OrderInvestigationModalContentDescription>
                                    {reason || translations.noReason }
                                </OrderInvestigationModalContentDescription>
                            </OrderInvestigationModalContentWrapper>
                            <OrderInvestigationModalContentWrapper>
                                <OrderInvestigationModalFlexContainer>
                                    <OrderInvestigationModalContentLabel>
                                        {image ? translations.imageAvailable : translations.report}
                                    </OrderInvestigationModalContentLabel>
                                    {image && (
                                        <IconActionButton
                                            Icon={AiOutlineLineChart}
                                            tooltipText={translations.viewReport}
                                            onClick={() =>
                                                setIsReportPreviewOpen(true)
                                            }
                                            selected={isReportPreviewOpen}
                                        />
                                    )}
                                </OrderInvestigationModalFlexContainer>
                                <OrderInvestigationModalContentDescription>
                                    {image ? translations.clickButtonToViewImage : details}
                                </OrderInvestigationModalContentDescription>
                            </OrderInvestigationModalContentWrapper>

                            <OrderInvestigationModalContentWrapper>
                                <OrderInvestigationModalContentLabel>
                                    {translations.notesSection}:
                                </OrderInvestigationModalContentLabel>

                                {allowNotes ? (
                                    notes ? (
                                        <OrderInvestigationModalContentDescription>
                                            {notes}
                                        </OrderInvestigationModalContentDescription>
                                    ) : (
                                        <TextArea
                                            autoFocus
                                            rows={6}
                                            placeholder={translations.addAnyNotes}
                                            value={note}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLTextAreaElement>
                                            ) => setNote(e.target.value)}
                                        />
                                    )
                                ) : (
                                    <OrderInvestigationModalContentDescription>
                                        No notes available
                                    </OrderInvestigationModalContentDescription>
                                )}
                            </OrderInvestigationModalContentWrapper>
                            <OrderInvestigationModalFormFooter>
                                <WebsiteButton
                                    type="submit"
                                    tag="button"
                                    disabled={!note.trim()}
                                    onClick={handleSubmit}
                                >
                                    {translations.save}
                                </WebsiteButton>
                            </OrderInvestigationModalFormFooter>
                        </OrderInvestigationModalForm>
                    </OrderInvestigationModalContentContainer>
                </OrderInvestigationModalContainer>
            </Modal>
            {isReportPreviewOpen && image && (
                <ShowInvestigationImageReport
                    turn={turn_ordered}
                    investigateName={title}
                    url = {`https://faces-clinical-metrics20250207.lon1.digitaloceanspaces.com/${image}.png`}
                    secondUrl="annotated.png"
                    imageComment={imageComment}
                    onClose={() => setIsReportPreviewOpen(false)}
                    onSaveNote={onNoteSave}
                />
            )}
        </>
    );
}

export default OrderInvestigationReportModal;
