// External imports
import React, { useState } from 'react';

// Internal imports
import { Container, TurnsCount } from './DigialClock.styles';
import DigitalClockPopup from './DigitalClockPopup/DigitalClockPopup';
import { getTurnCount, turnsToTime } from '../../../../../core/LocalStorage/turnLogic/core';
import { formatGameTime } from '../../../../../core/gameTime/formatTime';


/**
 * DigitalNumber component
 *
 */
const DigitalNumber: React.FC = () => {
    const [clockOpen, setClockOpen] = useState(false);

    const turns = getTurnCount();
    const gameTime = turnsToTime(turns);

  return (
    <Container onMouseEnter={()=>{setClockOpen(true)}} onMouseLeave={()=>{setClockOpen(false)}}>
        <TurnsCount>{formatGameTime(gameTime)}</TurnsCount>
      {clockOpen && <DigitalClockPopup currentTurn={turns} />}
    </Container>
  );
};

export default DigitalNumber;
