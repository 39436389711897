import { PatientExamination } from "../types/PatientExamTypes";
import respiratoryExamImage from "../assets/respiratory-exam.png";
import abdominalExamImage from "../assets/abdominal.png";
import cardiologyExamImage from "../assets/cardiology-exam.png";
import generalAppearanceExamImage from "../assets/general-appearance.png";
import headAndNeckExamImage from "../assets/head-and-neck.png";
import lymphNodesExamImage from "../assets/lymph-nodes.png";
import neurologyExamImage from "../assets/neurological-exam.png";
import genitalExamImage from "../assets/genito-urinary-exam.png";
import armExamImage from "../assets/arm.png";
import legsExamImage from "../assets/legs.png";
import spineExamImage from "../assets/spine.png";

/**
 * This config file is used to define the generic contents of the examinations
 * including the image, name, id and number of turns. These are the same for
 * each patient but the findings will be updated for each patient.
 * See the type PatientExamination for information on how this gets updated.
 */
const config = {
    examinations: [
        {
            name: "Respiratory Exam",
            id: "RESPIRATORY",
            image: respiratoryExamImage,
            turns: 4,
        },
        {
            name: "Abdominal Exam",
            id: "ABDOMEN",
            image: abdominalExamImage,
            turns: 4,
        },
        {
            name: "Cardiology Exam",
            id: "CARDIOVASCULAR",
            image: cardiologyExamImage,
            turns: 4,
        },
        {
            name: "General Appearance",
            id: "GENERAL",
            image: generalAppearanceExamImage,
            turns: 2,
        },
        {
            name: "Head and Neck",
            id: "HEAD_AND_NECK",
            image: headAndNeckExamImage,
            turns: 2,
        },
        {
            name: "Lymph Nodes",
            id: "LYMPH_NODE",
            image: lymphNodesExamImage,
            turns: 2,
        },
        {
            name: "Neurological Exam",
            id: "NEUROLOGY",
            image: neurologyExamImage,
            turns: 5,
        },
        {
            name: "Genito-Urinary Exam",
            id: "GENITAL",
            image: genitalExamImage,
            turns: 4,
        },
        {
            name: "Arm Exam",
            id: "ARMS",
            image: armExamImage,
            turns: 3,
        },
        {
            name: "Legs Exam",
            id: "LEGS",
            image: legsExamImage,
            turns: 3,
        },
        {
            name: "Spine Exam",
            id: "SPINE",
            image: spineExamImage,
            turns: 3,
        },
    ] as PatientExamination[],
};

export default config;
