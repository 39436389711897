import { DiseaseTranslations } from "./types";

export const deDiseaseTranslations: DiseaseTranslations = {
    categories: {
        CARDIOVASCULAR: "Herz-Kreislauf",
        RESPIRATORY: "Atemwege",
        NEUROLOGICAL: "Neurologisch",
        ENDOCRINOLOGY: "Endokrinologie",
        UROLOGY: "Urologie",
        GENERAL_SURGERY: "Allgemeinchirurgie",
        GASTROENTEROLOGY: "Gastroenterologie",
        OBS_GYNAE: "Gynäkologie und Geburtshilfe",
        ORTHOPAEDICS: "Orthopädie",
        OTHER: "Sonstiges",
    },
    diseases: {
        STEMI_ANTERIOR: { name: "STEMI Anterior", description: "Herzinfarkt an der Vorderseite des Herzens" },
        STEMI_POSTERIOR: { name: "STEMI Posterior", description: "Herzinfarkt an der Rückseite des Herzens" },
        NSTEMI: { name: "NSTEMI", description: "Herzinfarkt ohne ST-Hebung" },
        ATRIAL_FIBRILLATION: { name: "Vorhofflimmern", description: "Unregelmäßiger Herzschlag" },
        CONGESTIVE_HEART_FAILURE: { name: "Herzinsuffizienz", description: "Unfähigkeit des Herzens, effektiv zu pumpen" },
        ORTHOSTATIC_SYNCOPE: { name: "Orthostatische Synkope", description: "Ohnmacht durch niedrigen Blutdruck" },
        HYPERTENSIVE_CRISIS: { name: "Hypertensive Krise", description: "Starker Anstieg des Blutdrucks" },
        AVNRT: { name: "AVNRT", description: "Atrioventrikuläre nodale Reentrytachykardie" },
        AORTIC_STENOSIS: { name: "Aortenstenose", description: "Verengung der Aortenklappe" },
        LEFT_SIDED_ENDOCARDITIS: { name: "Linksseitige Endokarditis", description: "Infektion der Herzinnenwand" },
        AORTIC_DISSECTION: { name: "Aortendissektion", description: "Riss in der inneren Schicht der Aorta" },
        AV_BLOCK_COMPLETE: { name: "Kompletter AV-Block", description: "Vollständiger Block der elektrischen Signale im Herzen" },
        HYPERTENSIVE_EMERGENCY: { name: "Hypertensiver Notfall", description: "Schwere Hypertonie mit Organschäden" },
        PERIPHERAL_ARTERY_DISEASE: { name: "Periphere Arterielle Verschlusskrankheit", description: "Verengte Arterien reduzieren den Blutfluss zu den Gliedmaßen" },

        NON_INFECTIVE_EXACERBATION_OF_ASTHMA: { name: "Nicht-infektiöse Asthma-Exazerbation", description: "Verschlechterung von Asthma ohne Infektion" },
        NON_INFECTIVE_EXACERBATION_OF_COPD: { name: "Nicht-infektiöse COPD-Exazerbation", description: "Verschlechterung von COPD ohne Infektion" },
        INFECTIVE_EXACERBATION_OF_COPD: { name: "Infektiöse COPD-Exazerbation", description: "Verschlechterung von COPD mit Infektion" },
        COMMUNITY_ACQUIRED_PNEUMONIA: { name: "Außerhalb des Krankenhauses erworbene Pneumonie", description: "Pneumonie, die außerhalb des Krankenhauses erworben wurde" },
        PULMONARY_EMBOLISM: { name: "Lungenembolie", description: "Blutgerinnsel in der Lunge" },
        CHEST_SEPSIS: { name: "Thoraxsepsis", description: "Infektion, die sich in der Brust ausbreitet" },

        HYPERGLYCAEMIA: { name: "Hyperglykämie", description: "Hoher Blutzucker" },
        HYPERKALEMIA: { name: "Hyperkaliämie", description: "Hohe Kaliumspiegel" },
        HYPERTHYROIDISM: { name: "Hyperthyreose", description: "Überfunktion der Schilddrüse" },
        HYPOGLYCAEMIA: { name: "Hypoglykämie", description: "Niedriger Blutzucker" },

        UROSEPSIS: { name: "Urosepsis", description: "Schwere Harnwegsinfektion" },
        ACUTE_URINARY_RETENTION: { name: "Akuter Harnverhalt", description: "Plötzliche Unfähigkeit, zu urinieren" },
        KIDNEY_STONES: { name: "Nierensteine", description: "Feste Massen in den Nieren" },

        ACUTE_APPENDICITIS: { name: "Akute Appendizitis", description: "Entzündung des Blinddarms" },
        ACUTE_CHOLECYSTITIS: { name: "Akute Cholezystitis", description: "Entzündung der Gallenblase" },

        ISCHAEMIC_STROKE: { name: "Ischämischer Schlaganfall", description: "Schlaganfall durch Blutgerinnsel" },
        MIGRAINE: { name: "Migräne", description: "Starke Kopfschmerzen" },
        SEIZURE: { name: "Anfall", description: "Plötzliche, unkontrollierte elektrische Störung im Gehirn" },
        BENIGN_PAROXYSMAL_POSITIONAL_VERTIGO: { name: "BPLS", description: "Schwindel durch Lageänderung des Kopfes" },
        SUBARACHNOID_HEMORRHAGE: { name: "Subarachnoidalblutung", description: "Blutung im Raum um das Gehirn" },
        SUBDURAL_HEMATOMA: { name: "Subdurales Hämatom", description: "Blutansammlung zwischen Gehirn und Schädel" },
        TRANSIENT_ISCHAEMIC_ATTACK: { name: "TIA", description: "Vorübergehende Blockade des Blutflusses zum Gehirn" },

        ACIDIC_REFLUX: { name: "Säurereflux", description: "Säure aus dem Magen fließt zurück in die Speiseröhre" },
        INFECTIVE_GASTROENTERITIS: { name: "Infektiöse Gastroenteritis", description: "Mageninfektion" },
        UPPER_GI_BLEED: { name: "Oberer GI-Blutung", description: "Blutung im oberen Magen-Darm-Trakt" },
        LOWER_GI_BLEEDING: { name: "Unterer GI-Blutung", description: "Blutung im unteren Magen-Darm-Trakt" },
        MESENTERIC_ISCHAEMIA: { name: "Mesenterische Ischämie", description: "Mangeldurchblutung des Darms" },
        SIGMOID_DIVERTICULITIS: { name: "Sigmoide Divertikulitis", description: "Entzündung der Divertikel im Darm" },

        ECTOPIC_PREGNANCY: { name: "Eileiterschwangerschaft", description: "Schwangerschaft außerhalb der Gebärmutter" },
        OVARIAN_TORSION: { name: "Ovarialtorsion", description: "Verdrehung des Eierstocks" },
        PREECLAMPSIA: { name: "Präeklampsie", description: "Hoher Blutdruck in der Schwangerschaft" },

        ANKLE_SPRAIN: { name: "Knöchelverstauchung", description: "Dehnung oder Riss der Bänder"},
        KNEE_OSTEOARTHRITIS: { name: "Kniegelenkarthrose", description: "Abnutzung des Knorpels im Kniegelenk" },
        HIP_FRACTURE: { name: "Hüftfraktur", description: "Bruch des Hüftknochens" },
        HIP_OSTEOARTHRITIS: { name: "Hüftgelenkarthrose", description: "Abnutzung des Knorpels im Hüftgelenk" },
        INFECTION_OF_PROSTHETIC_JOINT: { name: "Infektion des künstlichen Gelenks", description: "Infektion in einem künstlichen Gelenk" },

        MUSCULOSKELETAL_CHEST_PAIN: { name: "Muskuloskelettaler Brustschmerz", description: "Brustschmerz aufgrund von Muskel- oder Knochenproblemen" },
    }
};
