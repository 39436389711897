// External Imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Internal Imports
import {
    DepartmentalDetailsFlexContainer,
    DepartmentalDetailsTableContainer,
    DemographicsContainer,
    ReviewButton,
    DischargeButton,
    DischargeFormIconContainer,
} from "./dashBoardTable.styles";
import Table from "../../../../components/core/table/Table";
import { IPatientLocation } from "../../../../components/core/location-icon/types/IPatientLocation";
import { LocationIcon } from "../../../../components/core/location-icon/LocationIcon";
import GenderIcon from "../../../../components/core/gender-icon/GenderIcon";
import { FaCheckCircle } from "react-icons/fa";
import DisabledWithTooltip from "../../../../components/core/disabled-with-tooltip/DisabledWithTooltip";
import { Patient } from "../../../../core/LocalStorage/patients/interface";
import IntroductionModal from "../../../../components/core/modal/introduction-modal/IntroductionModal";

// Translation imports
import { GameBarLayoutTranslations } from "../../../../core/sharedDependencies/types";
import { formatGameTime } from "../../../../core/gameTime/formatTime";
import { turnsToTime } from "../../../../core/LocalStorage/turnLogic/core";

export interface DepartmentalDetailsProps {
    patients: Patient[];
    translations: GameBarLayoutTranslations;
    pageTranslations: any;
}

/**
 * DepartmentalDetails Component
 *
 * This component is used to display departmental table data, showing the patients
 * currently in the department, including those in the waiting room and those being seen by a doctor.
 */
export const DepartmentalDetails: React.FC<DepartmentalDetailsProps> = ({
    patients,
    translations,
    pageTranslations,
}) => {
    // Navigate to the patient review page
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState<boolean>(false);

    // Close the introduction modal
    const onCloseIntroModal = () => {
        localStorage.setItem("hasSeenIntroduction", "true");
        setOpenModal(false);
    };
    useEffect(() => {
        //@ts-ignore
        if (
            localStorage.getItem("hasSeenIntroduction") &&
            localStorage.getItem("hasSeenIntroduction") != ""
        ) {
            setOpenModal(false);
        } else {
            setOpenModal(true);
        }
    }, []);

    // The columns for the departmental details table
    // @ts-ignore
    const columns = [
        {
            key: "location",
            label: pageTranslations.table.columns.location,
            render: (patient: Patient) => {
                const locationType = patient.admin.location_type;

                const validLocation =
                    IPatientLocation[
                        locationType as keyof typeof IPatientLocation
                    ] || locationType;

                return (
                    <DepartmentalDetailsFlexContainer>
                        <LocationIcon
                            location={
                                translations.location[
                                    validLocation
                                ] as IPatientLocation
                            }
                            translations={translations}
                        />
                    </DepartmentalDetailsFlexContainer>
                );
            },
        },
        {
            key: "arrival",
            label: pageTranslations.table.columns.arrivalTime,
            render: (patient: Patient) => (
                <span>
                    {formatGameTime(turnsToTime(patient.admin.arrival_turn))}
                </span>
            ),
        },
        {
            key: "name",
            label: pageTranslations.table.columns.name,
            render: (patient: Patient) => (
                <span>{patient.demographic.full_name}</span>
            ),
        },
        {
            key: "demographics",
            label: pageTranslations.table.columns.ageGender,
            render: (patient: Patient) => {
                const { age_gender } = patient.demographic;
                const [age, gender] = age_gender.split(" ");
                return (
                    <DemographicsContainer>
                        {age}{" "}
                        <GenderIcon
                            gender={gender === "Male" ? "male" : "female"}
                        />
                    </DemographicsContainer>
                );
            },
        },
        {
            key: "symptom",
            label: pageTranslations.table.columns.symptom,
            render: (patient: Patient) => (
                <span>
                    {
                        translations.symptoms[
                            patient.symptoms.presenting_complaint
                        ]
                    }
                </span>
            ),
        },
        {
            key: "diagnosis",
            label: pageTranslations.table.columns.diagnosis,
            render: (patient: Patient) => (
                <DepartmentalDetailsFlexContainer id="DiagnosisBox">
                    {patient.diagnosis
                        ? translations.diagnosis[patient.diagnosis]
                        : null}
                </DepartmentalDetailsFlexContainer>
            ),
        },
        {
            key: "review",
            render: (patient: Patient) => (
                <ReviewButton
                    id="ReviewButton"
                    onClick={() =>
                        navigate(`/patients/${patient.admin.id}/review`)
                    } // Use patient.admin.id
                >
                    {pageTranslations.table.review}
                </ReviewButton>
            ),
        },
    ];

    return (
        <div>
            {openModal && (
                <IntroductionModal onRequestClose={() => onCloseIntroModal()} />
            )}
            <DepartmentalDetailsTableContainer id="DashboardContainer">
                <Table data={patients} columns={columns as any} />
            </DepartmentalDetailsTableContainer>
        </div>
    );
};
