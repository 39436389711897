import React from "react";
import {
    ClockText,
    PopupContainer,
    PopupContent,
    TransparentContainer,
    ProgressBarWrapper,
    ProgressBar,
    StartNumber,
    EndNumber
} from "./DigitalClockPopup.styles";
import { turnsToTime } from "../../../../../../core/LocalStorage/turnLogic/core";
import { formatGameTime } from "../../../../../../core/gameTime/formatTime";

interface DigitalClockPopupProps {
    currentTurn: number; // Current turn (1 turn = 1 minute)
}

const DigitalClockPopup: React.FC<DigitalClockPopupProps> = ({ currentTurn }) => {
    const totalGameMinutes = 8 * 60; // 8-hour game in minutes

    // Convert turns to formatted time
    const startTime = formatGameTime(turnsToTime(0)); // Start at 09:00
    const endTime = formatGameTime(turnsToTime(totalGameMinutes)); // End at 17:00
    const currentTime = formatGameTime(turnsToTime(currentTurn));

    return (
        <TransparentContainer>
            <PopupContainer>
                <PopupContent>
                    <ClockText>Progress through Shift</ClockText>
                    <ProgressBarWrapper>
                        <StartNumber>{startTime}</StartNumber>
                        <ProgressBar value={currentTurn} max={totalGameMinutes} />
                        <EndNumber>{endTime}</EndNumber>
                    </ProgressBarWrapper>
                    <ClockText>{currentTime}</ClockText>
                </PopupContent>
            </PopupContainer>
        </TransparentContainer>
    );
};

export default DigitalClockPopup;
