// Defines the student routes
import React from "react";

// Internal imports page components
import { FrontPage } from "./frontPage/FrontPage";
import { StartGamePage } from "./startGame/StartGamePage";
import { JoinClassPage } from "./joinClassPage/JoinClassPage";
import { DashBoardPage } from "./dashBoardPage/dashBoardPage";
import { DischargeBoardPage } from "./dischargeBoardPage/dischargeBoardPage";
import { PatientObservations } from "./observationPage/PatientObservationsPage";
import { ExamPage } from "./examPage/ExamPage";
import { OrderInvestigationPage } from "./investigationPage/OrderInvestigation";
import { OrderTreatmentPage } from "./treatmentOrderPage/OrderTreatment";
import { BloodOrderScreenPage } from "./bloodOrderPage/BloodOrderScreen";
import { BloodTestResultPage } from "./bloodResultsPage/BloodTestTable";
import { DrugChart } from "./drugChartPage/DrugChart";
import { NotesPage } from "./notePage/Notes";
import { DischargeScreenPage } from "./dischargeFormPage/DischargeForm";
import { RequestConsultationPage } from "./requestConsultation/RequestConsultation";
import { OrderAdditionalTestsPage } from "./additionalTestsPage/OrderAdditionalTests";
import { ChatScreen } from "./chatScreenPage/ChatScreen";
import RewardPage from "./reward/RewardPage";

// Common dependencies for many pages
import {
    getTurnCount,
    updateTurnCount,
} from "../../core/LocalStorage/turnLogic/core";
import { getJwt } from "../../core/LocalStorage/AuthSession/core";
import { getDemographicsFromLocalStorage } from "../../core/LocalStorage/patients/demographics";
import { SupportedLanguage } from "../../core/translations/supportedLanguages";
import { useLanguage } from "../../core/translations/LanguageContext";

// dependencies for the new-game page
import { startSession } from "../../core/ServerApiLayer/VOne/sessions/start";

// dependencies for the join-class page
import { assignClass } from "../../core/ServerApiLayer/VOne/classes/assign";
import { setFromApiData } from "../../core/LocalStorage/classSession/core";

// dependencies for the start-game page
import { setFromApiData as setFromSessionApiData } from "../../core/LocalStorage/gameSession/core";

// dependencies for the dashboard page
import { getAllPatients } from "../../core/ServerApiLayer/VOne/patients/getAll";
import { getPatientDataByStatus } from "../../core/LocalStorage/patients/core";

// dependencies for the patient observation page ("review page")
import { getAllObservations } from "../../core/ServerApiLayer/VOne/patients/getObservations";
import { postObservations } from "../../core/ServerApiLayer/VOne/patients/postObservations";
import {
    setObservationData,
    getObservationData,
} from "../../core/LocalStorage/patients/observations";
import { getHandoverFromLocalStorage } from "../../core/LocalStorage/patients/handover";

// dependencies for the chat page
import { getPromptDataFromLocalStorage } from "../../core/LocalStorage/patients/prompt";

// dependencies for the exam page
import { postExam } from "../../core/ServerApiLayer/VOne/patients/postExam";
import {
    getExamDataFromLocalStorage,
    setToLocalStorage,
} from "../../core/LocalStorage/patients/examination";

// dependencies for the order investigation page
import { postInvestigation } from "../../core/ServerApiLayer/VOne/patients/postInvestigate";
import {
    setToInvestigationLocalStorage,
    getInvestigationDataFromLocalStorage as GetInvestigationDataFromLocalStorage,
    filterInvestigationData,
} from "../../core/LocalStorage/patients/investigations";
import { updateInvestigationDocumentationToLocalStorage } from "../../core/LocalStorage/patients/notes";
import { postNote } from "../../core/ServerApiLayer/VOne/patients/postNote";
import { updateNotesDocumentationToLocalStorage } from "../../core/LocalStorage/patients/notes";

// dependencies for the order treatment page
import { postTreatment } from "../../core/ServerApiLayer/VOne/patients/postTreatment";
import {
    setTreatmentToLocalStorage,
    getTreatmentDataFromLocalStorage,
    listOfTreatmentDataFromLocalStorage,
} from "../../core/LocalStorage/patients/treatments";
import { updateTreatmentsDocumentationToLocalStorage } from "../../core/LocalStorage/patients/notes";

// dependencies for the blood order page
import { postBloods } from "../../core/ServerApiLayer/VOne/patients/postBloods";
import { setBloodsToLocalStorage } from "../../core/LocalStorage/patients/bloods";
import {
    SetDocumentationToLocalStorageType,
    updateBloodDocumentationToLocalStorage,
} from "../../core/LocalStorage/patients/notes";

// dependencies for the blood results page
import { getAllBloods } from "../../core/ServerApiLayer/VOne/patients/getAllBloods";
import { getBloodsFromLocalStorage } from "../../core/LocalStorage/patients/bloods";

// dependencies for the drug chart page
import {
    getDrugsFromLocalStorage,
    setAllergiesToLocalStorage,
    setDrugsToLocalStorage,
    getAllergiesFromLocalStorage,
} from "../../core/LocalStorage/patients/drugs";
import { postDrug } from "../../core/ServerApiLayer/VOne/patients/postDrug";
import { getAllDrugs } from "../../core/ServerApiLayer/VOne/patients/getAllDrugs";
import { updateDrugsDocumentationToLocalStorage } from "../../core/LocalStorage/patients/notes";

// dependencies for the notes page
import {
    getAllDocumentationFromLocalStorage,
    setDocumentationToLocalStorage,
} from "../../core/LocalStorage/patients/notes";
import { getAllNotes } from "../../core/ServerApiLayer/VOne/patients/getAllNotes";

// dependencies for the forms page
import { postForm } from "../../core/ServerApiLayer/VOne/patients/postForm";
import {
    setFormsToLocalStorage,
    getLatestFormDataFromLocalStorage,
} from "../../core/LocalStorage/patients/forms";
import { updateFormDocumentationToLocalStorage } from "../../core/LocalStorage/patients/notes";
import { getDiagnosis } from "../../core/LocalStorage/patients/demographics";
import { updateDiagnosis } from "../../core/LocalStorage/patients/demographics";
import { setScoreData } from "../../core/LocalStorage/patients/scoring";

// dependencies for the request additional tests page
import {
    setAdditionalTestsToLocalStorage,
    filterAdditionalTestDataFromStorage,
    getAdditionalTestDataFromLocalStorage,
} from "../../core/LocalStorage/patients/additionalTests";
import { updateAdditionalTestsDocumentationToLocalStorage } from "../../core/LocalStorage/patients/notes";
import { postAdditionalTests } from "../../core/ServerApiLayer/VOne/patients/postAdditionalTests";
import { getAllAdditionalTests } from "../../core/ServerApiLayer/VOne/patients/getAllAdditionalTests";

// dependencies for the request consultation page
import {
    setConsultationsToLocalStorage,
    getConsultationDataFromLocalStorage,
    filterConsultationData,
} from "../../core/LocalStorage/patients/consultations";
import { updateConsultationDocumentationToLocalStorage } from "../../core/LocalStorage/patients/notes";
import { postConsultation } from "../../core/ServerApiLayer/VOne/patients/postConsultation";
import { getAllConsultations } from "../../core/ServerApiLayer/VOne/patients/getAllConsultations";

// Define the student routes #TODO: We might want to split this into parts for better readability in the future.
export const StudentRoutes = [
    {
        path: "/start",
        element: <FrontPage />,
    },
    {
        path: "/student/new-game",
        element: (
            <StartGamePage
                startSessionApiCall={startSession}
                setGameSessionData={setFromSessionApiData}
            />
        ),
    },
    {
        path: "/student/join-class",
        element: (
            <JoinClassPage
                joinClassApiCall={assignClass}
                setSessionData={setFromApiData}
            />
        ),
    },
    {
        path: "/dashboard",
        element: (
            <DashBoardPage
                getAllPatientApiCall={getAllPatients}
                getPatientDataStorage={getPatientDataByStatus}
            />
        ),
    },
    {
        path: "discharged",
        element: (
            <DischargeBoardPage
                getAllPatientApiCall={getAllPatients}
                getPatientDataStorage={getPatientDataByStatus}
            />
        ),
    },
    {
        path: "patients/:id/review",
        element: (
            <PatientObservations
                getObservationsApiCall={getAllObservations}
                postObservationsApiCall={postObservations}
                setObservationsLocalStorage={setObservationData}
                getObservationsLocalStorage={getObservationData}
                getHandoverFromLocalStorage={getHandoverFromLocalStorage}
            />
        ),
    },
    {
        path: "patients/:id/exam",
        element: (
            <ExamPage
                postExamApiCall={postExam}
                setExamLocalStorage={setToLocalStorage}
                getExamLocalStorage={getExamDataFromLocalStorage}
            />
        ),
    },
    {
        path: "patients/:id/investigate",
        element: (
            <OrderInvestigationPage
                postInvestigationApiCall={postInvestigation}
                setInvestigationLocalStorage={setToInvestigationLocalStorage}
                getInvestigationLocalStorage={
                    GetInvestigationDataFromLocalStorage
                }
                filterInvestigationLocalStorage={filterInvestigationData}
                updateInvestigationDocumentationToLocalStorage={
                    updateInvestigationDocumentationToLocalStorage
                }
                postNoteApiCall={postNote}
                postNoteLocalStorage={updateNotesDocumentationToLocalStorage}
            />
        ),
    },
    {
        path: "patients/:id/treatments",
        element: (
            <OrderTreatmentPage
                postTreatmentApiCall={postTreatment}
                setTreatmentLocalStorage={setTreatmentToLocalStorage}
                getTreatmentLocalStorage={getTreatmentDataFromLocalStorage}
                listOfTreatmentDataFromLocalStorage={
                    listOfTreatmentDataFromLocalStorage
                }
                updateTreatmentDocumentationToLocalStorage={
                    updateTreatmentsDocumentationToLocalStorage
                }
            />
        ),
    },
    {
        path: "patients/:id/bloods/order",
        element: (
            <BloodOrderScreenPage
                postBloodsApiCall={postBloods}
                setBloodsToLocalStorage={setBloodsToLocalStorage}
                updateBloodDocumentationToLocalStorage={
                    updateBloodDocumentationToLocalStorage
                }
            />
        ),
    },
    {
        path: "patients/:id/bloods/results",
        element: (
            <BloodTestResultPage
                getBloodsApiCall={getAllBloods}
                getBloodsFromLocalStorage={getBloodsFromLocalStorage}
                setBloodsFromLocalStorage={setBloodsToLocalStorage}
            />
        ),
    },
    {
        path: "patients/:id/drugchart",
        element: (
            <DrugChart
                postDrugApiCall={postDrug}
                getDrugsApiCall={getAllDrugs}
                getDrugsFromLocalStorage={getDrugsFromLocalStorage}
                setDrugsToLocalStorage={setDrugsToLocalStorage}
                setAllergiesToLocalStorage={setAllergiesToLocalStorage}
                getAllergiesFromLocalStorage={getAllergiesFromLocalStorage}
                updateDrugsDocumentationToLocalStorage={
                    updateDrugsDocumentationToLocalStorage
                }
            />
        ),
    },
    {
        path: "patients/:id/forms",
        element: (
            <DischargeScreenPage
                getPatientData={getDemographicsFromLocalStorage}
                postFormApiCall={postForm}
                setFormsDataToLocalStorage={setFormsToLocalStorage}
                getLatestFormFromLocalStorage={
                    getLatestFormDataFromLocalStorage
                }
                updateNotesDataToLocalStorage={
                    updateFormDocumentationToLocalStorage
                }
                getDiagnosisFromLocalStorage={getDiagnosis}
                updateDiagnosisToLocalStorage={updateDiagnosis}
                setScoreData={setScoreData}
            />
        ),
    },
    {
        path: "patients/:id/notes",
        element: (
            <NotesPage
                getAllNotesApiCall={getAllNotes}
                getNotesLocalStorage={getAllDocumentationFromLocalStorage}
                setNotesLocalStorage={setDocumentationToLocalStorage}
                isLayout={true}
            />
        ),
    },
    {
        path: "patients/:id/specialty",
        element: (
            <RequestConsultationPage
                setConsultationsToLocalStorage={setConsultationsToLocalStorage}
                getConsultationDataFromLocalStorage={
                    getConsultationDataFromLocalStorage
                }
                filterConsultations={filterConsultationData}
                updateConsultationDocumentationToLocalStorage={
                    updateConsultationDocumentationToLocalStorage
                }
                postConsultationApiCall={postConsultation}
                getAllConsultationsApiCall={getAllConsultations}
            />
        ),
    },
    {
        path: "patients/:id/hcp",
        element: (
            <OrderAdditionalTestsPage
                setAdditionalTestsToLocalStorage={
                    setAdditionalTestsToLocalStorage
                }
                getAdditionalTestDataFromLocalStorage={
                    getAdditionalTestDataFromLocalStorage
                }
                filterAdditionalTestsFromStorage={
                    filterAdditionalTestDataFromStorage
                }
                updateAdditionalTestsDocumentationToLocalStorage={
                    updateAdditionalTestsDocumentationToLocalStorage
                }
                postAdditionalTestsApiCall={postAdditionalTests}
                getAllAdditionalTestsApiCall={getAllAdditionalTests}
            />
        ),
    },
    {
        path: "patients/:id/chat",
        element: (
            <ChatScreen
                getPatientData={getDemographicsFromLocalStorage}
                getPromptFromLocalStorage={getPromptDataFromLocalStorage}
            />
        ),
    },
    {
        path: "/badges",
        element: <RewardPage />,
    },
    {
        path: "*",
        element: <h1>404</h1>,
    },
];
