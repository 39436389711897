import { ExitChatModalTranslation } from './types';

export const deTranslations: ExitChatModalTranslation = {
  placeholderOne: 'Besten Rat zuerst',  // "Best Guess First"
  placeholderTwo: 'Besten Rat zwei',  // "Best Guess Two"
  placeholderThree: 'Besten Rat drei',  // "Best Guess Three"
  modalTitle: 'Bester Diagnosetipp',  // "Best Guess Diagnosis"
  saveButton: 'Speichern',  // "Save",
  noteText: "Nach der Anamnese werden folgende Differentialdiagnosen in Betracht gezogen:"
};
