// External imports
import React, { useState, FormEvent, useEffect } from "react";
import { FaAmbulance, FaUserNurse } from "react-icons/fa";

// Internal imports
import GlobalSmallPopup from "../GlobalSmallPopup";
import {
    Form,
    FormH3,
    FormH4,
    FormInputFieldArea,
    FormFieldSection,
    FormLabel,
    FormSubmitButton,
    ProfessionalName,
    ErrorMessage,
} from "./HandoverModal.styles";
import SelectBox, { SelectOption } from "../../select-box/SelectBox";
import { handoverData } from "../../../../core/LocalStorage/patients/interface";

// Translation imports
import { HandoverModalTranslations } from "./translations/types";

/*
The props for the HandoverModal component.
@onSubmit: A function to handle the form submission (passed in from the parent node)
@initialFormData: The initial state of the form (passed in from the parent node)
 */
export interface PopupFormProps {
    onRequestClose: () => void;
    handover: handoverData | null;
    pageTranslations: HandoverModalTranslations;
    translations: any;
    demographicsDependencies: any;
    patientId: number;
}

/*
The HandoverModal component.
@onSubmit: A function to handle the form submission (passed in from the parent node)
@initialFormData: The initial state of the form (passed in from the parent node)
 */
export const HandoverModal: React.FC<PopupFormProps> = ({
                                                            onRequestClose,
                                                            handover,
                                                            translations,
                                                            pageTranslations,
                                                            demographicsDependencies,
                                                            patientId,
                                                        }) => {
    const [selectedDestination, setSelectedDestination] =
        useState<SelectOption>({
            label: "Select a destination",
            value: "",
        });
    const [error, setError] = useState<string | null>(null); // Error state

    const isSubmitEnabled = selectedDestination.value !== "";

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formData = {
            destination: selectedDestination.value,
        };

        try {
            await demographicsDependencies.updateLocation(patientId, formData.destination);
            onRequestClose(); // Close modal on success
        } catch (err) {
            setError(translations.gameBar.movePatientModal.illegalMove); // Set error message
        }
    };

    const moveOptions: SelectOption[] = [
        { label: translations.location.Resus, value: "Resus" },
        { label: translations.location.Majors, value: "Majors" },
    ];

    return (
        <GlobalSmallPopup onRequestClose={onRequestClose}>
            <Form onSubmit={handleSubmit}>
                <FormInputFieldArea>
                    <FormH4>{translations.title}</FormH4>

                    <FormH3>
                        {handover ? (
                            <>
                                {handover.professional === "AMBULANCE" && (
                                    <>
                                        <FaAmbulance />
                                        <ProfessionalName>Ambulance</ProfessionalName>
                                    </>
                                )}
                                {handover.professional === "TRIAGE_NURSE" && (
                                    <>
                                        <FaUserNurse />
                                        <ProfessionalName>Triage Nurse</ProfessionalName>
                                    </>
                                )}
                                {handover.handover}
                            </>
                        ) : (
                            <>{translations.noHandoverAvailable}</>
                        )}
                    </FormH3>

                    <FormFieldSection>
                        <FormLabel>{translations.pickDestination}</FormLabel>
                        <SelectBox
                            options={moveOptions}
                            value={selectedDestination}
                            onChange={setSelectedDestination}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>} {/* Display error */}
                    </FormFieldSection>
                    <FormSubmitButton type="submit" disabled={!isSubmitEnabled}>
                        {pageTranslations.moveText}
                    </FormSubmitButton>
                </FormInputFieldArea>
            </Form>
        </GlobalSmallPopup>
    );
};
