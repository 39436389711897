// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";
import { drugChartData } from "../../../LocalStorage/patients/interface";


// The payload for posting a drug to the drug chart
export interface PostDrugPayload {
    patient_id: number;
    turn: number;
    drug_key: string;
    drug_name: string;
    dose: string;
    route: string;
}



// The interface for the response from the post treatment endpoint
export interface PostDrugResponse {
    status: number;
    error?: string;
    data?: {
        drug_chart: drugChartData;
    };
}

// The type for the postDrug function
export type PostDrugType = (jwt: string, postDrugPayload: PostDrugPayload) => Promise<PostDrugResponse>;

/*
The function to post a drug for a patient.

@jwt: The JWT of the user requesting the post.
@postTreatmentPayload: The payload containing the patient ID, turn, drug name, dose, and route.

@returns: The ApiResponse from the post treatment endpoint.
 */
export async function postDrug(jwt: string, postTreatmentPayload: PostDrugPayload): Promise<PostDrugResponse> {

    console.log("postDrug payload: ", postTreatmentPayload);
    const url = new PatientUrl().postDrug;
    let response: AxiosResponse<any>;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }
    try {
        response = await axios.post<PostDrugResponse | string>(url, {
            patient_id: postTreatmentPayload.patient_id,
            turn: postTreatmentPayload.turn,
            drug_key: postTreatmentPayload.drug_key,
            drug_name: postTreatmentPayload.drug_name,
            dose: postTreatmentPayload.dose,
            route: postTreatmentPayload.route
        }, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            validateStatus: () => true
        });
    } catch (error) {
        return {
            status: 500,
            error: 'Network or other error occurred'
        };
    }

    if (response.status === 201) {
        return {
            status: response.status,
            data: response.data as { drug_chart: drugChartData }
        };
    } else {
        return {
            status: response.status,
            error: response.data as string
        };
    }
}
