// External imports
import React from 'react';
import { FaCheck } from 'react-icons/fa';

// Internal imports
import {
    ReviewPageTableHead,
    ReviewPageTableRow,
    ReviewPageTableData,
    ReviewPageTableRowHead,
    ReviewPageTable,
    ReviewPageTableHeadWithIcon,
    ReviewPageTableHeadWithIconItem,
    ReviewPageTableBody
} from "./TableComponent.styles";
import { ReviewPageLeftTableRowProps } from '../../ReviewTablesStaticData';

// Language Context
import { useLanguage} from "../../../../../core/translations/LanguageContext";

// Translations
import { LeftSectionTableTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../../../core/translations/supportedLanguages";


// Translations const
const TRANSLATIONS: Record<SupportedLanguage, LeftSectionTableTranslations> = {
    en: enTranslations,
    de: deTranslations
};


// Props interface
export interface TableComponentProps {
    tableData: Array<ReviewPageLeftTableRowProps>,
    setSelectedRow: (data: ReviewPageLeftTableRowProps) => void;
    translations: any;
}

/**
 * Table component for the left section of the review page
 */
const ReviewPageLeftTableComponent: React.FC<TableComponentProps> = ({
    tableData,
    setSelectedRow,
    translations

}) => {

    console.log("dIsease translations", translations.diseases);

    const { language } = useLanguage();
    const pageTranslations = TRANSLATIONS[language];

    return (
        <ReviewPageTable>
            <ReviewPageTableHead>
                <ReviewPageTableRow>
                    <ReviewPageTableRowHead>{pageTranslations.disease}</ReviewPageTableRowHead>
                    <ReviewPageTableRowHead>{pageTranslations.students}</ReviewPageTableRowHead>
                    <ReviewPageTableRowHead>{pageTranslations.patients}</ReviewPageTableRowHead>
                    <ReviewPageTableRowHead>
                        <ReviewPageTableHeadWithIcon>
                            <FaCheck size={16} />
                            <ReviewPageTableHeadWithIconItem>
                                {pageTranslations.diagnosis} %
                            </ReviewPageTableHeadWithIconItem>
                        </ReviewPageTableHeadWithIcon>
                    </ReviewPageTableRowHead>
                    <ReviewPageTableRowHead>{pageTranslations.avg_score}</ReviewPageTableRowHead>
                </ReviewPageTableRow>
            </ReviewPageTableHead>
            <ReviewPageTableBody>
                {tableData.map((data, index) => (
                    <ReviewPageTableRow key={index} onClick={() => setSelectedRow(data)}>
                        <ReviewPageTableData>{translations.diseases[data.disease]?.name}</ReviewPageTableData>
                        <ReviewPageTableData>{data.students}</ReviewPageTableData>
                        <ReviewPageTableData>{data.patients}</ReviewPageTableData>
                        <ReviewPageTableData>{data.diagnosis}</ReviewPageTableData>
                        <ReviewPageTableData>{data.avg_score}</ReviewPageTableData>
                    </ReviewPageTableRow>
                ))}
                {tableData.length == 0 &&
                    <ReviewPageTableRow>
                        <ReviewPageTableData colSpan={5} style={{ textAlign: "center" }}>{translations.noRecordAvailable}</ReviewPageTableData>
                    </ReviewPageTableRow>
                }
            </ReviewPageTableBody>
        </ReviewPageTable>
    );
}

export default ReviewPageLeftTableComponent;
