// External imports
import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";

// Internal imports
import {
    ScoringContainer,
    LeftPanel,
    RightPanel,
    ScoringControl,
    ScoringLabel,
    ScoringInput,
    SwitchContainer,
    SwitchInput,
    Slider
} from "./ClassScoringComponent.styles";

// Translation imports
import { useLanguage } from '../../../../../core/translations/LanguageContext';
import { ScoringTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../../../core/translations/supportedLanguages';

// Dependency injection imports
import { ScoreController } from "../../../../../core/metaDataAccess/classes/scoring/ScoreController";
import { NewClassDataStore } from "../../../../../core/metaDataAccess/classes/store";
import { useForceUpdate } from "../../../../../core/metaDataAccess/utils";

// Constants
const TRANSLATIONS: Record<SupportedLanguage, ScoringTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

const CHART_COLORS = ["#f67171", "#57aaea"];
const CHART_DIMENSIONS = {
    width: 300,
    height: 300,
    thickness: 100
};

interface SwitchProps {
    value: string;
    isChecked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    id: string;
    ariaLabel: string;
}

/**
 * Switch component for toggling score locks
 */
const Switch: React.FC<SwitchProps> = ({
                                           value,
                                           isChecked,
                                           onChange,
                                           id,
                                           ariaLabel
                                       }) => (
    <SwitchContainer>
        <SwitchInput
            type="checkbox"
            checked={isChecked}
            onChange={onChange}
            value={value}
            id={id}
            aria-label={ariaLabel}
        />
        <Slider checked={isChecked} />
    </SwitchContainer>
);

/**
 * ClassScoringComponent
 * Handles scoring calculations and visualization for class scoring
 */
export const ClassScoringComponent: React.FC = () => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];
    const d3Container = useRef<SVGSVGElement>(null);
    const forceUpdate = useForceUpdate();

    const [scores, setScores] = useState<ScoreController>(
        new ScoreController(
            NewClassDataStore.getDiagnosisScore(),
            NewClassDataStore.getProtocolScore(),
            NewClassDataStore.diagnosisLocked,
            NewClassDataStore.protocolLocked,
        )
    );

    const [protocol, setProtocol] = useState(scores.protocolScore.score);
    const [diagnosis, setDiagnosis] = useState(scores.diagnosisScore.score);

    useEffect(() => {
        if (!d3Container.current) return;

        const data = [
            { label: translations.labels.protocol, value: protocol },
            { label: translations.labels.diagnosis, value: diagnosis },
        ];

    }, [protocol, diagnosis, language]);

    const handleLockChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const scoreType = event.target.value;
        switch (scoreType) {
            case "protocol":
                scores.protocolScore.checkLock();
                NewClassDataStore.protocolLocked = scores.protocolScore.locked;
                break;
            case "diagnosis":
                scores.diagnosisScore.checkLock();
                NewClassDataStore.diagnosisLocked = scores.diagnosisScore.locked;
                break;
        }
        forceUpdate();
    };

    const handleScoreChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newValue = Number(event.target.value);
        const scoreType = event.target.name;

        switch (scoreType) {
            case "protocol":
                scores.changeProtocolScore(newValue);
                setProtocol(newValue);
                break;
            case "diagnosis":
                scores.changeDiagnosisScore(newValue);
                setDiagnosis(newValue);
                break;
        }

        NewClassDataStore.setProtocolScore(scores.protocolScore.score);
        NewClassDataStore.setDiagnosisScore(scores.diagnosisScore.score);
    };

    /**
     * Creates and updates the D3 donut chart
     */
    useEffect(() => {
        if (!d3Container.current) return;

        // Clear any existing SVG content
        d3.select(d3Container.current).selectAll("*").remove();

        const data = [
            { label: translations.labels.protocol, value: protocol },
            { label: translations.labels.diagnosis, value: diagnosis },
        ];

        const { width, height, thickness } = CHART_DIMENSIONS;
        const radius = Math.min(width, height) / 2;

        // Create the SVG container
        const svg = d3
            .select(d3Container.current)
            .attr("width", width)
            .attr("height", height);

        // Create the donut chart group and move it to the center
        const g = svg
            .append("g")
            .attr("transform", `translate(${width / 2}, ${height / 2})`);

        // Create the pie layout
        const pie = d3
            .pie<{ label: string; value: number }>()
            .value(d => d.value)
            .sort(null);

        // Create the arc generator
        const arc = d3
            .arc<d3.PieArcDatum<{ label: string; value: number }>>()
            .innerRadius(radius - thickness)
            .outerRadius(radius);

        // Create the arcs
        const arcs = g
            .selectAll("path")
            .data(pie(data))
            .enter()
            .append("g")
            .attr("class", "arc");

        // Add paths (sections of the donut)
        arcs
            .append("path")
            .attr("d", arc)
            .attr("fill", (_, i) => CHART_COLORS[i])
            .attr("stroke", "white")
            .style("stroke-width", "2px");

        // Add labels
        const labelArc = d3
            .arc<d3.PieArcDatum<{ label: string; value: number }>>()
            .innerRadius(radius - thickness / 2)
            .outerRadius(radius - thickness / 2);

        arcs
            .append("text")
            .attr("transform", d => `translate(${labelArc.centroid(d)})`)
            .attr("dy", "0.35em")
            .attr("text-anchor", "middle")
            .attr("fill", "black")
            .attr("font-size", "14px")
            .attr("font-weight", "bold")
            .each(function(d) {
                const lines = [
                    d.data.label,
                    `${Math.round(d.data.value)}%`
                ];
                d3.select(this)
                    .selectAll("tspan")
                    .data(lines)
                    .enter()
                    .append("tspan")
                    .attr("x", 0)
                    .attr("dy", (_, i) => i === 0 ? "-0.6em" : "1.2em")
                    .text(l => l);
            });

        // Add tooltips
        arcs
            .append("title")
            .text(d => `${d.data.label}: ${Math.round(d.data.value)}%`);

        // Add hover effects
        arcs
            .selectAll("path")
            .on("mouseover", function() {
                d3.select(this)
                    .transition()
                    .duration(200)
                    .style("opacity", 0.8);
            })
            .on("mouseout", function() {
                d3.select(this)
                    .transition()
                    .duration(200)
                    .style("opacity", 1);
            });

    }, [protocol, diagnosis, translations]);

    return (
        <ScoringContainer>
            <LeftPanel>
                <ScoringControl>
                    <Switch
                        value="protocol"
                        isChecked={scores.protocolScore.locked}
                        onChange={handleLockChange}
                        id="protocol-lock-switch"
                        ariaLabel={translations.ariaLabels.protocolLock}
                    />
                    <ScoringLabel>{translations.labels.protocol}</ScoringLabel>
                    <ScoringInput
                        type="number"
                        id="protocol"
                        name="protocol"
                        value={scores.protocolScore.score}
                        onChange={handleScoreChange}
                        aria-label={translations.ariaLabels.protocolScore}
                    />
                </ScoringControl>
                <ScoringControl>
                    <Switch
                        value="diagnosis"
                        isChecked={scores.diagnosisScore.locked}
                        onChange={handleLockChange}
                        id="diagnosis-lock-switch"
                        ariaLabel={translations.ariaLabels.diagnosisLock}
                    />
                    <ScoringLabel>{translations.labels.diagnosis}</ScoringLabel>
                    <ScoringInput
                        type="number"
                        id="diagnosis"
                        name="diagnosis"
                        value={scores.diagnosisScore.score}
                        onChange={handleScoreChange}
                        aria-label={translations.ariaLabels.diagnosisScore}
                    />
                </ScoringControl>
            </LeftPanel>
            <RightPanel>
                <svg ref={d3Container} />
            </RightPanel>
        </ScoringContainer>
    );
};