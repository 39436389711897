// External Imports
import React, { useState, useEffect } from "react";

// Internal Imports
import { DrugChartComponent, FormData } from "../FormComponent/FormComponent";
// @ts-ignore
//import styles from "./DrugChartLayout.module.css";
import GlobalSmallPopup from "../../../../components/core/modal/GlobalSmallPopup";
import SelectedDrugsListing from "../SelectedDrugsListing/SelectedDrugsListing";
import { FormSection, PageMain } from "./DrugChartLayout.styles";

// The data for one drug in the table
export interface TableDataProps {
    drugName: string;
    turn: number;
    drug: string;
    route: string;
    dose: string;
}

/**
 * The props for the DrugChartLayout component.
 */
export interface DrugChartLayoutProps {
    initalValuesOfTable: Array<TableDataProps>;
    onSubmit: (data: FormData) => void;
    translations: any;
    drugTranslations: any;
}

/**
 * The DrugChartLayout component.
 * @initalValuesOfTable: The initial state of the table (passed in from the parent node)
 */
export const DrugChartLayout: React.FC<DrugChartLayoutProps> = ({
    initalValuesOfTable,
    onSubmit,
    translations,
    drugTranslations,
}) => {
    const [formStatus, setFormStatus] = useState<FormData>({
        drug: "",
        drug_key: "",
    });

    return (
        <PageMain>
                <FormSection>
                    <DrugChartComponent
                        onSubmit={onSubmit}
                        initialFormData={formStatus}
                        translations={translations}
                        drugTranslations={drugTranslations}
                    />
                </FormSection>
         </PageMain>
    );
};
