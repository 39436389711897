import React from "react";
import {
    DischargePatientContainer,
    DischargePatientContentContainer,
    DischargePatientDiagnosisInfoContainer,
    DischargePatientFeedbackContainer,
    DischargePatientFeedbackContent,
    DischargePatientFeedbackScore,
    RequestFeedbackButton
} from "./DischargePatient.styles";
import FeedbackPatientInfo from "./components/FeedbackPatientInfo/FeedbackPatientInfo";
import FeedbackDiagnosisBox from "./components/FeedbackDiagnosisBox/FeedbackDiagnosisBox";
import ItemListTable from "../../item-list-table/ItemListTable";
import Tooltip from "../../tooltip/Tooltip";
import {
    CORRECT_DIAGNOSE_SCORE_PERCENTAGE,
    CORRECT_MANAGEMENT_SCORE_PERCENTAGE,
    NEGATIVE_SCORE_PERCENTAGE_FOR_INCORRECT_MANAGEMENT,
} from "./config";
import { Modal } from "../Modal";

export interface DischargedPatient {
    id: string;
    location: string;
    name: string;
    demographics: string;
    symptom: string;
    diagnosis: string;
    real_diagnosis?: string;
    score?: number;
    feedback?: string;
}

interface DischargePatientModalProps {
    patient: DischargedPatient;
    translations: any;
    onClose: () => void;
}

function DischargePatientModal({
                                   patient,
                                   translations,
                                   onClose,
                               }: DischargePatientModalProps) {
    const hasRelevantHistory = (history: any) => {
        if (!history) return false;
        for (const key in history) {
            if (history[key] && Object.keys(history[key]).length) return true;
        }
        return false;
    };

    const handleRequestFeedback = () => {
        console.log('feedback requested');
    };

    return (
        <Modal onRequestClose={onClose}>
            <DischargePatientContainer>
                <DischargePatientContentContainer>
                    <FeedbackPatientInfo patient={patient} translations={translations} />
                    <DischargePatientDiagnosisInfoContainer>
                        <FeedbackDiagnosisBox
                            title="Your Diagnosis"
                            diagnosis={translations.diagnosis[patient?.diagnosis]}
                            isCorrect={
                                patient?.diagnosis ===
                                patient?.real_diagnosis
                            }
                        />
                        <FeedbackDiagnosisBox
                            title="Correct Diagnosis"
                            diagnosis={translations.diagnosis[patient?.real_diagnosis]}
                            isCorrect={true}
                        />
                    </DischargePatientDiagnosisInfoContainer>
                    <DischargePatientFeedbackScore>
                        Score: <span>{patient?.score}%</span>
                    </DischargePatientFeedbackScore>
                    {patient?.actions &&
                        Object.keys(patient?.actions.actions).length > 0 && (
                            <ItemListTable
                                title="Your Management"
                                protocol={patient?.actions.actions}
                                translations={translations}
                            />
                        )}
                </DischargePatientContentContainer>

                <DischargePatientFeedbackContainer>
                    {patient.feedback ? (
                        <DischargePatientFeedbackContent>
                            {feedback.feedback.split(/\n/).map((line: string) => (
                                <p key={line}>{line}</p>
                            ))}
                        </DischargePatientFeedbackContent>
                    ) : (
                        <RequestFeedbackButton onClick={handleRequestFeedback}>
                            Request Feedback
                        </RequestFeedbackButton>
                    )}
                </DischargePatientFeedbackContainer>
            </DischargePatientContainer>
        </Modal>
    );
}

export default DischargePatientModal;