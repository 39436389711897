// External imports
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Internal Imports
import {
    OrderConsultationForm,
    FormData,
} from "./components/OrderConsultationForm/FormComponent";
import { GamePageLayout } from "../../../components/game/GamePageLayout/GamePageLayout";
import { getTurnCount } from "../../../core/LocalStorage/turnLogic/core";
import { getJwt } from "../../../core/LocalStorage/AuthSession/core";
import {
    OrderConsultationContainer,
    OrderConsultationDivider,
    OrderConsultationPageContainer,
    OrderConsultationSpace,
} from "./RequestConsultation.styles";
import { OrderConsultationResult } from "./components/ConsultationResult/orderConsultationResult/OrderConsultationResult";

// Dependency Injection
import { PostConsultationType } from "../../../core/ServerApiLayer/VOne/patients/postConsultation";
import {
    SetConsultationsToLocalStorageType,
    GetConsultationDataFromLocalStorageType,
    FilterConsultationDataType,
} from "../../../core/LocalStorage/patients/consultations";
import { UpdateConsultationDocumentationToLocalStorageType } from "../../../core/LocalStorage/patients/notes";
import { GetAllConsultationsType } from "../../../core/ServerApiLayer/VOne/patients/getAllConsultations";

// Shared Dependency Injection across all the in-game pages
import {
    withGameDependencies,
    WithPatientProps,
} from "../../../core/sharedDependencies/withGameDependencies";
import { WithGameDependenciesProps } from "../../../core/sharedDependencies/types";

// Translation imports
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";
import { ConsultationPageTranslations } from "./translations/types";

// Define the translations for the consultation page
const TRANSLATIONS: Record<SupportedLanguage, ConsultationPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

interface RequestConsultationProps extends WithPatientProps {
    setConsultationsToLocalStorage: SetConsultationsToLocalStorageType;
    getConsultationDataFromLocalStorage: GetConsultationDataFromLocalStorageType;
    filterConsultations: FilterConsultationDataType;
    updateConsultationDocumentationToLocalStorage: UpdateConsultationDocumentationToLocalStorageType;
    postConsultationApiCall: PostConsultationType;
    getAllConsultationsApiCall: GetAllConsultationsType;
}

type CombinedProps = RequestConsultationProps & WithGameDependenciesProps;

const RequestConsultationBase: React.FC<CombinedProps> = ({
    setConsultationsToLocalStorage,
    getConsultationDataFromLocalStorage,
    filterConsultations,
    updateConsultationDocumentationToLocalStorage,
    postConsultationApiCall,
    getAllConsultationsApiCall,
    // Injected game dependencies
    sidebarDependencies,
    turnDependencies,
    getJwt,
    demographicsDependencies,
    useLanguage,
    translations,
    refreshPage,
}) => {
    // Get the turn count and patient id
    const { id: id } = useParams<{ id: string }>();
    // @ts-ignore
    const patientId = parseInt(id);
    const turnCount = turnDependencies.getTurnCount();
    const patient = demographicsDependencies.getDemographicsFromLocalStorage(
        Number(patientId)
    );

    // Get the unread notifications
    const unreadNotifications = turnDependencies.getNotifications();

    // Get the translations
    const { language } = useLanguage();
    const pageTranslations = TRANSLATIONS[language];

    const [formStatus, setFormStatus] = useState<FormData>({
        specialty: "",
        summary: "",
        reason: "",
    });

    const STATUS = {
        PENDING: pageTranslations.pending,
        AVAILABLE: pageTranslations.available,
    };

    const [pendingConsultations, setPendingConsultations] = useState<any[]>([]);
    const [availableConsultations, setAvailableConsultations] = useState<any[]>(
        []
    );

    const fetchConsultationData = async () => {
        const data = filterConsultations(Number(patientId), turnCount);
        setPendingConsultations(data.pending);
        setAvailableConsultations(data.available);
    };

    useEffect(() => {
        // Fetch data when component mounts
        fetchConsultationData();
    }, []);

    const handleSubmit = async (userData: FormData): Promise<boolean> => {
        const payload = {
            turn: turnCount,
            patient_id: Number(patientId),
            specialty: userData.specialty,
            summary: userData.summary,
            reason: userData.reason,
        };

        const existingConsultation = getConsultationDataFromLocalStorage(
            Number(patientId)
        );
        // @ts-ignore
        if (existingConsultation && existingConsultation[userData.specialty]) {
            return false;
        }

        const jwt = getJwt();
        //@ts-ignore
        const response = await postConsultationApiCall(jwt, payload);

        if (response.status === 201) {
            // Update the turn count
            turnDependencies.updateTurns(4);

            //@ts-ignore
            setConsultationsToLocalStorage(patientId, response.data!);

            // Update the notes data in local storage
            //@ts-ignore
            updateConsultationDocumentationToLocalStorage(
                patientId,
                Number(response.data!.turn_ordered),
                response.data!
            );

            // Re-fetch the data to update the UI instantly
            fetchConsultationData();
            return true;
        } else {
            console.error("Error posting consultation:", response.error);
            return false;
        }
    };

    return (
        <GamePageLayout
            turnCount={turnCount}
            unreadNotifications={unreadNotifications}
            pageTitle="Request a specialty consult"
            patient={patient}
            translations={translations}
            documentationDependencies={sidebarDependencies}
            turnDependencies={turnDependencies}
            demographicsDependencies={demographicsDependencies}
            useLanguage={useLanguage}
            refreshPage={refreshPage}
        >
            <OrderConsultationPageContainer>
                <OrderConsultationContainer>
                    <OrderConsultationForm onSubmit={handleSubmit} />
                    <OrderConsultationDivider />
                    <OrderConsultationResult
                        status={STATUS.PENDING}
                        data={pendingConsultations}
                    />
                    <OrderConsultationSpace />
                    <OrderConsultationResult
                        status={STATUS.AVAILABLE}
                        data={availableConsultations}
                    />
                </OrderConsultationContainer>
            </OrderConsultationPageContainer>
        </GamePageLayout>
    );
};

export const RequestConsultationPage =
    withGameDependencies<RequestConsultationProps>(RequestConsultationBase);
