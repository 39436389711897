import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
    SidebarNavItem,
    SidebarNavItemProps,
} from "../SidebarNavItem/SidebarNavItem";
import { Sidebar, SidebarContent, SidebarInner } from "./SidebarNav.styles";
import { SidebarNavPopoutMenu } from "../SideBarNavPopoutMenu/SidebarNavPopoutMenu";
import { useLocation, useNavigate } from "react-router-dom";

// Adjusting the item structure to accommodate subitems
export interface NavItem extends SidebarNavItemProps {
    subItems?: NavItem[];
}

export interface SidebarNavProps {
    items: NavItem[];
    setModalOpen?: (e: boolean) => void;
    setChatExitModalOpen?: (e: boolean) => void;
}

export const SidebarNav: React.FC<SidebarNavProps> = ({
    items,
    setModalOpen,
    setChatExitModalOpen,
}) => {
    const location = useLocation();
    const previousPage = document.referrer;

    const { id: patientId } = useParams<{ id: string }>();

    const storeToLocalstorage = (newPatientIds: any) => {
        // Save the updated array back into localStorage
        localStorage.setItem(
            "showChatExitPopupFor",
            JSON.stringify(newPatientIds)
        );
    };

    const updatePatientIdInLocalStorage = () => {
        // Retrieve the current array from localStorage
        const existingData = localStorage.getItem("showChatExitPopupFor");

        // Parse the existing data or initialize as an empty array if nothing is found
        const patientIds = existingData ? JSON.parse(existingData) : [];

        // Check if the patientId exists in the array and its value is false (i.e., popup has already been shown)
        const existingPatient = patientIds.find(
            (patient: { [key: string]: boolean }) =>
                //@ts-ignore

                patient[patientId] !== undefined
        );

        if (!existingPatient && previousPage.includes("chat")) {
            // If the patientId is not found in the array, add it with true (indicating popup not shown)
            //@ts-ignore

            patientIds.push({ [patientId]: true });
            storeToLocalstorage(patientIds);
        }
    };

    useEffect(() => {
        if (patientId) {
            if (previousPage.includes("chat") && !location.pathname.includes("dashboard")) {
                updatePatientIdInLocalStorage();
            }
            //if (!location.pathname.includes("chat")) {
            // Check if patientId is in localStorage and the popup hasn't been shown yet
            const existingData = localStorage.getItem("showChatExitPopupFor");
            const patientIds = existingData ? JSON.parse(existingData) : [];

            // Find the patientId and check if its value is true (not shown yet)
            const existingPatient = patientIds.find(
                //@ts-ignore

                (patient: { [key: string]: boolean }) => patient[patientId]
            );
            //@ts-ignore

            if (existingPatient && existingPatient[patientId] === true) {
                // If patientId exists and the value is true, show the modal
                //@ts-ignore
                setChatExitModalOpen(true);
            }
            //}
        }
    }, [location.pathname, patientId]);

    return (
        <Sidebar>
            <SidebarContent>
                <SidebarInner>
                    {items.map((item, index) =>
                        item.subItems && item.subItems.length > 0 ? (
                            // Correctly handling items with sub-items
                            <SidebarNavPopoutMenu
                                key={index}
                                mainItem={item}
                                subItems={item.subItems}
                            />
                        ) : item.href == "/patients/:id/guidelines" ? (
                            //@ts-ignore
                            <div onClick={() => setModalOpen(true)}>
                                <SidebarNavItem
                                    key={index}
                                    name={item.name}
                                    Icon={item.Icon}
                                />
                            </div>
                        ) : (
                            // Correctly rendering items without sub-items, ensuring Icons are passed

                            <SidebarNavItem
                                key={index}
                                name={item.name}
                                //@ts-ignore
                                href={item.href.replace(":id", patientId!)}
                                Icon={item.Icon}
                            />
                        )
                    )}
                </SidebarInner>
            </SidebarContent>
        </Sidebar>
    );
};
