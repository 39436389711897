// External imports
import React, { useState, useEffect } from "react";

// Internal imports
import { SelectOption } from "../../config/OrderTreatment.type";
import {
    OrderTreatmentDivider,
    OrderTreatmentFormContainer,
    OrderTreatmentFormFieldContainer,
    OrderTreatmentFormGridContainer,
    OrderTreatmentFormGridItemBy3,
    OrderTreatmentFormGridItemBy6,
    OrderTreatmentFormLabel,
    OrderTreatmentTurnLabel,
    StyledOrderTreatmentForm,
} from "./OrderTreatmentForm.styles";
import SelectBox from "../../../../../components/core/select-box/SelectBox";
import TextArea from "../../../../../components/core/input-form/TextArea";
import ClearSubmitFormButtons from "../../../../../components/core/clear-submit-form-buttons/ClearSubmitFormButtons";

// OrderTreatmentFormProps
interface OrderTreatmentFormProps {
    onSubmit: (treatment: string, priority: string, reason: string) => void;
    translations: any;
    treatmentTranslations: any;
    priorityTranslations: any;
}

/**
 * OrderTreatmentForm
 *
 * @description Represents the form for ordering a treatment.
 * @returns {JSX.Element} The rendered OrderTreatmentForm component.
 */
export const OrderTreatmentForm: React.FC<OrderTreatmentFormProps> = ({
                                                                          onSubmit,
                                                                          translations,
                                                                          treatmentTranslations,
                                                                          priorityTranslations,
                                                                      }) => {
    // Convert options from translations
    const treatmentOptions = Object.entries(treatmentTranslations)
        .map(([key, value]) => ({
            label: value,
            value: key,
            turn: undefined,
        }))
        // @ts-ignore
        .sort((a, b) => a.label.localeCompare(b.label));

    const priorityOptions = Object.entries(priorityTranslations)
        .map(([key, value]) => ({
            label: value,
            value: key,
            turn: undefined,
        }))
        // @ts-ignore
        .sort((a, b) => a.label.localeCompare(b.label));

    // Initialize state with first option
    const [treatment, setTreatment] = useState<SelectOption>(
        // @ts-ignore
        treatmentOptions.length > 0 ? treatmentOptions[0] : undefined
    );

    const [priority, setPriority] = useState<SelectOption>(
        // @ts-ignore
        priorityOptions.length > 0 ? priorityOptions[0] : undefined
    );

    const [reason, setJustification] = useState<string>("");

    // Ensure state updates if options change dynamically
    useEffect(() => {
        if (!treatment && treatmentOptions.length > 0) {
            // @ts-ignore
            setTreatment(treatmentOptions[0]);
        }
    }, [treatmentOptions]);

    useEffect(() => {
        if (!priority && priorityOptions.length > 0) {
            // @ts-ignore
            setPriority(priorityOptions[0]);
        }
    }, [priorityOptions]);

    // Clear form function
    const clearForm = () => {
        // @ts-ignore
        setTreatment(treatmentOptions[0]);
        // @ts-ignore
        setPriority(priorityOptions[0]);
        setJustification("");
    };

    // Handle form submission
    const handleFormSubmit = async () => {
        if (treatment && priority && reason.trim().length >= 5) {
            const success = await onSubmit(
                treatment.value,
                priority.value,
                reason
            );
            // @ts-ignore
            if (success) {
                clearForm();
            }
        }
    };

    return (
        <StyledOrderTreatmentForm onSubmit={(e) => e.preventDefault()}>
            <OrderTreatmentFormContainer>
                <div>
                    <OrderTreatmentFormGridContainer>
                        <OrderTreatmentFormGridItemBy3>
                            <OrderTreatmentFormLabel htmlFor="OrderTreatment">
                                {translations.treatment}:
                            </OrderTreatmentFormLabel>
                            <OrderTreatmentFormFieldContainer>
                                <SelectBox
                                    value={[treatment]}
                                    // @ts-ignore
                                    options={treatmentOptions}
                                    onChange={(value) =>
                                        setTreatment(value)
                                    }
                                />
                            </OrderTreatmentFormFieldContainer>
                        </OrderTreatmentFormGridItemBy3>

                        <OrderTreatmentFormGridItemBy3>
                            <OrderTreatmentFormLabel htmlFor="priority">
                                {translations.priority}:
                            </OrderTreatmentFormLabel>
                            <OrderTreatmentFormFieldContainer>
                                <SelectBox
                                    value={[priority]}
                                    // @ts-ignore
                                    options={priorityOptions}
                                    onChange={(value) =>
                                        setPriority(value)
                                    }
                                />
                            </OrderTreatmentFormFieldContainer>
                        </OrderTreatmentFormGridItemBy3>

                        <OrderTreatmentFormGridItemBy6>
                            <OrderTreatmentFormLabel htmlFor="reasons">
                                {translations.justification}:
                            </OrderTreatmentFormLabel>
                            <OrderTreatmentFormFieldContainer>
                                <TextArea
                                    rows={3}
                                    height="auto"
                                    color="#000"
                                    borderColor="#d1d5db"
                                    value={reason}
                                    onChange={(e) =>
                                        // @ts-ignore
                                        setJustification(e.target.value)
                                    }
                                />
                            </OrderTreatmentFormFieldContainer>
                        </OrderTreatmentFormGridItemBy6>
                    </OrderTreatmentFormGridContainer>
                </div>
            </OrderTreatmentFormContainer>

            <OrderTreatmentDivider />

            <ClearSubmitFormButtons
                submitText={translations.orderTreatment}
                clearText={translations.clearForm}
                isSubmitDisabled={reason.trim().length < 5}
                onSubmit={handleFormSubmit}
                onClear={clearForm}
                RenderComponent={() => <></>}
            />
        </StyledOrderTreatmentForm>
    );
};

