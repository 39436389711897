

export class NewClassDataStore {

    static scores: Map<string, number> = new Map<string, number>();
    static diagnosisLocked: boolean = false;
    static neverLocked: boolean = false;
    static protocolLocked: boolean = false;
    static ecgImages: boolean = false;
    static clinicalReasoning: boolean = false;

    static setDiagnosisScore(score: number) {
        this.scores.set("scoring_diagnosis", score);
    }

    static setNeverScore(score: number) {
        this.scores.set("scoring_never", score);
    }

    static setProtocolScore(score: number) {
        this.scores.set("scoring_protocol", score);
    }

    static getDiagnosisScore() {
        let score = this.scores.get("scoring_diagnosis");
        if (score === undefined) {
            return 50
        }
        return score
    }

    static getProtocolScore() {
        let score = this.scores.get("scoring_protocol");
        if (score === undefined) {
            return 50
        }
        return score
    }

    static setEcgImagesEnabled(enabled: boolean) {
        this.ecgImages = enabled;
    }

    static getEcgImagesEnabled() {
        return this.ecgImages
    }

    static setClinicalReasoningEnabled(enabled: boolean) {
        this.clinicalReasoning = enabled;
    }

    static getClinicalReasoningEnabled() {
        return this.clinicalReasoning
    }

}
