// External Imports
import React from "react";
import { FaProcedures } from "react-icons/fa";

// Internal imports
import {
    PatientDescription,
    PatientDescriptionContainer,
    PatientInfoContainer,
    PatientNameTitle,
} from "./PatientInfo.styles";
import { PatientPicture } from "../PatientPicture/PatientPicture";
import { PencilButton } from "../PencilButton/PencilButton";
import WebsiteButton from "../../../core/button/WebsiteButton";

/**
 * PatientInfoProps
 *
 * This is the props interface for the PatientInfo component.
 *
 * @param patient - The patient information.
 * @param tab - The current tab.
 * @param setTab - The function to set the tab.
 * @param isDischargeFormOpen - Whether the discharge form is open.
 */
interface PatientInfoProps {
    patient: any;
    tab: string;
    setTab: React.Dispatch<React.SetStateAction<string>>;
    isDischargeFormOpen?: boolean;
    translations?: any;
}

export const PatientInfo: React.FC<PatientInfoProps> = ({
    patient,
    tab,
    setTab,
    isDischargeFormOpen = false,
    translations,
}: PatientInfoProps) => {
    const isDesktop = true;
    const notesTabActive = tab === "notes";
    const dischargeNotesActive = tab === "dischargeNotes";

    return (
        <div>
            {isDesktop ? (
                <PatientPicture
                    url={`https://faces-clinical-metrics20250207.lon1.digitaloceanspaces.com/faces/${patient.faceURL}`}
                />
            ) : null}
            <PatientInfoContainer>
                <div>
                    <PatientNameTitle>{patient.name}</PatientNameTitle>
                    <PatientDescriptionContainer>
                        {patient.location === "Majors" ? (
                            <FaProcedures />
                        ) : null}
                        <PatientDescription>
                            {translations.location[patient.location]}
                        </PatientDescription>
                    </PatientDescriptionContainer>
                </div>
                {isDischargeFormOpen ? (
                    <WebsiteButton
                        tag="button"
                        onClick={() =>
                            setTab(
                                dischargeNotesActive ? "main" : "dischargeNotes"
                            )
                        }
                    >
                        {dischargeNotesActive
                            ? "Patient Info"
                            : translations.gameBar.patientSidebar.reviewNotes}
                    </WebsiteButton>
                ) : (
                    <PencilButton
                        tab={tab}
                        notesTabActive={notesTabActive}
                        setTab={setTab}
                    />
                )}
            </PatientInfoContainer>
        </div>
    );
};
