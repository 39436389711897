import { ObservationPageTranslations } from "./types";

export const enTranslations: ObservationPageTranslations = {
    title: "Observations",
    columns: {
        turns: "Turns",
        time: "Time",
        bloodPressure: "Blood Pressure",
        pulse: "Pulse",
        respiratoryRate: "Respiratory Rate",
        oxygenSaturation: "Oxygen Saturation",
        temperature: "Temperature",
        mmHg: "mmHg",
        bpm: "bpm",
        degCel: "°C",
        percentage: "%",
    },
    takeObs: "Take Obs",
};
